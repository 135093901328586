import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import "./apply.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../../components/header/header";
import Footer from "../../../../components/footer/footer";
import { ToastContainer, toast } from 'react-toastify';
import InputComponent from "../../../../components/inputComponent";
import TextAreaComponent from "../../../../components/textAreaComponent";
import axios from 'axios';
import FetchSuccessComponent from "../../../../components/fetchComponent/fetchComponent";
import optionsCountries from "../../../../utilities/countries_code";
import useDropdown from "../../../../hooks/searchDropdownHook";
import { filterByCountryObject, filterByFieldOptionMultiSelect } from "../../../../utilities/filterfunctionsDropdowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import useServiceDropdown from "../../../../hooks/searchMultiSelectDropdownsHook";
import { Select } from "@mobiscroll/react";
import ContactWay from "../../../../components/contactOptionsComponent/contactOptionsComponent";
import useSortedCountries from "../../../../hooks/sortCountriesCode";

const ApplyContractorComponent = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleRedirect = (route) => {
    navigate(route + "/", { replace: true });
  };
  const [openDropdownCountry, setOpenDropdownCountry] = useState(false)
  const [skillsArray, setSkillsArray] = useState([]);
  const [loading, setLoading] = useState(false)
  const [errors, setErros] = useState({})
  const initialData = {
    firstName: "",
    lastName: "",
    phoneNumberCode: "",
    phoneNumber: "",
    candidateEmail: "",
    company: "",
    service: [],
    additionalInformation: "",
    policies: false,
    industry: "2ef7c2ae-93e7-ee11-904c-6045bd03b4d4"
  };
  const {
    dropdowns,
    setDropdowns,
    setOpenDropdowns,
    openDropdowns,
    searchTerm,
    filteredArray,
    handleSearchChange,
    handleSelectOptionDropdown,
    addDropdown,
    setForm,
    form,
    servicesData,
    setServicesData,
    inputRef,
    removeDropdown,
    selectOptions,
    selectedService,
    handleChangeService
  } = useServiceDropdown(filterByFieldOptionMultiSelect, initialData, "service", "https://prod-04.westus.logic.azure.com:443/workflows/cfdd9ddca4334cdb8ea7a4c276041b10/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ux7vwhdPr3qDKQ6K52U8s2JC_DssuB7u4TjLzyV9gm8");
  const {
    isOpen: openDropdownCountryCode,
    setIsOpen: setOpenDropdownCountryCode,
    searchTerm: searchTermCountryCode,
    filteredArray: filteredCountryCode,
    inputRef: elementoRef,
    handleSearchChange: handleSearchChangeCountryCode,
    selectedItem: selectedCountryCode,
    setSelectedItem: setSelectedCountryCode
  } = useDropdown(optionsCountries, filterByCountryObject);

  useEffect(() => {
    axios.get('https://prod-04.westus.logic.azure.com:443/workflows/cfdd9ddca4334cdb8ea7a4c276041b10/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ux7vwhdPr3qDKQ6K52U8s2JC_DssuB7u4TjLzyV9gm8')
      .then((response) => {
        setServicesData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  const handleDropdown = (set) => {
    set((prev) => !prev);
  }


  const handleChange = (event) => {
    const { name, value, checked } = event.target

    if (name != "candidateEmail" && name !== "additionalInformation") {
      if (name === 'phoneNumber' || name === 'previousExperience') {
        if (value < 0) {
          return;
        }
      } else {
        if (!/^[^\d]*$/.test(value)) {
          return;
        }
      }
    }

    if (name === 'policies') {
      setForm({ ...form, [name]: checked })
    } else {
      setForm({ ...form, [name]: value })
    }

  }

  const handleSelectOption = (option, keyOption, set) => {
    if (keyOption == 'skills') {

      setSkillsArray([...skillsArray, option]);
      setForm({
        ...form,
        [keyOption]: [...skillsArray, option].join(",")
      });
    } else if (keyOption === "phoneNumberCode") {
      setSelectedCountryCode(option);
      setForm({ ...form, [keyOption]: option })
    } else {
      setForm({ ...form, [keyOption]: option })
    }
    set((prev) => !prev);
  }


  const [selectedContactWay, setSelectedContactWay] = useState("");

  const onValidate = (form) => {
    let errors = {};

    if (!form.firstName.trim()) {
      errors.firstName = t('NAME_REQUIRED');
    }

    if (!form.lastName.trim()) {
      errors.lastName = t('LAST_NAME_REQUIRED');
    }

    if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumberCode.trim())) {
      errors.phoneNumberCode = t('PHONE_CODE_REQUIRED');
    }

    if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumber.trim())) {
      errors.phoneNumber = t('PHONE_NUMBER_REQUIRED');
    }

    if (((selectedContactWay === "1") || (selectedContactWay === "3")) && (!form.candidateEmail.trim())) {
      errors.candidateEmail = t('EMAIL_REQUIRED');
    }

    if (!selectedContactWay) {
      errors.contactWay = t("CONTACT_WAY_VALIDATION")
    }

    if (!form.company.trim()) {
      errors.company = t('COMPANY_NAME_REQUIRED');
    }

    if (form.service.length == 0) {
      errors.service = t('SERVICE_REQUIRED');
    }

    if (!form.additionalInformation.trim()) {
      errors.additionalInformation = t('ADDITIONAL_INFO_REQUIRED');
    }

    if (!form.policies) {
      errors.policies = t('POLICIES_REQUIRED');
    }

    if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (form.phoneNumber <= 0)) {
      errors.phoneNumber = t('PHONE_NUMBER_ZERO')
    }

    return errors;
  };

  console.log(form);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
    const base64File = await convertToBase64(event.target.files[0]);
    setForm({
      ...form, ["candidateResume"]: {
        "content-type": event.target.files[0].type,
        content: base64File,
      }
    })
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };


  // Función para manejar los clics en toda la página
  const handleClickOutside = (event) => {
    if (elementoRef.current && !elementoRef.current.contains(event.target)) {
      setOpenDropdownCountryCode(false);
    }
    if (!event.target.classList.contains('inputNumber2') &&
      !event.target.classList.contains('dropdownOption')
    ) {
      setOpenDropdowns(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const form2 = useRef();

  onValidate(form)

  const [isFetchSuccessful, setIsFetchSuccessful] = useState(false);


  const sendEmail = async (e) => {
    e.preventDefault();
    const validationErrors = onValidate(form);
    setErros(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch('https://prod-171.westus.logic.azure.com:443/workflows/dbf71385cf274815add1a20ba7e6c66a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=53EESARd8kHLV5Vno1bw1lg-Ex6CuhiExLG3NbFCPFY', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(form),
        });

        if (response.ok) {
          setIsFetchSuccessful(true); // Cambia el estado a true si la respuesta es exitosa
        } else {
          setIsFetchSuccessful(false); // Cambia el estado a false si la respuesta no es exitosa
        }

      } catch (error) {
        setUploadStatus('Error uploading file.');
      }
    }
  };

  const notifySucces = () => toast.success('Form send correctly', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  });

  const notifyError = () => toast.error('Form does not send correctly', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  });

  const sortedCountriesCode = useSortedCountries("country", filteredCountryCode)

  return (
    <>
      <Header />
      <main className="formMaincontainer">
        <div className="mainImgConsultingApply"></div>
        <main className="workWithUsFormMainContainer">
          <div className="contact-us-forms">
            <form ref={form2} onSubmit={sendEmail}>
              <h2 className="form-title">{t('FORM_TITLE')}</h2>
              <div className="nameContainer">
                <div className="errorContainer">
                  <InputComponent
                    className={`input-width`}
                    name={'firstName'}
                    value={form.firstName}
                    handleChange={handleChange}
                    label={`${t('FORM_FIRST_NAME')}*`}
                  />
                  {errors.firstName && <div className="alert alert-danger">{errors.firstName}</div>}
                </div>
                <div className="errorContainer">
                  <InputComponent
                    className={`input-width`}
                    name={'lastName'}
                    value={form.lastName}
                    handleChange={handleChange}
                    label={`${t('FORM_LAST_NAME')}*`}
                  />
                  {errors.lastName && <div className="alert alert-danger">{errors.lastName}</div>}
                </div>
              </div>
              <ContactWay
                selectedContactWay={selectedContactWay}
                setSelectedContactWay={setSelectedContactWay}
              />
              <div className="phoneEmailContainer">
                {((selectedContactWay === "2") || (selectedContactWay === "3")) && (<div className="phoneContactContainerMain">
                  <p>{t('FORM_PHONE_NUMBER')}*</p>
                  <div className="phoneContactContainer phoneCode">
                    <div className="phoneContactDropdown phoneCode" ref={elementoRef}>
                      <div className="phoneContactInputContainer">
                        <input
                          type="text"
                          className={`inputNumber ${openDropdownCountryCode && "active"}`}
                          onClick={() => handleDropdown(setOpenDropdownCountryCode)}
                          onChange={handleSearchChangeCountryCode}
                          name="phoneNumberCode"
                          value={selectedCountryCode ? selectedCountryCode : searchTermCountryCode}
                          autoComplete="off"
                        />
                        <img src={'/img/arrowDownDropdown.svg'} alt="" />
                      </div>
                      {openDropdownCountryCode && (
                        <div className="phoneContactOptions">
                          <div className="optionsContainer">
                            {sortedCountriesCode.map((option) => {
                              return <p onClick={() => handleSelectOption(option.code, "phoneNumberCode", setOpenDropdownCountryCode)} key={option.country}>
                                {i18n.language === 'es' ? option.country : option.country_EN}
                              </p>
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="errorContainer">
                      <InputComponent
                        className={`input-width`}
                        name={'phoneNumber'}
                        type={'number'}
                        handleChange={handleChange}
                        value={form.phoneNumber}
                      />
                    </div>
                  </div>
                  {errors.phoneNumber && <div className="alert alert-danger">{errors.phoneNumber}</div>}
                  {errors.phoneNumberCode && <div className="alert alert-danger">{errors.phoneNumberCode}</div>}
                </div>)}
                {((selectedContactWay === "1") || (selectedContactWay === "3")) && (<div className="errorContainer email">
                  <InputComponent
                    className={`input-width`}
                    label={`${t('FORM_EMAIL')}*`}
                    name={'candidateEmail'}
                    type={'email'}
                    value={form.candidateEmail}
                    handleChange={handleChange}
                  />
                  {errors.candidateEmail && <div className="alert alert-danger">{errors.candidateEmail}</div>}
                </div>)}
              </div>
              {errors.contactWay && <div className="alert alert-danger">{errors.contactWay}</div>}
              <div className="errorContainer">
                <InputComponent
                  className={`input-width`}
                  name={'company'}
                  label={`${t('FORM_COMPANY_NAME')}*`}
                  value={form.company}
                  handleChange={handleChange}
                />
                {errors.company && <div className="alert alert-danger">{errors.company}</div>}
              </div>
              <div className={`phoneContactContainer services ${(dropdowns.length > 1 || dropdowns[dropdowns.length - 1].value) && "edit"}`}>
                <div className="dropdownsContainer" ref={inputRef}>
                  <p>{t('FORM_LABEL_SERVICE')}*</p>
                  {dropdowns.map((dropdown) => (
                    <div key={dropdown.id} className="phoneContactDropdown">
                      <div className="phoneContactInputContainer">
                        <div className="servicesDropdownContainer">
                          <Select
                            data={selectOptions}
                            value={selectedService}
                            selectMultiple={true}
                            inputProps={{
                              className: "inputNumber2"
                            }}
                            theme="ios"
                            themeVariant="light"
                            filter={true}
                            onChange={handleChangeService}
                            placeholder={t('WORK_US_VACANCY')}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {dropdowns[dropdowns.length - 1].value && (
                    <p className="buttonAddDropdown" onClick={addDropdown}>
                      {t('FORM_ADD_SERVICE')}
                    </p>
                  )}
                  {errors.service && <div className="alert alert-danger">{errors.service}</div>}
                </div>
              </div>
              <p>{t('WORK_US_RESUME_MESSAGE')}*</p>
              <div className="errorContainer">
                <TextAreaComponent
                  className={`input-width textArea`}
                  name={'additionalInformation'}
                  handleChange={handleChange}
                  value={form.additionalInformation}
                />
                {errors.additionalInformation && <div className="alert alert-danger">{errors.additionalInformation}</div>}
              </div>
              <div className="termsAndConditionsContainer">
                <input type="checkbox" name="policies" onChange={handleChange} />
                <p className="termsAndConditionsText">{t('FORM_POLICY')} <a href="/PoliticaDeTratamientoDeDatosPersonales.pdf">{t('FORM_POLICY_LINK')}</a></p>
              </div>
              {errors.policies && <div className="alert alert-danger">{errors.policies}</div>}
              <button className="button">{loading ? t('FORM_BUTTON_LOADING') : t('FORM_BUTTON')}</button>
              <FetchSuccessComponent isFetchSuccessful={isFetchSuccessful} setIsFetchSuccessful={setIsFetchSuccessful} url={'/contractors'} />
            </form>
          </div>
        </main>
      </main>
      <Footer />
    </>
  );
};

export default ApplyContractorComponent;
