import React, { useEffect, useMemo, useRef, useState } from "react";
import "./getHiredform.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../../components/header/header";
import Footer from "../../../../components/footer/footer";
import InputComponent from "../../../../components/inputComponent";
import TextAreaComponent from "../../../../components/textAreaComponent";
import axios from "axios";
import FetchSuccessComponent from "../../../../components/fetchComponent/fetchComponent";
import { filterByCountryObject } from "../../../../utilities/filterfunctionsDropdowns";
import useDropdown from "../../../../hooks/searchDropdownHook";
import optionsCountries from "../../../../utilities/countries_code";
import { Select } from "@mobiscroll/react";
import ContactWay from "../../../../components/contactOptionsComponent/contactOptionsComponent";
import useSortedCountries from "../../../../hooks/sortCountriesCode";
import i18n from "../../../../utilities/i18n";

const GetHireForm = () => {
    const { t } = useTranslation();
    const [openDropdownCountry, setOpenDropdownCountry] = useState(false)
    const inputRef = useRef();
    const [skillsArray, setSkillsArray] = useState([])
    const [vacanciesData, setVacanciesData] = useState([])
    const [vacanciesDataOriginal, setVacanciesDataOriginal] = useState([])
    const [skillsData, setSkillsData] = useState([])
    const {
        isOpen: openDropdownCountryCode,
        setIsOpen: setOpenDropdownCountryCode,
        searchTerm: searchTermCountryCode,
        filteredArray: filteredCountryCode,
        inputRef: elementoRef,
        handleSearchChange: handleSearchChangeCountryCode,
        selectedItem: selectedCountryCode,
        setSelectedItem: setSelectedCountryCode
    } = useDropdown(optionsCountries, filterByCountryObject);
    const inputRefPosition = useRef();

    const initialData = {
        firstName: "",
        lastName: "",
        phoneNumberCode: "",
        phoneNumber: "",
        candidateEmail: "",
        currentCandidateDepartment: "",
        currentCandidateCity: "",
        currentCandidateAddress: "",
        remoteWork: true,
        applicationPosition: "",
        skills: [],
        previousExperience: "",
        candidateResume: {},
        additionalInformation: "",
        RecruitingEffort: "",
        VacancyGUID: "",
        policies: false,
        ByForm: "Contractor"
    }
    const [loading, setLoading] = useState(false)
    const [errors, setErros] = useState({})
    // const [selectedPhonePrefix, setSelectedPhonePrefix] = useState(initialData.phone_prefix)
    const [form, setForm] = useState(initialData)

    const handleDropdown = (set) => {
        set((prev) => !prev);
    }

    useEffect(() => {
        axios.get('https://prod-49.westus.logic.azure.com:443/workflows/2c614ab243244f2db1f10b462753208e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=thFXVLzYx-VUD85W9gp_jpFgOcHhwo8sFHxDR1M9Tds')
            .then((response) => {
                setVacanciesData(response.data);
                setVacanciesDataOriginal(response.data)
            })
            .catch((error) => {

            });
    }, [vacanciesData.length === 0]);

    useEffect(() => {
        axios.get('https://prod-49.westus.logic.azure.com:443/workflows/2c614ab243244f2db1f10b462753208e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=thFXVLzYx-VUD85W9gp_jpFgOcHhwo8sFHxDR1M9Tds')
            .then((response) => {
                setSkillsData(response.data.vacancySkills);
            })
            .catch((error) => {

            });
    }, [skillsData]);


    const handleChange = (event) => {
        const { name, value, checked } = event.target

        if (name != "candidateEmail" && name !== "additionalInformation") {
            if (name === 'phoneNumber' || name === 'previousExperience') {
                if (value < 0) {
                    return;
                }
            } else {
                if (!/^[^\d]*$/.test(value)) {
                    return;
                }
            }
        }

        if (name === 'policies') {
            setForm({ ...form, [name]: checked })
        } else {
            setForm({ ...form, [name]: value })
        }

    }

    const handleSelectOption = (option, keyOption, set) => {
        if (keyOption == 'skills') {

            setSkillsArray([...skillsArray, option]);
            setForm({
                ...form,
                [keyOption]: [...skillsArray, option].join(",")
            });
        } else if (keyOption == 'vacancy') {
            setForm({ ...form, [keyOption]: option.vacancyName, ["RecruitingEffort"]: option.recruitingEffortId, ["VacancyGUID"]: option.vacancyGUID })
        } else if (keyOption === "phoneNumberCode") {
            setSelectedCountryCode(option);
            setForm({ ...form, [keyOption]: option })
        } else {
            setForm({ ...form, [keyOption]: option })
        }
        set((prev) => !prev);
    }

    const [selectedContactWay, setSelectedContactWay] = useState("");

    const onValidate = (form) => {
        let errors = {};

        if (!form.firstName.trim()) {
            errors.firstName = t('NAME_REQUIRED');
        }

        if (!form.lastName.trim()) {
            errors.lastName = t('LAST_NAME_REQUIRED');
        }

        if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumberCode.trim())) {
            errors.phoneNumberCode = t('PHONE_CODE_REQUIRED');
        }

        if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumber.trim())) {
            errors.phoneNumber = t('PHONE_NUMBER_REQUIRED');
        }

        if (((selectedContactWay === "1") || (selectedContactWay === "3")) && (!form.candidateEmail.trim())) {
            errors.candidateEmail = t('EMAIL_REQUIRED');
        }

        if (!selectedContactWay) {
            errors.contactWay = t("CONTACT_WAY_VALIDATION")
        }

        if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (form.phoneNumber <= 0)) {
            errors.phoneNumber = t('PHONE_NUMBER_ZERO')
        }

        if (!form.VacancyGUID.trim()) {
            errors.VacancyGUID = t('VACANCY_GUID_REQUIRED');
        }

        if (form.skills.length === 0) {
            errors.skills = t('SKILLS_REQUIRED');
        }

        if (!form.previousExperience.trim()) {
            errors.previousExperience = t('PREVIOUS_EXPERIENCE_REQUIRED');
        }

        if (!form.candidateResume || Object.keys(form.candidateResume).length === 0) {
            errors.candidateResume = t('CANDIDATE_RESUME_REQUIRED');
        }

        if (!form.additionalInformation.trim()) {
            errors.additionalInformation = t('ADDITIONAL_INFO_REQUIRED');
        }

        if (!form.policies) {
            errors.policies = t('POLICIES_REQUIRED');
        }

        return errors;
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');

    const handleFileChange = async (event) => {
        setSelectedFile(event.target.files[0]);
        const base64File = await convertToBase64(event.target.files[0]);
        setForm({
            ...form, ["candidateResume"]: {
                "content-type": event.target.files[0].type,
                content: base64File,
            }
        })
    };

    const onChooseFile = (e) => {
        e.preventDefault()
        inputRef.current.click();
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
        });
    };



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (elementoRef.current && !elementoRef.current.contains(event.target) &&
                // !event.target.classList.contains('inputNumber') &&
                // !event.target.classList.contains('inputNumber2') &&
                !event.target.classList.contains('arrowDownInput')
            ) {
                setOpenDropdownCountryCode(false);
            }
            if (inputRefPosition.current && !inputRefPosition.current.contains(event.target) &&
                // !event.target.classList.contains('inputNumber') &&
                // !event.target.classList.contains('inputNumber2') &&
                !event.target.classList.contains('arrowDownInput')
            ) {
                setOpenPositionDropdowns(false);
            }

        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const form2 = useRef();


    onValidate(form)

    const [isFetchSuccessful, setIsFetchSuccessful] = useState(false);

    const sendEmail = async (e) => {
        e.preventDefault();

        const validationErrors = onValidate(form);
        setErros(validationErrors);


        if (!selectedFile) {
            setUploadStatus('Please select a file to upload.');
            return;
        }
        if (Object.keys(validationErrors).length === 0) {
            try {
                const base64File = await convertToBase64(selectedFile);
                const payload = {
                    candidateResume: {
                        "content-type": selectedFile.type,
                        content: base64File,
                    },
                };
                setForm({
                    ...form, ["candidateResume"]: {
                        "content-type": selectedFile.type,
                        content: base64File,
                    }
                })

                const convertSkillsToString = (data) => {
                    return {
                        ...data,
                        skills: data.skills.join(', ')
                    };
                };

                const response = await fetch('https://prod-157.westus.logic.azure.com:443/workflows/c5224c81f5fa471d9e4bf02bbd3c2f0a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=wcq_EpvAkydHzfVvbtQ7uJ2NU6_3UjXPi49qY8Efawc', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(convertSkillsToString(form)),
                });

                if (response.ok) {
                    setIsFetchSuccessful(true); // Cambia el estado a true si la respuesta es exitosa
                    setForm(initialData)

                } else {
                    setIsFetchSuccessful(false); // Cambia el estado a false si la respuesta no es exitosa
                }

            } catch (error) {
                setUploadStatus('Error uploading file.');
            }
        }
    };

    const [openPositionDropdowns, setOpenPositionDropdowns] = useState({});
    const [positionDropdowns, setPositionDropdowns] = useState([{ id: 1, value: '' }]);
    const [openTimeDropdowns, setOpenTimeDropdowns] = useState({});
    const [timeDropdowns, setTimeDropdowns] = useState([{ id: 1, value: '' }]);
    const [dropdowns, setDropdowns] = useState([{ id: 1, value: '' }]);

    const handleSelectOptionPosition = (option, dropdownId) => {
        setPositionDropdowns((prev) =>
            prev.map((dropdown) =>
                dropdown.id === dropdownId ? { ...dropdown, value: option.vacancyName } : dropdown
            )
        );

        setOpenPositionDropdowns((prev) => ({ ...prev, [dropdownId]: false }));

        setForm((prev) => ({
            ...prev,
            VacancyGUID: [...prev.VacancyGUID, option],
        }));
        setForm({ ...form, ["RecruitingEffort"]: option.recruitingEffortId, ["VacancyGUID"]: option.vacancyGUID })


        setcombinedChildren(Array.from(new Set(vacanciesDataOriginal.flatMap(parent => parent.vacancySkills).map(option => option.technologyName))));

    };
    const [combinedChildren, setcombinedChildren] = useState([])

    const handleSelectOptionTime = (event) => {
        const selectedValues = Array.isArray(event.value) ? event.value : [event.value];
        setForm((prev) => ({
            ...prev,
            skills: selectedValues,
        }));
    };

    const addTimeDropdown = () => {
        setTimeDropdowns((prev) => [...prev, { id: prev.length + 1, value: '' }]);
    };

    const isAllOptionsSelected = vacanciesData.every((option) =>
        dropdowns.some((d) => d.value === option.Service_Name)
    );

    const sortedCountriesCode = useSortedCountries("country", filteredCountryCode)

    return (
        <>
            <Header></Header>
            <div className="mainGetHireVMAFormImg"></div>
            <main className="workWithUsFormMainContainer">
                <h2 className="form-title">{t('FORM_TITLE')}</h2>
                <div className="contact-us-forms">
                    <form ref={form2}>
                        <div className="nameContainer">
                            <div className="errorContainer">
                                <InputComponent
                                    className={`input-width`}
                                    name={'firstName'}
                                    type={'text'}
                                    value={form.firstName}
                                    handleChange={handleChange}
                                    label={t('FORM_FIRST_NAME')}
                                />
                                {errors.firstName && <div className="alert alert-danger">{errors.firstName}</div>}
                            </div>
                            <div className="errorContainer">
                                <InputComponent
                                    className={`input-width`}
                                    name={'lastName'}
                                    type={'text'}
                                    value={form.lastName}
                                    handleChange={handleChange}
                                    label={t('FORM_LAST_NAME')}
                                />
                                {errors.lastName && <div className="alert alert-danger">{errors.lastName}</div>}
                            </div>
                        </div>
                        <ContactWay
                            selectedContactWay={selectedContactWay}
                            setSelectedContactWay={setSelectedContactWay}
                        />
                        <div className="phoneEmailContainer">
                            {((selectedContactWay === "2") || (selectedContactWay === "3")) && (<div className="phoneContactContainerMain">
                                <p>{t('FORM_PHONE_NUMBER')}</p>
                                <div className="phoneContactContainer phoneCode">
                                    <div className="phoneContactDropdown phoneCode" ref={elementoRef}>
                                        <div className="phoneContactInputContainer">
                                            <input
                                                type="text"
                                                className={`inputNumber ${openDropdownCountryCode && "active"}`}
                                                onClick={() => handleDropdown(setOpenDropdownCountryCode)}
                                                onChange={handleSearchChangeCountryCode}
                                                name="phoneNumberCode"
                                                value={selectedCountryCode ? selectedCountryCode : searchTermCountryCode}
                                                autoComplete="off"
                                            />
                                            <img src={'/img/arrowDownDropdown.svg'} alt="" />
                                        </div>
                                        {openDropdownCountryCode && (
                                            <div className="phoneContactOptions">
                                                <div className="optionsContainer">
                                                    {sortedCountriesCode.map((option) => {
                                                        return <p onClick={() => handleSelectOption(option.code, "phoneNumberCode", setOpenDropdownCountryCode)} key={option.country}>
                                                            {i18n.language === 'es' ? option.country : option.country_EN}
                                                        </p>
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="errorContainer">
                                        <InputComponent
                                            className={`input-width`}
                                            name={'phoneNumber'}
                                            type={'number'}
                                            handleChange={handleChange}
                                            value={form.phoneNumber}
                                        />
                                    </div>
                                </div>
                                {errors.phoneNumber && <div className="alert alert-danger">{errors.phoneNumber}</div>}
                                {errors.phoneNumberCode && <div className="alert alert-danger">{errors.phoneNumberCode}</div>}
                            </div>)}
                            {((selectedContactWay === "1") || (selectedContactWay === "3")) && (<div className="errorContainer email">
                                <InputComponent
                                    className={`input-width`}
                                    label={t('FORM_EMAIL')}
                                    name={'candidateEmail'}
                                    type={'email'}
                                    value={form.candidateEmail}
                                    handleChange={handleChange}
                                />
                                {errors.candidateEmail && <div className="alert alert-danger">{errors.candidateEmail}</div>}
                            </div>)}
                        </div>
                        {errors.contactWay && <div className="alert alert-danger">{errors.contactWay}</div>}

                        <div className="errorContainer">
                            <InputComponent
                                className={`input-width`}
                                name={'previousExperience'}
                                type={'number'}
                                value={form.previousExperience}
                                label={t('FORM_LABEL_PREVIOUS_EXPERIENCE')}
                                handleChange={handleChange}
                            />
                            {errors.previousExperience && <div className="alert alert-danger">{errors.previousExperience}</div>}
                        </div>
                        <p>{t('FORM_LABEL_SUBMIT_RESUME')}</p>
                        <div className="uploadFileContainer">
                            <input ref={inputRef} type="file" className="button" onChange={handleFileChange} style={{ display: "none" }} />
                            <button className="button" onClick={onChooseFile}>
                                {t('FORM_LABEL_CV')}
                            </button>
                            <p>{selectedFile?.name}</p>
                        </div>
                        {errors.candidateResume && <div className="alert alert-danger">{errors.candidateResume}</div>}
                        <div className={`phoneContactContainer services  ${(positionDropdowns.length > 1 || positionDropdowns[positionDropdowns.length - 1].value) && "edit"}`}>
                            <div className="dropdownsContainer">
                                <p>{t('FORM_LABEL_JOB_POSITION')}</p>
                                {positionDropdowns.map((dropdown) => (
                                    <div key={dropdown.id} className="phoneContactDropdown" ref={inputRefPosition}>
                                        <div className="phoneContactInputContainer">
                                            <div className="servicesDropdownContainer">
                                                <input
                                                    type="text"
                                                    className={`inputNumber2 ${dropdown.value && 'active'}`}
                                                    onClick={() =>
                                                        setOpenPositionDropdowns((prev) => ({
                                                            ...prev,
                                                            [dropdown.id]: !prev[dropdown.id],
                                                        }))
                                                    }
                                                    placeholder={t('WORK_US_VACANCY')}
                                                    name={`vacancy-${dropdown.id}`}
                                                    readOnly
                                                    disabled={!!dropdown.value}
                                                    value={dropdown.value}
                                                />
                                                <img src={'/img/arrowDownDropdown.svg'} alt="" />
                                            </div>
                                            {(positionDropdowns.length > 1 || positionDropdowns[positionDropdowns.length - 1].value) && (
                                                <p
                                                    className="editButton"
                                                    onClick={() =>
                                                        setPositionDropdowns((prev) =>
                                                            prev.map((d) =>
                                                                d.id === dropdown.id ? { ...d, value: '' } : d
                                                            )
                                                        )
                                                    }
                                                >
                                                    {t("EDIT")}
                                                </p>
                                            )}
                                        </div>
                                        {errors.VacancyGUID && <div className="alert alert-danger">{errors.VacancyGUID}</div>}
                                        {openPositionDropdowns[dropdown.id] && (
                                            <div className="phoneContactOptions">
                                                <div className="optionsContainer">
                                                    {vacanciesData.map((option) => {
                                                        const isOptionSelected = positionDropdowns.some((d) => d.value === option.vacancyGUID);
                                                        if (!isOptionSelected || !(option.vacancyName === "")) {
                                                            return (
                                                                <p
                                                                    onClick={() => handleSelectOptionPosition(option, dropdown.id)}
                                                                    key={option.vacancyName}
                                                                >
                                                                    {option.vacancyName} {option.vacancyName === ""}
                                                                </p>
                                                            );
                                                        } else {
                                                            return (
                                                                <p style={{ color: 'grey' }}>
                                                                    No vacancies
                                                                </p>
                                                            )
                                                        }
                                                        return null;
                                                    })}
                                                    {(vacanciesData.length === 0 || isAllOptionsSelected) && (
                                                        <p style={{ color: 'grey' }}>
                                                            No vacancies
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="phoneContactContainer services">
                            <div className="dropdownsContainer">
                                <p>{t('FORM_LABEL_SKILLS')}</p>
                                {timeDropdowns.map((dropdown) => (
                                    <div key={dropdown.id} className="phoneContactDropdown">
                                        <Select
                                            data={combinedChildren}
                                            // value={selectedService}
                                            selectMultiple={true}
                                            inputProps={{
                                                className: "inputNumber2"
                                            }}
                                            theme="ios"
                                            themeVariant="light"
                                            filter={true}
                                            onChange={handleSelectOptionTime}
                                            placeholder={t('WORK_US_VACANCY')}
                                        />
                                    </div>
                                ))}
                            </div>
                            {timeDropdowns[timeDropdowns.length - 1].value && (
                                <button className="buttonAddDropdown" onClick={addTimeDropdown}>
                                    + Add Another Skill
                                </button>
                            )}
                        </div>
                        {errors.skills && <div className="alert alert-danger">{errors.skills}</div>}

                        <p>{t('WORK_US_RESUME_MESSAGE')}</p>
                        <div className="errorContainer">
                            <TextAreaComponent
                                className={`input-width textArea`}
                                name={'additionalInformation'}
                                handleChange={handleChange}
                                value={form.additionalInformation}
                            />
                            {errors.additionalInformation && <div className="alert alert-danger">{errors.additionalInformation}</div>}
                        </div>
                        <div className="termsAndConditionsContainer">
                            <input type="checkbox" name="policies" onChange={handleChange} />
                            <p className="termsAndConditionsText">{t('FORM_POLICY')} <a href="/PoliticaDeTratamientoDeDatosPersonales.pdf">{t('FORM_POLICY_LINK')}</a></p>
                        </div>
                        {errors.policies && <div className="alert alert-danger">{errors.policies}</div>}
                        <button className="button" onClick={sendEmail}>{loading ? t('FORM_BUTTON_LOADING') : t('FORM_BUTTON')}</button>
                        <FetchSuccessComponent isFetchSuccessful={isFetchSuccessful} setIsFetchSuccessful={setIsFetchSuccessful} url={'/vma'} />
                    </form>
                </div>
            </main>

            <Footer></Footer>
        </>
    );
};

export default GetHireForm;
