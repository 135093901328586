import { useState, useEffect, useRef } from 'react';

function useDropdown(dataOriginal, filterFunction) {

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredArray, setFilteredArray] = useState(dataOriginal);
    const [selectedItem, setSelectedItem] = useState('');
    const [firstDataOriginal, setFirstDataOriginal] = useState('');
    const inputRef = useRef();

    useEffect(() => {
        setFilteredArray(dataOriginal);
        setFirstDataOriginal(dataOriginal);
    }, [dataOriginal !== firstDataOriginal]);

    const handleSearchChange = (event) => {
        if (event.target.name !== 'phoneNumberCode' && event.target.name !== 'extPhoneNumber') {
            if (!/^[^\d]*$/.test(event.target.value)) {
                return;
            }
        }
        if (event.target.value !== selectedItem) {
            setSelectedItem('');
        }
        setFilteredArray(filterFunction(dataOriginal, event.target.value));
        setSearchTerm(event.target.value);
        console.log(event.target.value);
    };

    return {
        isOpen,
        setIsOpen,
        searchTerm,
        setSearchTerm,
        filteredArray,
        setFilteredArray,
        inputRef,
        handleSearchChange,
        selectedItem,
        setSelectedItem
    };
}

export default useDropdown;
