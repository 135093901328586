
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
const userLanguage = navigator.language.split('-')[0];

i18n
    .use(initReactI18next) // Aquí puedes seguir usando initReactI18next si lo prefieres
    .init({
        resources: {
            en: {
                translation: {
                    // Textos en inglés...
                    //HOME PAGE
                    HOME_TITLE: 'START',
                    HOME_TITLE_2: 'AUTOMATING YOUR WORLD',
                    HOME_WELCOME_TITLE: 'Join us to access a wide range of top-quality services!',
                    HOME_MAIN_BUTTON: 'Automate your company.',
                    HOME_SERVICES_BUTTON: 'Dare to learn more!',
                    HOME_SERVICE_IA_TITLE_1: 'INTELIGENCIA ARTIFICIAL (IA) &',
                    HOME_SERVICE_IA_TITLE_2: 'MACHINE LEARNING (ML)',
                    HOME_SERVICE_RPA_TITLE_1: 'ROBOTIC PROCESS',
                    HOME_SERVICE_RPA_TITLE_2: 'AUTOMATION (RPA)',
                    HOME_SERVICE_CLOUD_TITLE: 'CLOUD SOLUTIONS',
                    HOME_SERVICE_BI_TITLE_1: 'BUSINESS',
                    HOME_SERVICE_BI_TITLE_2: 'INTELLIGENCE (BI)',
                    HOME_SERVICE_WEB_DEV_TITLE_1: 'WEB DEVELOPMENT &',
                    HOME_SERVICE_WEB_DEV_TITLE_2: 'APP DEVELOPMENT',
                    HOME_SERVICE_IA_TEXT: "Unlock your data's potential with our AI and ML solutions. From predictive algorithms to evolving intelligent systems, we help elevate your business by anticipating your needs.",
                    HOME_SERVICE_RPA_TEXT: "Streamline your team’s work with Robotic Process Automation. Our RPA solutions boost efficiency, cut errors, and let your team focus on what truly matters.",
                    HOME_SERVICE_CLOUD_TEXT: "Elevate your business with our cloud solutions. Enjoy flexible, secure, and accessible technology for storage, processing, and custom applications that scale with your needs.",
                    HOME_SERVICE_BI_TEXT: "Make strategic decisions with our Business Intelligence tools. We turn raw data into valuable insights, helping you anticipate trends, spot opportunities, and boost performance.",
                    HOME_SERVICE_WEB_DEV_TEXT: "Excel in the digital world with our custom web and app development. We create interactive, brand-driven experiences and apps that engage your users.",
                    HOME_ABOUT_US: 'About us',
                    HOME_ABOUT_DESCRIPTION: 'At 5ig, innovation meets intelligence in the dynamic world of artificial intelligence (AI) and automation. As leaders in this exciting field, our passion and expertise shine brightest in our work with Microsoft’s Power Platform, redefining new standards in intelligent automation and Robotic Process Automation (RPA).',
                    HOME_ABOUT_DESCRIPTION_2: 'Our mission is to transform the way organizations operate. We harness the power of AI, machine learning, and natural language processing to turn complex challenges into opportunities for efficiency and growth. Our goal is to take your operations from good to great, making every process smoother, faster, and more efficient.',
                    HOME_ABOUT_DESCRIPTION_3: 'Always hand in hand with our clients and technology.',
                    HOME_LINKEDIN_BUTTON: 'Find us on LinkedIn',
                    HOME_BUTTON: "Home",
                    HOME_BUTTON_INFO: "More info",

                    //SERVICES PAGES TITLE
                    HOME_SERVICES_TITLE: "More Services",
                    OUR_SERVICES: "Our Services",

                    //SERVICES PAGES(IA)
                    IA_SERVICE_FIRST_TITLE: 'Predictive Analysis Platform',
                    IA_SERVICE_SECOND_TITLE: 'Intelligent Virtual Assistant',
                    IA_SERVICE_THIRD_TITLE: 'Recommendation Systems',

                    IA_SERVICE_FIRST_DESCR: 'We provide a predictive analysis platform leveraging AI and ML, enabling businesses to make informed decisions through the identification of data trends and patterns.',
                    IA_SERVICE_SECOND_DESCR: 'We develop AI-powered virtual assistants that are faster and smarter, enhancing customer service, automating responses, and offering 24/7 support.',
                    IA_SERVICE_THIRD_DESCR: 'We build Machine Learning recommendation systems that boost customer retention and sales through personalized suggestions.',

                    //SERVICES PAGES(RPA)
                    RPA_SERVICE_FIRST_TITLE: 'Business Process Automation',
                    RPA_SERVICE_SECOND_TITLE: 'RPA Implementation Consultancy',

                    RPA_SERVICE_FIRST_DESCR: 'We implement RPA solutions to automate repetitive and manual tasks, which increases efficiency and reduces human errors.',
                    RPA_SERVICE_SECOND_DESCR: 'We offer consultancy and RPA implementation to help companies automate tasks like data entry, invoice processing, customer service, HR onboarding, and more.',

                    //SERVICES PAGES (CLOUD)
                    CLOUD_SERVICE_FIRST_TITLE: 'Cloud Migration',
                    CLOUD_SERVICE_SECOND_TITLE: 'Cloud Management',

                    CLOUD_SERVICE_FIRST_DESCR: 'We help companies migrate their systems and data to the cloud, offering scalable and secure infrastructure options.',
                    CLOUD_SERVICE_SECOND_DESCR: 'We provide cloud management services, monitoring, and optimization to ensure optimal performance and strong security in cloud environments.',

                    //SERVICES PAGES (BI)
                    BI_SERVICE_FIRST_TITLE: 'Custom Dashboards',
                    BI_SERVICE_SECOND_TITLE: 'Advanced Data Analysis',

                    BI_SERVICE_FIRST_DESCR: 'We design custom dashboards and control panels that provide real-time information and data analysis for strategic decision-making.',
                    BI_SERVICE_SECOND_DESCR: 'We use BI tools to perform advanced analyses, such as trend analysis, customer segmentation, and anomaly detection.',

                    //SERVICES PAGES(WEB_DEV)
                    WEB_DEV_SERVICE_FIRST_TITLE: 'Responsive Website Development',
                    WEB_DEV_SERVICE_SECOND_TITLE: 'Mobile App Development',

                    WEB_DEV_SERVICE_FIRST_DESCR: 'We create responsive and appealing websites that adapt to different devices and offer an exceptional user experience.',
                    WEB_DEV_SERVICE_SECOND_DESCR: 'We design and develop native and cross-platform mobile applications to meet the specific needs of our clients.',

                    //QUIENES SOMOS
                    QS_TITLE: 'OUR TEAM',
                    QS_VALUES: 'Values',
                    QS_VALUES_DESCR: 'At our core, we value trust, transparency, and ethical conduct. We\'re committed to developing solutions that not only respond swiftly to our clients\' needs but also incorporate cutting-edge technology and innovation to secure a competitive advantage.',
                    QS_MISION: 'Goal',
                    QS_MISION_DESCR: 'Our mission is to establish ourselves as the foremost trusted advisor in the realms of artificial intelligence, automation, and innovation. We aim to achieve this through offering a suite of solutions that are both effective and accessible, ensuring our clients receive unparalleled value.',
                    QS_VISION: 'Vision',
                    QS_VISION_DESCR: 'We envision a future where technology and innovation drive transformative change in both society and the corporate world. Our goal is to lead this change by delivering rapid advancements and tangible impacts, setting new standards of excellence and progress.',

                    //AUTOMATE PAGE
                    AUT_TITLE: 'Automate your company. ',
                    AUT_SUBTITLE: 'What are automated processes? ',

                    AUT_BUTTON: 'Chat with us',

                    AUT_IMG_TITLE_1: 'Artificial intelligence',
                    AUT_IMG_TITLE_2: 'Microsoft Power Platform',
                    AUT_IMG_TITLE_3: 'Machine Learning',
                    AUT_IMG_TITLE_4: 'Innovative Technological Platforms',

                    AUT_FIRST_TITLE: 'Our Clients Aim',
                    AUT_SECOND_TITLE: 'Our Clients Vision',
                    AUT_THIRD_TITLE: 'Our Clients Concerns',

                    AUT_FIRST_TEXT: 'Automated processes, powered by Artificial Intelligence, Microsoft Power Platform, Machine Learning, and innovative technology platforms, empower organizations to enhance their performance, efficiency, and flexibility.',
                    AUT_SECOND_TEXT: 'By integrating these cutting-edge solutions, businesses can craft exceptional and unforgettable customer experiences, setting new standards in service delivery and operational excellence.',
                    AUT_THIRD_TEXT: 'Our clients want to make their work better using automation technology. They\'re looking to improve and speed up their processes with solutions that fit exactly what they need. We help them do this by offering training, advice, and resources to make automated processes easier to use and more effective.',
                    AUT_FOUR_TEXT: 'They also need our help to fit these new technologies into their existing systems without running into problems with tech, organization, or rules. We provide smart plans, help with setting up, and technology platforms that make it easy to start using automation, ensuring everything works smoothly and meets the required standards. ',
                    AUT_FIFTH_TEXT: 'Our clients dream of becoming industry leaders through innovation and digital transformation enabled by automation. To support this vision, we aim to inspire them with success stories, highlight the advantages and possibilities of automation, and motivate them to embrace and innovate with these technologies.',
                    AUT_SIX_TEXT: 'They also strive for a seamless blend of automation and human effort, fostering a culture of teamwork and empowerment. Involving them in the creation, deployment, and upkeep of automated systems, clarifying the importance and benefits of these processes, and appreciating their efforts and accomplishments can be key strategies in achieving this harmony. ',
                    AUT_SEVEN_TEXT: 'Our clients worry about keeping control when using automated processes. They\'re concerned about mistakes or problems automation might cause. Our goal is to make sure these processes are safe, clear, and easy to track. We set up checks and oversight to keep everything running smoothly and have clear plans for handling any unexpected situations.',
                    AUT_EIGHT_TEXT: 'Another common worry is about automation taking over jobs and making people less important or competitive. We want to reassure everyone that our automation is here to support and enhance human work, not replace it. We focus on bringing out the best in everyone\'s abilities and providing chances for learning and growing in your career, making sure automation works for you, not against you.',

                    //CONTACT US PAGE
                    CONTACT_US_TITLE: 'Navigate your business into the Next Wave of Technological Innovation!',
                    //OTHER TITLES
                    MORE_SERVICES: 'More services',

                    //CONTACT FORM
                    FORM_HEADER: 'CONTACT US',
                    FORM_TITLE: 'Please use the form below to contact us. We will get back to you as quickly as possible. Thanks!',
                    FORM_CONTACT_US: 'CONTACT US',
                    FORM_FOLLOW: 'FOLLOW US ON',
                    FORM_NAME: 'Contact',
                    FORM_EMAIL: 'Give us your email',
                    FORM_PHONE: 'Give us your phone number',
                    FORM_MESSAGE: 'What would you like to contact us about?',
                    FORM_POLICY: 'By checking this box, you confirm that you have read and agree to our data processing policy',
                    FORM_POLICY_LINK: ' Click here to read the full policy.',
                    FORM_BUTTON: 'Send',
                    FORM_BUTTON_LOADING: 'Sending...',
                    FORM_NAME_LABEL: 'Your name here',
                    FORM_PLACEHOLDER: '',
                    FORM_NAME_PHONE: 'Phone',
                    FORM_NAME_MESSAGE: 'Type your message here',

                    //WORK WITH US FORM
                    WORK_US_YOUR_COUNTRY: "Select your country",
                    WORK_US_YOUR_STATE: "Select your state",
                    WORK_US_WAY_KNOW: "How did you know about us?",
                    WORK_US_SKILLS: "Select your Skills",
                    WORK_US_VACANCY: "Select an option",
                    WRITE_YOUR_DESIRED_POSITION: "Write the position",
                    WORK_US_RESUME_MESSAGE: "Tell us about you",

                    //HEADER
                    HEADER_CONTRACT: 'I’m Interested!',
                    HEADER_SERVICES: 'Services',
                    HEADER_WHOWEARE: 'Our Team',
                    WORK_WITH_US: 'Work with us',
                    GET_HIRED: 'Work with us',

                    //FOOTER
                    FOOTER_MESSAGE: 'The management approves the data processing policy on February 8, 2024. It is published on the respective platforms for public access.',
                    FOOTER_POLICIES: 'Policies',
                    FOOTER_TERMS: 'Terms of use',
                    FOOTER_DATA: 'Data treatment',
                    FOOTER_WHOWEARE: 'Our Team',
                    FOOTER_CONTACT_US: 'Contact us',

                    //BUTTONS
                    APPOINTMENT_BUTTON: "Book An Appointment",

                    //TECH SERVICES TITLES
                    "START_AUTOMATING_WORLD": "Start Automating your world!",
                    Tools_That_We_Use: "Tools That We Use",

                    RECRUITMENT_TITLE_CARD: 'Recruitment',
                    RECRUITMENT_TITLE: 'Discover the best talent with our recruitment service.',
                    RECRUITMENT_TEXT: `Our headhunting service specializes in identifying and recruiting top-tier talent for your business. Whether you need executives, managers, or specialized roles, we ensure you get the right fit.`,
                    APPLY_NOW: 'Apply Now',
                    EXPERT_RECRUITERS: "Expert Recruiters",
                    EXPERT_RECRUITERS_DESC: "Our team has deep industry knowledge and connections.",

                    PERSONALIZED_APPROACH: "Personalized Approach",
                    PERSONALIZED_APPROACH_DESC: "We tailor our search to meet your unique requirements.",

                    PROVEN_TRACK_RECORD: "Proven Track Record",
                    PROVEN_TRACK_RECORD_DESC: "We've successfully placed top talent in leading companies.",

                    FIND_YOUR_NEXT_TALENT: 'Find Your Next Talent',
                    FEATURED_JOBS: 'Featured Positions',

                    RECRUITMENT_CTA: 'Don\'t miss out on incredible remote IT opportunities. Join us today and take your career to new heights!',
                    FEATURE_GLOBAL_OPPORTUNITIES: 'Global Opportunities',
                    FEATURE_GLOBAL_OPPORTUNITIES_TEXT: 'Access exciting job opportunities with leading companies worldwide without leaving your home.',
                    FEATURE_COMPETITIVE_COMPENSATION: 'Competitive Compensation',
                    FEATURE_COMPETITIVE_COMPENSATION_TEXT: 'Benefit from competitive salaries and attractive perks offered by our partner companies.',
                    FEATURE_SUPPORT_TRAINING: 'Support & Training',
                    FEATURE_SUPPORT_TRAINING_TEXT: 'Receive ongoing support and training to enhance your skills and career growth.',
                    UNLOCK_GLOBAL_CAREER_TITLE: 'Unlock Your Global IT Career!',
                    JOIN_TALENT_POOL_TITLE: 'Connecting you with the best professionals in the industry.',
                    WE_OFFER: "We Offer You",
                    "MORE_INFO_BUTTON": "More Info",
                    "TECH_SERVICES_TITLE": "Tech Services",
                    "TECH_SERVICES_TEXT": "We create innovative products without manual programming, allowing us to swiftly adapt to our clients' specific needs.",
                    "VMA_TITLE_HOME": "VMA",
                    "VMA_TEXT_HOME": "Our team manages scheduling, patient communication, records, prescriptions, and insurance coordination.",
                    "VMA_SUBTITLE": "(Virtual Medical Assistant)",
                    "CONSULTING_TITLE": "Consulting",
                    "CONSULTING_TEXT": "Our service encompasses strategic planning, development, optimization, finance, marketing, technology, and human resources.",
                    "INVESTMENTS_TITLE": "Investments",
                    "INVESTMENTS_TEXT": "We guide investments in industries such as construction, retail, entertainment, startups, finance, and more.",
                    "HOME_INTRODUCING_PEOPLE_360": "Introducing People 360",
                    "HOME_ONE_STOP_SOLUTION": "Your One-Stop Solution for Employee and Project Management.",
                    "HOME_PEOPLE_360_DESC": "People 360 is designed to streamline your HR and project management tasks. With our platform, both employees and employers can easily access company benefits, track project progress, and manage client information, all in one place.",
                    "HOME_EMPLOYEE_BENEFITS": "Employee Benefits",
                    "HOME_EMPLOYEE_BENEFITS_DESC": "Manage vacations, days off, and student loans effortlessly.",
                    "HOME_PROJECT_TRACKING": "Project Tracking",
                    "HOME_PROJECT_TRACKING_DESC": "Monitor the status of projects, user stories, epics, and tasks.",
                    "HOME_CLIENT_INFORMATION": "Client Information",
                    "HOME_CLIENT_INFORMATION_DESC": "Employers can access detailed client information quickly and easily.",
                    "HOME_ECOMMERCE_B2B": "E-commerce B2B",
                    "HOME_ECOMMERCE_B2B_DESC": "Boost your B2B sales with our E-commerce solutions for easy bulk ordering, inventory management, and streamlined transactions.",
                    "HOME_CRM": "CRM",
                    "HOME_CRM_DESC": "Enhance customer relationships with our CRM system. Track interactions, manage leads, and boost satisfaction using advanced analytics.",
                    "HOME_ERP": "ERP",
                    "HOME_ERP_DESC": "Optimize operations with our ERP solutions. Integrate finance, supply chain, and other processes to enhance efficiency and productivity.",
                    "HOME_CLIENT_SUCCESS_STORIES": "Success Stories",
                    "HOME_CLIENT_STORY_1_TITLE": "AT&T Chief Data Office",
                    "HOME_CLIENT_STORY_1_DESC": "\"Thanks to the partnership with 5IG Solutions, we not only created one of the largest Centers of Excellence in Automation but also implemented over 3,000 automations that completely transformed our operational processes. Additionally, their expertise in integrating advanced chatbots significantly enhanced our customer service. 5IG Solutions has been a strategic partner in our digital transformation and operational efficiency optimization, and has continued to help us deploy generative AI for over 80,000 people.\"",
                    "HOME_CLIENT_STORY_2_TITLE": "VP of Marketing at FinancePros",
                    "HOME_CLIENT_STORY_2_DESC": "\"5IG’s CRM solutions have transformed how we manage customer relationships. The advanced analytics and streamlined lead management have improved our customer satisfaction and sales performance.\"",
                    "HOME_CLIENT_ENVIGADO_NAME": "Secretariat of Economic Development of Envigado",
                    "HOME_CLIENT_STORY_3_TITLE": "Contracting",
                    "HOME_CLIENT_ENVIGADO_TITLE": "Allied Business",
                    "HOME_CLIENT_STORY_3_DESC": "\"Thanks to the professionalism of 5IG Solutions, we successfully supplied qualified personnel to one of the leading telecommunications companies in the world. With their support, we reduced the attrition and termination rate by 73%, significantly improving our performance metrics. Additionally, the professional and bilingual Latin American workforce we provided helped increase our return rate and maintain competitive pricing in the global market. 5IG Solutions has been a key partner in our success, helping us stand out in the demanding telecommunications sector.\"",
                    "HOME_CLIENT_ENVIGADO_DESC": "\"The high level of professionalism demonstrated by the young team at 5IG Solutions has been exceptional. Their dedication, professional approach, and ability to quickly adapt to various challenges have exceeded our expectations on multiple occasions. The way they handle each project with meticulousness and commitment not only ensures high-quality results but also contributes to building strong client relationships. Their ability to anticipate needs and provide effective solutions has been crucial to our success. We deeply appreciate their continuous effort and their constant pursuit of improvement and exceeding established standards.\"",
                    "HOME_DRIVING_FUTURE_TITLE": "Driving the future with comprehensive solutions",
                    "HOME_DRIVING_FUTURE_DESC_1": "Offering diverse services in one place enables cross-promotion and helps clients discover related solutions.",
                    "HOME_DRIVING_FUTURE_DESC_2": "An integrated approach enhances collaboration and communication, leading to more efficient client solutions.",
                    "HOME_DRIVING_FUTURE_DESC_3": "Multiple services enable cross-selling and upselling, revealing beneficial options to clients.",
                    HOME_PARTNERS: "Companies we work with",
                    "HOME_DRIVING_FUTURE_DESC_4": "Clients get tailored solutions that utilize your company’s expertise and resources across various service areas.",
                    "HOME_DRIVING_FUTURE_DESC_5": "Visitors can access diverse services from one platform, saving time and effort.",
                    "BENEFITS_TITLE": "Benefits To Work With Us",
                    "BENEFIT_1_TITLE": "We take pride in cultivating strong business relationships",
                    "BENEFIT_1_TEXT": "providing high-quality services that deliver exceptional results. Our commitment to excellence sets us apart and drives us to exceed our clients' expectations.",
                    "BENEFIT_2_TITLE": "We embrace a remote work culture, with a young and experienced team",
                    "BENEFIT_2_TEXT": "specializing in automations and GenAI. Our flexibility and focus on innovation allow us to offer cutting-edge solutions that drive our clients' success in the digital age.",
                    "BOOK_APPOINTMENT_BUTTON": "Book An Appointment",
                    "ALL_UNDER_ONE_ROOF_TITLE": "All Under One Roof!",
                    "ROOF_1_TEXT": "Having diverse services under one roof enables cross-promotion and synergy between different offerings. For example, clients interested in recruitment services may also find consulting solutions or tech solutions relevant to their needs.",
                    "ROOF_2_TEXT": "An integrated approach to services fosters collaboration and seamless communication between different teams or departments within the company, leading to more efficient and effective solutions for clients.",
                    "ROOF_3_TEXT": "Offering multiple services allows for cross-selling and upselling opportunities, where clients may discover additional services they hadn't initially considered but find beneficial.",
                    "ROOF_4_TEXT": "Clients can benefit from tailored and customized solutions that leverage the expertise and resources of your company across different service areas.",
                    "ROOF_5_TEXT": "Visitors can access a wide range of services conveniently from a single platform, saving time and effort in searching for different providers.",

                    CONNECT_BEST_WORKHAND: 'Connect with the best workhand',
                    EMPOWER_BUSINESS: 'Empower your business with our global workforce and expert consulting services. Take the next step to success by partnering with us today.',
                    BOOK_APPOINTMENT: 'Book An Appointment',
                    EXPLORE: 'Explore',
                    CONTRACTORS_FLEXIBILITY: 'We offer flexibility in staffing, allowing companies to scale their workforce up or down based on project demands. This flexibility helps them quickly adapt to changing market conditions without the long-term commitment of hiring full-time employees.',
                    CONTRACTORS_EXPERTISE: 'By partnering with contractors and consultants, companies gain access to specialized expertise and skills that may not be available in-house. These professionals bring fresh perspectives, industry knowledge, and best practices to projects, helping businesses achieve their objectives more effectively.',
                    CONTRACTORS_MULTIPLE_SERVICES: 'Using consulting services and contractors can be more cost-effective than hiring full-time employees for short-term projects or specialized tasks. Companies can save on overhead costs such as salaries, benefits, and training expenses while accessing high-quality talent to support their business objectives.',
                    COMPANIES_CONSULTING_SERVICES: 'COMPANIES CONSULTING SERVICES',
                    WORK_WITH_US_AS_A_CONTRACTOR: 'WORK WITH US AS A CONTRACTOR',
                    OUTSOURCING_SERVICES: 'ACCESS THE BEST TALENT FOR YOUR BUSINESS',

                    // CONSULTING SERVICES 

                    //CONSULTING VIEW

                    EXPLORE_OPPORTUNITIES: 'Let\'s explore opportunities together and see where your expertise can take us!',
                    APPLY_NOW: 'Apply Now!',
                    ABOUT_POSITION: 'About the Position',
                    POSITIONS_APPLY: 'Positions You Can Apply',
                    CONSULTING_FLEXIBILITY: 'Enjoy more flexibility in your work schedules. By working as a contractor you can choose when and where you work, allowing for a better work-life balance. This flexibility also extends to the types of projects you take on, allowing you to explore different industries and work with diverse clients.',
                    CONSULTING_DIVERSE_PROJECTS: 'As a contractor, you will have the opportunity to work on a variety of projects with different clients. This exposure to diverse projects can help expand your skill set, gain valuable experience, and build a strong portfolio. It also allows you to stay updated with industry trends and innovations.',
                    CONSULTING_HIGHER_EARNINGS: 'By working as a contractor you will have a higher earning potential than full-time employees. Since contractors are often paid based on the projects they complete or hours worked, you can negotiate your rates and take on multiple projects simultaneously, leading to higher income opportunities.',

                    // CONTRACTORS VIEW
                    HIRE_EXPERTS: 'Hire Experts',
                    SOFTWARE_DEVELOPER: 'Software Developer',
                    SOFTWARE_DEVELOPER_DESC: 'Unlock innovation and drive digital transformation with our skilled Software Developers. From coding wizardry to creating seamless user experiences, our developers bring your tech visions to life!',
                    CYBERSECURITY_EXPERT: 'Cybersecurity Expert',
                    CYBERSECURITY_EXPERT_DESC: 'Shield your digital assets and safeguard your business with our Cybersecurity Experts. Our specialists employ cutting-edge strategies to fortify your defenses, mitigate risks, and ensure data protection in an ever-evolving threat landscape.',
                    FULLSTACK_DEVELOPER: 'FullStack Developer',
                    FULLSTACK_DEVELOPER_DESC: 'Empower your digital projects with our Full Stack Developers. From front-end finesse to back-end brilliance, our experts craft seamless, scalable solutions that elevate user experiences and drive business growth.',
                    DATABASE_EXPERT: 'Database Expert',
                    DATABASE_EXPERT_DESC: 'Optimize data management and drive insights with our Database Experts. From designing robust architectures to ensuring data integrity, our specialists unlock the power of data to fuel informed decisions and business success.',
                    UX_UI_DESIGNER: 'UX/UI Designer',
                    UX_UI_DESIGNER_DESC: 'Transform user experiences and elevate design aesthetics with our UX/UI Designers. From intuitive interfaces to engaging visuals, our experts craft immersive digital experiences that captivate audiences and drive meaningful interactions.',
                    COST_SAVINGS_DESC: 'Reduce overhead costs associated with in-house staff.',
                    COST_SAVINGS_DESC_OUT: 'Enjoy more flexibility in your work schedules. By working as a contractor you can choose when and where you work, allowing for a better work-life balance. This flexibility also extends to the types of projects you take on, allowing you to explore different industries and work with diverse clients.',
                    ACCESS_SPECIALIZED_SKILLS: 'Access to Specialized Skills',
                    ACCESS_SPECIALIZED_SKILLS_DESC: 'Outsourcing allows companies to tap into a global talent pool and access specialized skills that may not be available in-house. Whether it\'s IT expertise, digital marketing proficiency, or language fluency, outsourcing enables businesses to fill skill gaps efficiently.',
                    FLEXIBILITY_SCALABILITY: 'Flexibility and Scalability',
                    FLEXIBILITY_SCALABILITY_DESC: 'Outsourcing provides flexibility in scaling resources up or down based on business needs. Whether it\'s a short-term project or ongoing support, companies can adjust their outsourcing arrangements to align with demand fluctuations.',
                    TIME_SAVINGS: 'Time Savings',
                    TIME_SAVINGS_DESC: 'Outsourcing tasks or processes can save valuable time for internal teams, allowing them to focus on strategic initiatives and core business objectives. This can lead to faster project delivery and increased efficiency.',
                    FIND_SKILLED_TALENT: 'Find skilled talent, flexibility, and cost-effectiveness. Let\'s elevate your projects together!',
                    HIRE_DEVELOPERS: 'Hire with us',
                    ROLES_YOU_CAN_GET: 'Roles You Can Get',
                    ENDLESS_BENEFITS: 'Endless Benefits',
                    ADD_ANOTHER_TIME: "Add another time",
                    CONTACT_WAY_VALIDATION: "You must select a contact way",
                    CONTACT_WAY: "Best way to contact me:",
                    EMAIL: "Email",
                    BOTH: "Either",

                    // OUTSOURCING VIEW 
                    MARKET_EXPANSION: 'Market Expansion',
                    STRATEGIES: 'Strategies',
                    MARKET_EXPANSION_DESC: 'It\'s about reaching more customers, exploring new markets, and growing your sales. Let\'s work together to expand your horizons and unlock exciting growth opportunities!',
                    BUSINESS_DEVELOPMENT: 'Business Development',
                    AND_GROWTH_PLANNING: 'and Growth Planning',
                    BUSINESS_DEVELOPMENT_DESC: 'It\'s about reaching more customers, exploring new markets, and growing your sales. Let\'s work together to expand your horizons and unlock exciting growth opportunities!',
                    DIGITAL_TRANSFORMATION: 'Digital Transformation',
                    ADVISORY: 'Advisory',
                    DIGITAL_TRANSFORMATION_DESC: 'We\'ll help you harness the power of technology to streamline processes, enhance customer experiences, and stay ahead of the curve in today\'s digital landscape. Let\'s transform together for success!',
                    TECHNOLOGY_ROADMAP: 'Technology Roadmap',
                    DEVELOPMENT: 'Development',
                    TECHNOLOGY_ROADMAP_DESC: 'We\'ll chart out a strategic roadmap with cutting-edge solutions, ensuring your technology aligns perfectly with your business goals. Get ready to navigate towards innovation and growth!',
                    STARTUP_CONSULTING: 'Start-Up Consulting',
                    STARTUP_CONSULTING_DESC: 'We\'ll guide you through every step of launching your new venture, from creating a solid business plan to navigating market entry strategies. Let\'s turn your vision into a thriving reality together!',
                    INCREASED_MARKET_SHARE: 'Increased market share and brand visibility',
                    STRATEGIC_PLANNING: 'Strategic planning for sustainable growth',
                    ENHANCED_DIGITAL_CAPABILITIES: 'Enhanced digital capabilities and efficiency',
                    EXPERT_GUIDANCE: 'Expert guidance for navigating industry challenges',
                    TAILORED_STRATEGIES: 'Tailored strategies for new businesses to establish a strong foundation',
                    EMPOWERING_IT_COMPANIES: 'Empowering IT companies to thrive with tailored strategies for growth and innovation. Explore our solutions and elevate your success!',
                    BOOK_AN_APPOINTMENT: 'Book An Appointment',
                    GREAT_BENEFITS: 'Great Benefits',
                    EXPLORE: 'Explore',

                    FORM_STEP_TITLE: 'One more step before you take your company to the next level',
                    FORM_INSTRUCTION: 'Please fill the form so we can contact you for further assistance on the service you want to acquire with us.',
                    FORM_PLACEHOLDER: 'Enter your name',
                    FORM_LABEL_NAME: 'Your Name',
                    FORM_LABEL_COUNTRY: 'Country Of Residence',
                    FORM_LABEL_DEPARTMENT: 'Department/State',
                    FORM_LABEL_PHONE: 'Phone Number',
                    FORM_LABEL_EMAIL: 'Your Email',
                    FORM_LABEL_SERVICE: 'Service You Want To Acquire',
                    FORM_ADD_SERVICE: '+ Add Another service',
                    FORM_LABEL_EXTRA_INFO: `Additional Information Regarding Your Company's Needs`,
                    FORM_BUTTON_LOADING: 'Submitting...',
                    FORM_BUTTON: 'Submit',

                    SHORT_TERM: 'Short Term: 1-6 Months',
                    MID_TERM: 'Mid term: 6-12 months',
                    LONG_TERM: 'Long term: More than 1 year',

                    "FORM_LABEL_CITY": "City Where You Are Located",
                    "FORM_LABEL_ADDRESS": "Address",
                    "FORM_LABEL_REMOTE_WORK": "Are Available To Work Remotely?",
                    "FORM_LABEL_POSITION": "Current Position (If Applicable)",
                    "FORM_LABEL_PREVIOUS_EXPERIENCE": "Previous Experience (on years)",
                    "FORM_LABEL_SUBMIT_RESUME": "Submit Your Resume",
                    "FORM_LABEL_JOB_POSITION": "Job Position",
                    "FORM_LABEL_SKILLS": "Skills",
                    "FORM_LABEL_CV": "Upload file",
                    "FORM_POSITION_LOOKING_FOR": "Position you are looking for",
                    "FORM_TIME_NEED_EXPERT": "Time You Need Our Expert",

                    "FORM_TITLE_READY_STREAMLINE": "Ready to streamline your practice?",
                    "FORM_TEXT_HIRE_VMA": "Fill out the form below to hire a skilled virtual medical assistant today.",
                    "FORM_COUNTRY_RESIDENCE": "Country Of Residence",
                    "FORM_MEDICAL_SPECIALIZATION": "Your Medical Specialization",
                    "FORM_TASKS_VMA": "Tasks You Require For The Virtual Medical Assistant",
                    "FORM_MEET_CANDIDATE": "When Do You Want To Meet With Our Candidate?",

                    "ADD_ANOTHER_POSITION": "Add Another Position",


                    "FORM_TITLE_JOIN_TEAM": "Join our dynamic team dedicated to making a difference in remote patient care",
                    "FORM_PHONE_NUMBER": "Phone Number",
                    "FORM_YOUR_EMAIL": "Your Email",
                    "FORM_DEPARTMENT_LOCATION": "Department Where You Are Located",
                    "FORM_CITY_LOCATION": "City Where You Are Located",
                    "FORM_ADDRESS": "Address",
                    "FORM_REMOTE_WORK": "Are Available To Work Remotely?",
                    "FORM_CURRENT_POSITION": "Current Position (If Applicable)",
                    "FORM_PREVIOUS_EXPERIENCE": "Previous Experience (on years)",
                    "FORM_SUBMIT_RESUME": "Submit Your Resume",
                    "FORM_UPLOAD_FILE": "Upload File",
                    FORM_ADDITIONAL_INFORMATION: "Additional Information",
                    "FORM_BUTTON_LOADING": "Sending...",
                    "FORM_BUTTON": "Send",

                    FORM_SELECT_OPTION: "Selec an option",

                    GROWING_ECONOMY: 'Growing Economy',
                    GROWING_ECONOMY_DESC: 'Explore a dynamic market with a rapidly growing economy and abundant investment opportunities.',
                    STRATEGIC_LOCATION: 'Strategic Location',
                    STRATEGIC_LOCATION_DESC: 'Benefit from Colombia\'s strategic location in Latin America, offering access to regional markets.',
                    BUSINESS_FRIENDLY_ENVIRONMENT: 'Business-Friendly Environment',
                    BUSINESS_FRIENDLY_ENVIRONMENT_DESC: 'Discover a welcoming business environment with supportive government policies and incentives for investors.',
                    TECHNOLOGY_INNOVATION: 'Technology and Innovation',
                    TECHNOLOGY_INNOVATION_DESC: 'Tap into Colombia\'s thriving tech scene and invest in innovative startups and tech companies.',
                    RENEWABLE_ENERGY: 'Renewable Energy',
                    RENEWABLE_ENERGY_DESC: 'Explore opportunities in renewable energy projects, such as solar, wind, and hydroelectric.',
                    TOURISM_HOSPITALITY: 'Tourism and Hospitality',
                    TOURISM_HOSPITALITY_DESC: 'Consider investments in the booming tourism and hospitality sector, including hotels, resorts, and eco-tourism ventures.',
                    AGRIBUSINESS: 'Agribusiness',
                    AGRIBUSINESS_DESC: 'Explore agricultural opportunities ranging from coffee and fruits to sustainable farming practices.',
                    REAL_ESTATE: 'Real Estate',
                    REAL_ESTATE_DESC: 'Discover potential in the real estate market, including commercial properties, residential developments, and mixed-use projects.',
                    RESEARCH: 'Research',
                    RESEARCH_DESC: 'Conduct thorough research on investment opportunities, market trends, and regulatory frameworks.',
                    LOCAL_PARTNERSHIPS: 'Local Partnerships',
                    LOCAL_PARTNERSHIPS_DESC: 'Consider partnering with local businesses or experts to navigate the market effectively.',
                    DIVERSIFICATION: 'Diversification',
                    DIVERSIFICATION_DESC: 'Diversify your investment portfolio across sectors to minimize risks and maximize returns.',
                    UNLOCK_GROWTH: 'Unlock Growth from Anywhere!',
                    WHY_INVEST_COLOMBIA: 'Why invest in Colombia?',
                    KEY_INVESTMENT_SECTORS: 'Key Investment Sectors',
                    HOW_WE_CAN_HELP: 'How We Can Help',
                    HELP_TEXT: 'At 5IG, we provide personalized guidance and support for international investors looking to explore opportunities in Colombia. Our team of experts can assist you in identifying strategic investment options and navigating the local market landscape.',
                    GET_STARTED: 'Let\'s get started',
                    GET_STARTED_TEXT: 'Ready to unlock investment potential in Colombia?',
                    GET_STARTED_CONTACT: 'Contact us today to discuss your investment goals and explore tailored opportunities.',
                    APPOINTMENT_BUTTON: 'Book An Appointment',

                    TECH_SOLUTIONS: 'Tech Services',
                    CONSULTING_SOLUTIONS: 'Consulting Solutions',
                    VIRTUAL_MEDICAL_ASSISTANT: 'Virtual Medical Assistant',
                    RECRUITMENT_SERVICES: 'Recruitment Services',
                    INVESTMENT_SOLUTIONS: 'Investment Solutions',
                    STEP_BY_STEP: 'Step-by-Step Process',
                    OUR_PARTNERS: 'Our Certification Partners',
                    NEEDS_ASSESSMENT: 'Needs Assessment',
                    NEEDS_ASSESSMENT_DESC: 'We begin by understanding your specific hiring needs and company culture.',
                    CANDIDATE_SEARCH: 'Candidate Search',
                    CANDIDATE_SEARCH_DESC: 'Our team identifies and approaches the best candidates in the market.',
                    SCREENING_AND_INTERVIEWING: 'Screening and Interviewing',
                    SCREENING_AND_INTERVIEWING_DESC: 'We conduct thorough screenings and initial interviews to shortlist top candidates.',
                    CANDIDATE_PRESENTATION: 'Candidate Presentation',
                    CANDIDATE_PRESENTATION_DESC: 'You review detailed profiles of shortlisted candidates.',
                    FINAL_SELECTION_AND_ONBOARDING: 'Final Selection and Onboarding',
                    FINAL_SELECTION_AND_ONBOARDING_DESC: 'We assist with final interviews and onboarding to ensure a smooth transition.',


                    FULL_STACK_DEVELOPER: 'Full Stack Developer',

                    FORM_TITLE_COLLABORATE: "Let's collaborate to take your business to new heights!",
                    FORM_FIRST_NAME: 'First Name',
                    FORM_FULL_NAME: 'Full name',
                    FORM_LAST_NAME: 'Last Name',
                    FORM_PHONE_NUMBER: 'Phone Number',
                    FORM_EMAIL: 'Your Email',
                    FORM_COMPANY_NAME: 'Company’s Name',
                    EXPERT_YOU_ARE_LOOKING: 'Expert You Are Looking For',
                    FORM_SERVICE_LOOKING_FOR: 'Service You Are Looking For',
                    ADD_ANOTHER_POSITION: 'Add Another Position',

                    TIME_MANAGEMENT: 'Time Management',
                    TIME_MANAGEMENT_DESC: 'Free up more time to focus on patient care.',
                    COST_SAVINGS: 'Cost Savings',
                    IMPROVED_ORGANIZATION: 'Improved Organization',
                    IMPROVED_ORGANIZATION_DESC: 'Keep your practice organized and running smoothly.',
                    APPOINTMENT_SCHEDULING: 'Appointment Scheduling',
                    APPOINTMENT_SCHEDULING_DESC: 'Effortlessly manage your patient appointments and ensure timely visits.',
                    ADMINISTRATIVE_SUPPORT: 'Administrative Support',
                    ADMINISTRATIVE_SUPPORT_DESC: 'Streamline your administrative tasks simply and efficiently.',
                    COMMUNICATION_MANAGEMENT: 'Communication Management',
                    COMMUNICATION_MANAGEMENT_DESC: 'Coordinate seamlessly with your assistant to handle non-clinical patient interactions.',
                    BROWSE_SERVICE_CATALOG: 'Browse Our Service Catalog',
                    BROWSE_SERVICE_CATALOG_DESC: 'Discover how we can help you enhance efficiency and patient care.',
                    EXPLORE_SERVICES: 'Explore Services',
                    IMPULSE_CAREER: 'Impulse your medical career',
                    IMPULSE_CAREER_DESC: 'Join our team as a Virtual Medical Assistant! Access the application screen now and embark on a rewarding journey of remote healthcare support.',
                    VMA_TITLE: 'VMA (Virtual Medical Assistant)',
                    VMA_TEXT: 'Effortlessly manage your patient appointments and ensure timely visits.',
                    ABOUT_SERVICE: 'About the Service',
                    DISCOVER_BENEFITS: 'Discover the benefits of hiring a virtual medical assistant',
                    STREAMLINE_TASKS: 'Streamline your administrative tasks simply and efficiently.',
                    KEY_SERVICES_OFFERED: 'Key Services Offered',
                    HIRE_VMA: 'Hire a VMA',

                    ENHANCE_PRACTICE_TITLE: 'Enhance Your Practice with Virtual',
                    ENHANCE_PRACTICE_TITLE2: 'Medical Assistants',
                    ENHANCE_PRACTICE_DESC: 'Effectively manage your appointments and administrative tasks.',

                    CONTACT_CENTER_PATIENTS: 'Contact Center for Patients',
                    CONTACT_CENTER_PATIENTS_DESC: 'Provide professional and friendly patient communication and support with our Contact Center for Patients, ensuring seamless interaction and care coordination.',
                    SCHEDULING_CENTER: 'Scheduling Center',
                    SCHEDULING_CENTER_DESC: 'Efficiently manage and organize patient appointments with our Scheduling Center, ensuring smooth and timely coordination.',
                    DIAGNOSTIC_REVIEWS_COMMUNICATIONS: 'Diagnostic Reviews & Communications of Results',
                    DIAGNOSTIC_REVIEWS_COMMUNICATIONS_DESC: 'Ensure timely review of diagnostics and effective communication of results to patients with our Diagnostic Reviews & Communications service.',
                    TREATMENT_MANAGEMENT: 'Treatment Management',
                    TREATMENT_MANAGEMENT_DESC: 'Streamline patient treatment plans and coordination with our Treatment Management service, ensuring comprehensive and organized care.',
                    RISKS_MANAGEMENT: 'Risks Management',
                    RISKS_MANAGEMENT_DESC: 'Proactively manage and mitigate patient risks with our Risks Management service, ensuring safety and compliance in healthcare practices.',
                    EMR_MAINTENANCE: 'EMR Maintenance',
                    EMR_MAINTENANCE_DESC: 'Efficiently maintain and update Electronic Medical Records with our EMR Maintenance service, ensuring accurate and accessible patient information.',
                    AUDITING: 'Auditing',
                    AUDITING_DESC: 'Ensure compliance and accuracy in healthcare practices with our Auditing service, providing thorough and comprehensive assessments.',
                    BILLING_COORDINATION: 'Billing Coordination',
                    BILLING_COORDINATION_DESC: 'Streamline patient billing processes and coordination with our Billing Coordination service, ensuring accuracy and efficiency.',
                    FINANCIAL_COVERAGE_PRECHECKS: 'Financial & Coverage PreChecks',
                    FINANCIAL_COVERAGE_PRECHECKS_DESC: 'Ensure accurate financial and coverage checks with our Financial & Coverage PreChecks service, optimizing billing and reimbursement processes.',
                    VIRTUAL_MEDICAL_SERVICES_TITLE: 'Our Comprehensive Virtual Medical Services',
                    VIRTUAL_MEDICAL_SERVICES_DESC: 'Discover our range of virtual medical services, designed to optimize your practice and elevate the quality of patient care. Explore our options for more efficient and effective practice management.',
                    READY_ENHANCE_PRACTICE: 'Ready to Enhance Your Practice?',
                    CONTACT_US_FOR_MORE_INFO_1: 'Contact us to learn more about our services',
                    CONTACT_US_FOR_MORE_INFO_2: 'and how they can benefit your practice.',

                    CAREER_GROWTH_DESC: 'Experience career growth, competitive salaries, remote work flexibility, and the opportunity to collaborate with people worldwide. Elevate your career while making a global impact.',
                    VIRTUAL_HEALTHCARE_OPPORTUNITIES_DESC: 'Explore exciting opportunities in virtual healthcare! Join us as a Virtual Medical Assistant specializing in Pediatrics, Psychiatry, Dermatology, and more. Make a difference from anywhere while advancing your career in remote medicine.',
                    REMOTE_HEALTHCARE_OPPORTUNITY_DESC: 'Embrace the opportunity to work with patients across the US! Join our team as a Virtual Medical Assistant and connect with individuals nationwide while advancing your career in remote healthcare.',
                    SCHEDULE_MANAGE_APPOINTMENTS: 'Schedule and manage patient appointments',
                    HANDLE_ADMIN_TASKS: 'Handle administrative tasks and medical records',
                    COORDINATE_WITH_PROVIDERS: 'Coordinate with healthcare providers and patients',
                    UNLOCK_REMOTE_HEALTHCARE: 'Unlock the potential of remote healthcare with us',
                    APPLY_NOW_VMA: 'Apply now for our Virtual Medical Assistant position and enjoy competitive salaries and the flexibility of remote work.',
                    YOUR_DAILY_TASKS: 'Your Daily Tasks',
                    BUSINESS_CREATION_CONSULTANCY: 'Business Creation Consultancy',
                    LEGAL_CONSULTANCY: 'Legal Consultancy',
                    FINANCIAL: 'Financial Consultancy',
                    LABOR_LEGISLATION: 'Labor Legislation Consultancy',
                    REQUEST_SUCCESS: 'Your request was successfully submitted',
                    EXPERT_REACH: 'One of our experts will reach you soon!',
                    CONTINUE_EXPLORING: 'Continue Exploring',
                    ABOUT_US: 'About Us',
                    OUR_VALUES: 'Our Values',
                    OUR_GOAL: 'Our Goal',
                    OUR_VISION: 'Our Vision',
                    GET_TO_KNOW: 'Get to Know the Company behind the innovation',
                    INNOVATIVE_SOLUTIONS: 'Innovative solutions',
                    EXPERIENCED_TEAM: 'Experienced Team',
                    CUSTOMER_CENTRIC: 'Customer-Centric Approach',
                    PROVEN_TRACK_RECORD: 'Proven Track Record',
                    HEADER_EVENTS: "Events",
                    UP_COMING_EVENTS_5IG: 'Upcoming Events at 5IG',
                    LIVE_EVENTS: 'Live Events',
                    UP_COMING_EVENTS: 'Upcoming Events',
                    RECORDED_EVENTS: 'Recorded Events',
                    STAY_UPDATED: 'Stay updated with our seminars, webinars, conferences and be at the forefront of the latest insights and innovations!',
                    BUTTON_JOIN_NOW: 'Join Now',
                    BUTTON_REGISTER_NOW: 'Register Now',
                    BUTTON_WATCH_NOW: 'Watch Now',
                    BUTTON_WATCH_MORE: 'Watch More',
                    BUTTON_WATCH_LESS: 'Watch Less',


                    INNOVATIVE_DESCRIPTION: 'We stay ahead of the curve with cutting-edge technology and strategies.',
                    EXPERIENCED_DESCRIPTION: 'Our team of seasoned professionals brings a wealth of knowledge and expertise.',
                    CUSTOMER_DESCRIPTION: 'We prioritize our clients needs and work tirelessly to exceed their expectations.',
                    PROVEN_TRACK_RECORD_DESCRIPTION: 'Our success stories speak for themselves, showcasing our ability to deliver exceptional results',

                    GET_TO_KNOW: 'Get to Know the Company behind the innovation',
                    INNOVATIVE_SOLUTIONS: 'Innovative solutions',
                    EXPERIENCED_TEAM: 'Experienced Team',
                    CUSTOMER_CENTRIC: 'Customer-Centric Approach',
                    PROVEN_TRACK_RECORD: 'Proven Track Record',


                    INNOVATIVE_DESCRIPTION: 'We stay at the forefront with advanced technology and strategies.',
                    EXPERIENCED_DESCRIPTION: 'Our team of seasoned professionals brings a wealth of knowledge and experience.',
                    CUSTOMER_DESCRIPTION: 'We prioritize our clients needs and work tirelessly to exceed their expectations.',
                    PROVEN_TRACK_RECORD_DESCRIPTION: 'Our success stories speak for themselves and demonstrate our ability to deliver exceptional results.',

                    VALUES_DESCRIPTION: 'Through trust, honest and ethical practices, we create quick and valuable solutions that offer a competitive advantage by leveraging technology and human innovation.',
                    GOAL_DESCRIPTION: 'To become the leading trusted partners in enabling artificial intelligence, automation, and innovation through a wide range of cost-effective solutions.',
                    VISION_DESCRIPTION: 'To transform society and corporations with technology and innovation at an extraordinary speed.',
                    INNOVATIVE_LEADERS: 'Innovative leaders in artificial intelligence and automation, we specialize in intelligent automation, RPA, and business intelligence.',
                    MISSION_STATEMENT: 'Our mission is to simplify organizational processes for our clients by leveraging cutting-edge technologies such as artificial intelligence, machine learning, and natural language processing.',
                    HAND_IN_HAND: 'Always hand in hand with our clients and technology',
                    CONTACT_US: 'We are happy to be in touch with our clients.',
                    FORM_TEXT: 'Feel free to leave us a message with any doubt or suggestion you have regarding our services.',
                    YOUR_NAME: 'Your Name',
                    PHONE_NUMBER: 'Phone Number',
                    YOUR_EMAIL: 'Your Email',
                    YOUR_MESSAGE: 'Your Message',
                    FORM_BUTTON: 'Submit',
                    FORM_BUTTON_LOADING: 'Submitting...',
                    REQUEST_SUCCESS: 'Your request was successfully submitted',
                    EXPERT_REACH: 'One of our experts will reach you soon!',
                    CONTINUE_EXPLORING: 'Continue Exploring',
                    NAME_REQUIRED: 'The name field must be completed.',
                    COMPANY_REQUIRED: 'The company field must be completed.',
                    COUNTRY_REQUIRED: 'The country of residence field must be completed.',
                    DEPARTMENT_STATE_REQUIRED: 'The department/state field must be completed.',
                    PHONE_CODE_REQUIRED: 'The phone code field must be completed.',
                    PHONE_NUMBER_REQUIRED: 'The phone number field must be completed.',
                    PHONE_NUMBER_ZERO: 'The phone number field cannot be less than or equal to 0.',
                    EMAIL_REQUIRED: 'The email field must be completed.',
                    SERVICE_REQUIRED: 'You must select at least one service.',
                    ADDITIONAL_INFO_REQUIRED: 'The additional information field must be completed.',
                    POLICIES_REQUIRED: 'You must accept the terms and conditions.',
                    LAST_NAME_REQUIRED: 'The last name field must be completed.',
                    COMPANY_NAME_REQUIRED: 'The company name field must be completed.',
                    CURRENT_CANDIDATE_DEPARTMENT_REQUIRED: 'The current candidate department field must be completed.',
                    CURRENT_CANDIDATE_CITY_REQUIRED: 'The current candidate city field must be completed.',
                    CURRENT_CANDIDATE_ADDRESS_REQUIRED: 'The current candidate address field must be completed.',
                    APPLICATION_POSITION_REQUIRED: 'The application position field must be completed.',
                    VACANCY_GUID_REQUIRED: 'The job position field must be completed.',
                    SKILLS_REQUIRED: 'The skills field must be completed.',
                    CANDIDATE_RESUME_REQUIRED: 'The candidate resume field must be completed.',
                    PREVIOUS_EXPERIENCE_REQUIRED: 'The previous experience field must be completed.',
                    POSITIONS_REQUIRED: 'The positions field must be completed.',
                    TIME_NEED_EXPERT_REQUIRED: 'The time need expert field must be completed.',
                    DEPARTMENT_STATE_REQUIRED: 'The department/state field must be completed.',
                    CURRENT_CANDIDATE_COUNTRY_REQUIRED: 'The current candidate country field must be completed.',
                    APPLICATION_POSITION_REQUIRED: 'The medical specialization field must be completed.',
                    ADDITIONAL_INFO_TASKS_REQUIRED: 'The required tasks field must be completed.',
                    POLICIES_CHECKBOX_REQUIRED: 'You must check the checkbox.',
                    CURRENT_CANDIDATE_CITY_REQUIRED: 'The current candidate city field must be completed.',
                    EXPERT_REQUIRED: 'The expert field must be completed.',
                    CURRENT_CANDIDATE_ADDRESS_REQUIRED: 'The current candidate address field must be completed.',
                    DATA_FOOTER: 'Data Processing',
                    ABOUT_US_TEXT: '5IG is a leading provider of innovative solutions, specializing in tech solutions, consulting, recruitment, virtual medical assistants, and investment opportunities. Our mission is to empower businesses with the tools and talent they need to succeed.',
                    ABOUT_US_OVERLAY_TEXT: 'Get to Know the Company behind the innovation',
                    MEET_OUR_TEAM: 'Meet the team behind our success',
                    WHY_CHOOSE_US: 'Why are we the ideal choice?',

                    COPYRIGHT: 'Copyright © 2024. All rights reserved.',
                    MORE_SERVICE_ERP_TITLE: "Optimize Your Business with ERP Solutions",
                    MORE_SERVICE_ERP_SUBTITLE1: "Streamline operations, enhance efficiency, and drive",
                    MORE_SERVICE_ERP_SUBTITLE2: "growth with 5IG’s comprehensive ERP systems.",
                    MORE_SERVICE_ERP_CTA: "Get Started Today",
                    MORE_SERVICE_ERP_DESCRIPTION: "5IG’s ERP solutions integrate all facets of your business, from finance and supply chain to human resources and customer relations, into a single, seamless system. Our goal is to help you achieve operational excellence and drive sustainable growth.",
                    MORE_SERVICE_ERP_KEY_FEATURES_TITLE: "Key Features",
                    MORE_SERVICE_ERP_FINANCE_MANAGEMENT: "Finance Management",
                    MORE_SERVICE_ERP_FINANCE_MANAGEMENT_DESC: "Automate financial processes and gain real-time insights into your financial health.",
                    MORE_SERVICE_ERP_SUPPLY_CHAIN_OPTIMIZATION: "Supply Chain Optimization",
                    MORE_SERVICE_ERP_SUPPLY_CHAIN_OPTIMIZATION_DESC: "Enhance supply chain visibility and efficiency from procurement to delivery.",
                    MORE_SERVICE_ERP_HUMAN_RESOURCES: "Human Resources",
                    MORE_SERVICE_ERP_HUMAN_RESOURCES_DESC: "Manage employee information, payroll, and benefits with ease.",
                    MORE_SERVICE_ERP_PROCESS_TITLE: "Step-by-Step Process",
                    MORE_SERVICE_ERP_ASSESS_NEEDS: "Assess Your Needs",
                    MORE_SERVICE_ERP_ASSESS_NEEDS_DESC: "We’ll work with you to understand your specific business requirements.",
                    MORE_SERVICE_ERP_TAILOR_SOLUTION: "Tailor the Solution",
                    MORE_SERVICE_ERP_TAILOR_SOLUTION_DESC: "We customize the ERP system to fit your unique needs.",
                    MORE_SERVICE_ERP_IMPLEMENT_TRAIN: "Implement and Train",
                    MORE_SERVICE_ERP_IMPLEMENT_TRAIN_DESC: "Our team ensures smooth implementation and provides comprehensive training.",
                    MORE_SERVICE_ERP_ONGOING_SUPPORT: "Ongoing Support",
                    MORE_SERVICE_ERP_ONGOING_SUPPORT_DESC: "Continuous support and updates to keep your system running efficiently.",
                    MORE_SERVICE_ERP_BENEFITS_TITLE: "Key Benefits",
                    MORE_SERVICE_ERP_CENTRALIZED_DATA_MANAGEMENT: "Centralized Data Management",
                    MORE_SERVICE_ERP_IMPROVED_EFFICIENCY_PRODUCTIVITY: "Improved Efficiency and Productivity",
                    MORE_SERVICE_ERP_REAL_TIME_REPORTING_ANALYTICS: "Real-time Reporting and Analytics",
                    MORE_SERVICE_ERP_SCALABLE_SOLUTIONS_GROWING_BUSINESSES: "Scalable Solutions for Growing Businesses",
                    "MORE_SERVICE_CRM_TITLE": "Enhance Customer Relationships with our CRM Solutions",
                    "MORE_SERVICE_CRM_SUBTITLE": "Manage interactions, improve customer satisfaction, and drive growth.",
                    "MORE_SERVICE_CRM_CTA": "Get Started Today",
                    "MORE_SERVICE_CRM_DESCRIPTION": "5IG’s CRM solutions empower your business to build stronger customer relationships, streamline interactions, and boost satisfaction. Our comprehensive tools help you manage leads, track interactions, and analyze customer data to drive growth.",
                    "MORE_SERVICE_CRM_KEY_FEATURES_TITLE": "Key Features",
                    "MORE_SERVICE_CRM_LEAD_MANAGEMENT": "Lead Management",
                    "MORE_SERVICE_CRM_LEAD_MANAGEMENT_DESC": "Track and manage leads from initial contact to conversion with ease.",
                    "MORE_SERVICE_CRM_CUSTOMER_INTERACTION_TRACKING": "Customer Interaction Tracking",
                    "MORE_SERVICE_CRM_CUSTOMER_INTERACTION_TRACKING_DESC": "Maintain a detailed history of all customer interactions to provide personalized service.",
                    "MORE_SERVICE_CRM_SALES_AUTOMATION": "Sales Automation",
                    "MORE_SERVICE_CRM_SALES_AUTOMATION_DESC": "Automate sales tasks and workflows to increase productivity and close deals faster.",
                    "MORE_SERVICE_CRM_ANALYTICS_REPORTING": "Analytics and Reporting",
                    "MORE_SERVICE_CRM_ANALYTICS_REPORTING_DESC": "Track and manage leads from initial contact to conversion with ease.",
                    "MORE_SERVICE_CRM_CUSTOMER_SUPPORT": "Customer Support",
                    "MORE_SERVICE_CRM_CUSTOMER_SUPPORT_DESC": "Track and manage leads from initial contact to conversion with ease.",
                    "MORE_SERVICE_CRM_PROCESS_TITLE": "Step-by-Step Process",
                    "MORE_SERVICE_CRM_UNDERSTAND_NEEDS": "Understand Your Needs",
                    "MORE_SERVICE_CRM_UNDERSTAND_NEEDS_DESC": "We assess your specific CRM requirements.",
                    "MORE_SERVICE_CRM_CUSTOMIZE_SOLUTION": "Customize the Solution",
                    "MORE_SERVICE_CRM_CUSTOMIZE_SOLUTION_DESC": "Tailor our CRM tools to fit your business processes.",
                    "MORE_SERVICE_CRM_IMPLEMENT_TRAIN": "Implement and Train",
                    "MORE_SERVICE_CRM_IMPLEMENT_TRAIN_DESC": "Ensure a smooth implementation and provide comprehensive training.",
                    "MORE_SERVICE_CRM_ONGOING_SUPPORT": "Ongoing Support",
                    "MORE_SERVICE_CRM_ONGOING_SUPPORT_DESC": "Continuous support and updates to optimize your CRM system.",
                    "MORE_SERVICE_CRM_BENEFITS_TITLE": "Key Benefits",
                    "MORE_SERVICE_CRM_CENTRALIZED_CUSTOMER_DATA": "Centralized Customer Data",
                    "MORE_SERVICE_CRM_ENHANCED_CUSTOMER_ENGAGEMENT": "Enhanced Customer Engagement",
                    "MORE_SERVICE_CRM_IMPROVED_SALES_EFFICIENCY": "Improved Sales Efficiency",
                    "MORE_SERVICE_CRM_DATA_DRIVEN_INSIGHTS": "Data-Driven Insights",
                    "MORE_SERVICE_ECOMMERCE_TITLE": "Revolutionize Your B2B Sales with 5IG E-commerce Solutions",
                    "MORE_SERVICE_ECOMMERCE_SUBTITLE": "Streamline transactions, manage inventory, and drive growth with our comprehensive B2B platform.",
                    "MORE_SERVICE_ECOMMERCE_CTA": "Get Started Today",
                    "MORE_SERVICE_ECOMMERCE_DESCRIPTION": "5IG’s E-commerce B2B solutions empower your business to streamline bulk ordering, manage inventory efficiently, and facilitate seamless transactions. Our platform is designed to enhance your business operations and drive growth.",
                    "MORE_SERVICE_ECOMMERCE_KEY_FEATURES_TITLE": "Key Features",
                    "MORE_SERVICE_ECOMMERCE_CUSTOM_CATALOGS": "Custom Catalogs",
                    "MORE_SERVICE_ECOMMERCE_CUSTOM_CATALOGS_DESC": "Create personalized product catalogs for different clients to meet their specific needs.",
                    "MORE_SERVICE_ECOMMERCE_AUTOMATED_ORDERING": "Automated Ordering",
                    "MORE_SERVICE_ECOMMERCE_AUTOMATED_ORDERING_DESC": "Streamline the ordering process with automated order management and tracking.",
                    "MORE_SERVICE_ECOMMERCE_INVENTORY_MANAGEMENT": "Inventory Management",
                    "MORE_SERVICE_ECOMMERCE_INVENTORY_MANAGEMENT_DESC": "Keep track of stock levels, manage reorders, and reduce excess inventory.",
                    "MORE_SERVICE_ECOMMERCE_FLEXIBLE_PAYMENT_OPTIONS": "Flexible Payment Options",
                    "MORE_SERVICE_ECOMMERCE_FLEXIBLE_PAYMENT_OPTIONS_DESC": "Offer multiple payment methods and terms to accommodate different client preferences.",
                    "MORE_SERVICE_ECOMMERCE_ANALYTICS_REPORTING": "Analytics and Reporting",
                    "MORE_SERVICE_ECOMMERCE_ANALYTICS_REPORTING_DESC": "Gain insights into sales performance and customer behavior with advanced analytics.",
                    "MORE_SERVICE_ECOMMERCE_PROCESS_TITLE": "Step-by-Step Process",
                    "MORE_SERVICE_ECOMMERCE_CONSULTATION": "Consultation",
                    "MORE_SERVICE_ECOMMERCE_CONSULTATION_DESC": "Schedule a consultation with our experts to discuss your business needs and objectives.",
                    "MORE_SERVICE_ECOMMERCE_SOLUTION_DESIGN": "Solution Design",
                    "MORE_SERVICE_ECOMMERCE_SOLUTION_DESIGN_DESC": "Tailor our e-commerce tools to fit your business processes.",
                    "MORE_SERVICE_ECOMMERCE_SEAMLESS_INTEGRATION": "Seamless Integration",
                    "MORE_SERVICE_ECOMMERCE_SEAMLESS_INTEGRATION_DESC": "Ensure a smooth implementation and provide comprehensive training.",
                    "MORE_SERVICE_ECOMMERCE_STAFF_TRAINING": "Staff Training",
                    "MORE_SERVICE_ECOMMERCE_STAFF_TRAINING_DESC": "Continuous support and updates to optimize your e-commerce system.",
                    "MORE_SERVICE_ECOMMERCE_LAUNCH_SUPPORT": "Launch and Support",
                    "MORE_SERVICE_ECOMMERCE_LAUNCH_SUPPORT_DESC": "Continuous support and updates to optimize your e-commerce system.",
                    "MORE_SERVICE_ECOMMERCE_BENEFITS_TITLE": "Key Benefits",
                    "MORE_SERVICE_ECOMMERCE_SIMPLIFIED_BULK_ORDERING": "Simplified Bulk Ordering",
                    "MORE_SERVICE_ECOMMERCE_EFFICIENT_INVENTORY_MANAGEMENT": "Efficient Inventory Management",
                    "MORE_SERVICE_ECOMMERCE_SEAMLESS_TRANSACTIONS": "Seamless Transactions",
                    "MORE_SERVICE_ECOMMERCE_ENHANCED_CUSTOMER_EXPERIENCE": "Enhanced Customer Experience",
                    BLOG_TITLE: "Stay Updated with the Latest in Tech, AI, and Automation",
                    BLOG_BUTTON: "Read More",
                    BLOG_FEATURED_TITLE: "Featured Articles",
                    BLOG_LATEST_TITLE: "Latest News",
                    SHARE: "Share",
                    SAVE_POSITIONS_REQUIRED: "All positions must be saved",
                    POSITIONS_REQUIRED: "All positions must be completed",
                    EDIT: "Edit",
                    SAVE: "Save",
                    SKILLS_NO_JOB: "Please select a job position",
                    "FINANCIALS_MAIN_TITLE": "POWER YOUR BUSINESS WITH OUR FINANCIAL SERVICES",
                    "FINANCIALS_MAIN_DESCRIPTION": "Expert financial consultations for small and large companies to help you thrive.",
                    "FINANCIALS_MAIN_BUTTON_TEXT": "Request a Consultation",
                    "FINANCIALS_MAIN_SUB_DESCRIPTION": "With years of experience and a team of experts in accounting, finance, taxes, and more, we offer comprehensive solutions that will transform the way you manage your company.",

                    "FINANCIALS_MAIN_WHY_CHOOSE_US_TITLE": "Why Choose Us?",
                    "FINANCIALS_MAIN_SERVICES_TITLE": "Our Services",
                    "FINANCIALS_MAIN_BENEFITS_TITLE": "Benefits",
                    "FINANCIALS_MAIN_TRUST_THE_BEST_TITLE": "Trust the Best",
                    "FINANCIALS_MAIN_TRUST_THE_BEST_DESCRIPTION": "We are more than just a service provider; we are your strategic partner on the path to success. Contact us today and discover how we can transform your business!",

                    "FINANCIALS_MAIN_SERVICE_1_TITLE": "General Accounting",
                    "FINANCIALS_MAIN_SERVICE_1_DESCRIPTION": "We handle all aspects of your daily accounting, from data entry to account reconciliation.",
                    "FINANCIALS_MAIN_SERVICE_2_TITLE": "Tax Preparation",
                    "FINANCIALS_MAIN_SERVICE_2_DESCRIPTION": "We take care of preparing and filing your tax returns to ensure compliance with all relevant laws and regulations.",
                    "FINANCIALS_MAIN_SERVICE_3_TITLE": "Payroll Management",
                    "FINANCIALS_MAIN_SERVICE_3_DESCRIPTION": "We offer a comprehensive solution for managing your payroll, including salary calculations, distributions and more.",
                    "FINANCIALS_MAIN_SERVICE_4_TITLE": "Budgeting",
                    "FINANCIALS_MAIN_SERVICE_4_DESCRIPTION": "We assist in creating, managing, and tracking your budgets so you can plan effectively and make financial decisions based on accurate data.",
                    "FINANCIALS_MAIN_SERVICE_5_TITLE": "Financial Reporting",
                    "FINANCIALS_MAIN_SERVICE_5_DESCRIPTION": "We generate detailed and accurate financial reports that provide a clear view of your company’s financial health.",
                    "FINANCIALS_MAIN_SERVICE_6_TITLE": "Legal Compliance",
                    "FINANCIALS_MAIN_SERVICE_6_DESCRIPTION": "We ensure that all your financial operations comply with current laws and regulations to avoid penalties and legal issues.",

                    "FINANCIALS_MAIN_BENEFIT_1_TITLE": "Time Savings",
                    "FINANCIALS_MAIN_BENEFIT_2_TITLE": "Cost Reduction",
                    "FINANCIALS_MAIN_BENEFIT_3_TITLE": "Legal and Regulatory Compliance",
                    "FINANCIALS_MAIN_BENEFIT_4_TITLE": "Global Adaptability",
                    "FINANCIALS_MAIN_BENEFIT_5_TITLE": "Sustainable Growth",

                    "FINANCIALS_MAIN_CHOOSE_US_1": "Bookkeeper",
                    "FINANCIALS_MAIN_CHOOSE_US_2": "Payroll Specialist",
                    "FINANCIALS_MAIN_CHOOSE_US_3": "AP/RP Specialist",
                    "FINANCIALS_MAIN_CHOOSE_US_4": "Tax Preparer",
                    "FINANCIALS_MAIN_CHOOSE_US_5": "Experience and Expertise",
                    "FINANCIALS_MAIN_CHOOSE_US_6": "Customized Solutions",
                    "FINANCIALS_MAIN_CHOOSE_US_7": "Guaranteed Compliance",
                    "FINANCIALS_MAIN_CHOOSE_US_8": "Process Optimization",
                    "FINANCIALS_MAIN_CHOOSE_US_9": "Comprehensive Support",
                    "FINANCIALS_MAIN_FINANCE_TODAY_TITLE": "Finance in today's world",
                    "FINANCIALS_MAIN_FINANCE_STAT_1": "About 80% of owners are baby boomers and are experiencing aggressive growth but have difficulty managing without sacrificing time.",
                    "FINANCIALS_MAIN_FINANCE_STAT_1_QUOTE": "\"76% of firms believe they perform effectively but not efficiently\"",
                    "FINANCIALS_MAIN_FINANCE_STAT_2": "Leveraging the right technology and automation tools are critical to the success of creating efficiency.",
                    "FINANCIALS_MAIN_FINANCE_STAT_2_QUOTE": "\"96% of accountants consider automation critical to their profession\"",
                    "FINANCIALS_FORM_SECURE_FUTURE_TITLE": "Secure Your Financial Future with 5IG!",
                    "CONTRACTING_MAIN_COMPREHENSIVE_CONSULTING": "Comprehensive Consulting for Your Business Growth",
                    "CONTRACTING_MAIN_EXPERIENCE_AND_EXPERTISE": "Experience and Expertise",
                    "CONTRACTING_MAIN_EXPERIENCE_AND_EXPERTISE_TEXT": "Our team consists of experts with decades of experience across various industries. We offer tailored solutions based on deep market knowledge, ensuring that all your administrative and technological operations are in the best hands.",
                    "CONTRACTING_MAIN_STRATEGIC_FOCUS": "Strategic Focus",
                    "CONTRACTING_MAIN_STRATEGIC_FOCUS_TEXT": "We become strategic partners for your business, helping you chart a clear path to success. With our solutions, you can spend more time innovating and expanding your business while we handle daily operations.",
                    "CONTRACTING_MAIN_CUSTOMIZED_SOLUTIONS": "Customized Solutions",
                    "CONTRACTING_MAIN_CUSTOMIZED_SOLUTIONS_TEXT": "We tailor our solutions to the specific needs of your business. We take care of everything from financial management to regulatory compliance, occupational health and safety, freeing you from routine tasks and giving you the freedom to focus on growth.",
                    "CONTRACTING_MAIN_PROCESS_OPTIMIZATION": "Process Optimization",
                    "CONTRACTING_MAIN_PROCESS_OPTIMIZATION_TEXT": "We implement advanced technologies and proven methodologies to automate and improve the efficiency of your operations, ensuring that all administrative and technological tasks are handled accurately and on time.",
                    "CONTRACTING_MAIN_ADMINISTRATIVE_AND_OPERATIONAL_MANAGEMENT": "Administrative and Operational Management",
                    "CONTRACTING_MAIN_BPO": "Business Process Outsourcing (BPO)",
                    "CONTRACTING_MAIN_RECORDS_MANAGEMENT": "Records Management",
                    "CONTRACTING_MAIN_AUTOMATION_OF_REPETITIVE_PROCESSES": "Automation of Repetitive Processes",
                    "CONTRACTING_MAIN_OCCUPATIONAL_HEALTH_AND_SAFETY": "Occupational Health and Safety",
                    "CONTRACTING_MAIN_DESIGN_AND_IMPLEMENTATION_OF_HEALTH_AND_SAFETY_PROGRAMS": "Design and Implementation of Health and Safety Programs",
                    "CONTRACTING_MAIN_RISK_ASSESSMENT_AND_TRAINING": "Risk Assessment and Training",
                    "CONTRACTING_MAIN_IT_SECURITY": "IT Security",
                    "CONTRACTING_MAIN_CYBERSECURITY_CONSULTING": "Cybersecurity Consulting",
                    "CONTRACTING_MAIN_MONITORING_AND_INCIDENT_RESPONSE": "Monitoring and Incident Response",
                    "CONTRACTING_MAIN_EQUIPMENT_MAINTENANCE_AND_MANAGEMENT": "Equipment Maintenance and Management",
                    "CONTRACTING_MAIN_IT_INFRASTRUCTURE_MANAGEMENT_AND_MAINTENANCE": "IT Infrastructure Management and Maintenance",
                    "CONTRACTING_MAIN_TECHNICAL_SUPPORT_AND_EQUIPMENT_UPGRADES": "Technical Support and Equipment Upgrades",
                    "CONTRACTING_MAIN_PROCESS_AUDITING": "Process Auditing",
                    "CONTRACTING_MAIN_COMPREHENSIVE_PROCESS_REVIEW": "Comprehensive Process Review",
                    "CONTRACTING_MAIN_IMPLEMENTATION_OF_IMPROVEMENTS": "Implementation of Improvements",
                    "CONTRACTING_MAIN_FINANCIAL_AND_ACCOUNTING_MANAGEMENT": "Financial and Accounting Management",
                    "CONTRACTING_MAIN_ACCOUNTING_AND_FINANCIAL_MANAGEMENT": "Accounting and Financial Management",
                    "CONTRACTING_MAIN_PAYROLL_AND_HUMAN_RESOURCES_MANAGEMENT": "Payroll and Human Resources Management",
                    "CONTRACTING_MAIN_REGULATORY_COMPLIANCE": "Regulatory Compliance",
                    "CONTRACTING_MAIN_LEGAL_AND_TAX_ADVISORY": "Legal and Tax Advisory",
                    "CONTRACTING_MAIN_AUDIT_AND_COMPLIANCE": "Audit and Compliance",
                    "CONTRACTING_MAIN_DIGITAL_TRANSFORMATION_AND_OPTIMIZATION": "Digital Transformation and Optimization",
                    "CONTRACTING_MAIN_ERP_IMPLEMENTATION": "ERP Implementation",
                    "CONTRACTING_MAIN_PROCESS_AUTOMATION_AND_CYBERSECURITY": "Process Automation and Cybersecurity",
                    "CONTRACTING_MAIN_STRATEGIC_CONSULTING_AND_BUSINESS_GROWTH": "Strategic Consulting and Business Growth",
                    "CONTRACTING_MAIN_STRATEGIC_PLANNING": "Strategic Planning",
                    "CONTRACTING_MAIN_MARKET_AND_COMPETITOR_ANALYSIS": "Market and Competitor Analysis",
                    "CONTRACTING_MAIN_STRATEGIC_CONSULTANTS": "Strategic Consultants",
                    "CONTRACTING_MAIN_RECORDS_MANAGEMENT_SPECIALISTS": "Records Management Specialists",
                    "CONTRACTING_MAIN_OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS": "Occupational Health and Safety Specialists",
                    "CONTRACTING_MAIN_CYBERSECURITY_SPECIALISTS": "Cybersecurity Specialists",
                    "CONTRACTING_MAIN_TECHNICAL_SUPPORT_AND_MAINTENANCE_TECHNICIANS": "Technical Support and Maintenance Technicians",
                    "CONTRACTING_MAIN_PROCESS_AUDITORS": "Process Auditors",
                    "CONTRACTING_MAIN_FINANCIAL_AND_ACCOUNTING_ANALYSTS": "Financial and Accounting Analysts",
                    "CONTRACTING_MAIN_ADMINISTRATIVE_MANAGERS": "Administrative Managers",
                    "CONTRACTING_MAIN_LEGAL_AND_TAX_ADVISORS": "Legal and Tax Advisors",
                    "CONTRACTING_MAIN_HUMAN_RESOURCES_CONSULTANTS": "Human Resources Consultants",
                    "CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS": "Time and Resource Savings",
                    "CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS_DESCRIPTION": "We take care of your administrative, operational, and technological tasks, allowing you to focus more time on growing your business.",
                    "CONTRACTING_MAIN_INCREASED_EFFICIENCY": "Increased Efficiency",
                    "CONTRACTING_MAIN_INCREASED_EFFICIENCY_DESCRIPTION": "With our technological solutions, records management services, process audits, and automation, we optimize your operations, reducing errors and improving productivity.",
                    "CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND": "Legal Compliance and Peace of Mind",
                    "CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND_DESCRIPTION": "We ensure your business complies with all legal regulations, minimizing risks and providing you with peace of mind.",
                    "CONTRACTING_MAIN_FOCUS_ON_GROWTH": "Focus on Growth",
                    "CONTRACTING_MAIN_FOCUS_ON_GROWTH_DESCRIPTION": "With operational, administrative, and technological tasks under control, you can focus on innovating, expanding, and sustainably growing your business.",
                    "CONTRACTING_MAIN_CONSULTING": "Consulting",
                    "CONTRACTING_MAIN_AT_5IG_SOLUTIONS": "At 5IG Solutions, we understand that managing administrative and operational tasks can consume valuable time and resources that could be better spent on growing your business.",
                    "CONTRACTING_MAIN_WHY_CHOOSE_US": "Why Choose Us?",
                    "CONTRACTING_MAIN_OUR_CONSULTING_SERVICES": "Our Consulting Services",
                    "CONTRACTING_MAIN_OUR_TEAM_OF_PROFESSIONALS": "Our Team of Professionals",
                    "CONTRACTING_MAIN_AT_5IG_SOLUTIONS_TEAM_DESCRIPTION": "At 5IG Solutions, we have a multidisciplinary team of experts dedicated to handling all the administrative, operational, and technological tasks of your company.",
                    "CONTRACTING_MAIN_TEAM_INCLUDES": "Our team includes:",
                    "CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS": "Time and Resource Savings",
                    "CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS_DESCRIPTION": "We take care of your administrative, operational, and technological tasks, allowing you to focus more time on growing your business.",
                    "CONTRACTING_MAIN_INCREASED_EFFICIENCY": "Increased Efficiency",
                    "CONTRACTING_MAIN_INCREASED_EFFICIENCY_DESCRIPTION": "With our technological solutions, records management services, process audits, and automation, we optimize your operations, reducing errors and improving productivity.",
                    "CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND": "Legal Compliance and Peace of Mind",
                    "CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND_DESCRIPTION": "We ensure your business complies with all legal regulations, minimizing risks and providing you with peace of mind.",
                    "CONTRACTING_MAIN_FOCUS_ON_GROWTH": "Focus on Growth",
                    "CONTRACTING_MAIN_FOCUS_ON_GROWTH_DESCRIPTION": "With operational, administrative, and technological tasks under control, you can focus on innovating, expanding, and sustainably growing your business.",
                    "WORK_WITH_US_CONTENT_APPRECIATION_INCENTIVES": "Appreciation Incentives",
                    "WORK_WITH_US_CONTENT_EDUCATIONAL_AID": "Educational Aid",
                    "WORK_WITH_US_CONTENT_LIFE_INSURANCE": "Life Insurance",
                    "WORK_WITH_US_CONTENT_CONNECTIVITY_ALLOWANCE": "Connectivity Allowance",
                    "WORK_WITH_US_CONTENT_OPHTHALMOLOGICAL_AID": "Ophthalmological Aid",
                    "WORK_WITH_US_CONTENT_PSYCHOLOGICAL_SUPPORT": "Psychological Support",
                    "WORK_WITH_US_CONTENT_JOIN_THE_COMPANY": "Join the innovation leading company",
                    "WORK_WITH_US_CONTENT_JOIN_DESCRIPTION": "Join 5IG Solutions and discover exciting opportunities to grow your career. Explore how we can work together to achieve success through innovative projects and collaboration.",
                    "WORK_WITH_US_CONTENT_OUR_COMPANY_BENEFITS": "Our Company Benefits",
                    "WORK_WITH_US_CONTENT_INTERESTED_JOIN_TEAM": "If you are interested in joining our team, please fill out the form below. We will get in touch with you shortly. Thank you!"
                }
                ,
            },
            es: {
                translation: {
                    // Textos en español...
                    //HOME PAGE
                    HOME_TITLE: 'COMIENZA A',
                    HOME_TITLE_2: 'AUTOMATIZAR TU MUNDO',
                    HOME_WELCOME_TITLE: '¡Únase a nosotros para acceder a una amplia gama de servicios de primera calidad!',
                    HOME_MAIN_BUTTON: 'Automatiza tu empresa.',
                    HOME_SERVICES_BUTTON: '¡Atrevete a conocer más!',
                    HOME_SERVICE_IA_TITLE_1: 'INTELIGENCIA ARTIFICIAL (IA) Y',
                    HOME_SERVICE_IA_TITLE_2: 'MACHINE LEARNING (ML)',
                    HOME_SERVICE_RPA_TITLE_1: 'AUTOMATIZACIÓN DE',
                    HOME_SERVICE_RPA_TITLE_2: 'PROCESOS ROBÓTICOS (RPA)',
                    HOME_SERVICE_CLOUD_TITLE: 'SOLUCIONES EN LA NUBE',
                    HOME_SERVICE_BI_TITLE_1: 'INTELIGENCIA',
                    HOME_SERVICE_BI_TITLE_2: 'DE NEGOCIOS (BI)',
                    HOME_SERVICE_WEB_DEV_TITLE_1: 'DESARROLLO WEB Y',
                    HOME_SERVICE_WEB_DEV_TITLE_2: 'DESARROLLO DE APLICACIONES',
                    HOME_SERVICE_IA_TEXT: "Libere el potencial de sus datos con nuestras soluciones de IA y ML. Desde algoritmos predictivos hasta sistemas inteligentes en evolución, ayudamos a mejorar su negocio anticipándonos a sus necesidades.",
                    HOME_SERVICE_RPA_TEXT: "Optimice el trabajo de su equipo con la automatización robótica de procesos. Nuestras soluciones RPA aumentan la eficiencia, reducen los errores y permiten que su equipo se concentre en lo que realmente importa.",
                    HOME_SERVICE_CLOUD_TEXT: "Mejore su negocio con nuestras soluciones en la nube. Disfrute de tecnología flexible, segura y accesible para almacenamiento, procesamiento y aplicaciones personalizadas que se adaptan a sus necesidades.",
                    HOME_SERVICE_BI_TEXT: "Tome decisiones estratégicas con nuestras herramientas de Business Intelligence. Convertimos datos sin procesar en información valiosa que lo ayudará a anticipar tendencias, detectar oportunidades y mejorar el rendimiento.",
                    HOME_SERVICE_WEB_DEV_TEXT: "Destaca en el mundo digital con nuestro desarrollo de aplicaciones y sitios web personalizados. Creamos experiencias y aplicaciones interactivas basadas en la marca que atraen a tus usuarios.",
                    HOME_ABOUT_US: 'Nosotros',
                    HOME_ABOUT_DESCRIPTION: 'Líderes innovadores en inteligencia artificial y automatización, nos especializamos en automatización inteligente, RPA e inteligencia empresarial.',
                    HOME_ABOUT_DESCRIPTION_2: 'Nuestra misión es simplificar los procesos organizativos para los clientes aprovechando tecnologías de vanguardia como la inteligencia artificial, el aprendizaje automático y el procesamiento del lenguaje natural.',
                    HOME_ABOUT_DESCRIPTION_3: 'Siempre de la mano con nuestros clientes y la tecnología.',
                    HOME_LINKEDIN_BUTTON: 'Encuéntranos en LinkedIn',
                    HOME_BUTTON: "Inicio",
                    HOME_BUTTON_INFO: "Más información",
                    "HOME_ECOMMERCE_B2B": "E-commerce B2B",
                    HOME_PARTNERS: "Empresas con las que trabajamos",
                    "HOME_ECOMMERCE_B2B_DESC": "Aumenta tus ventas B2B con nuestras soluciones de comercio electrónico para pedidos al por mayor, gestión de inventarios y transacciones simplificadas.",
                    "HOME_CRM": "CRM",
                    "HOME_CRM_DESC": "Mejora las relaciones con los clientes con nuestro sistema CRM. Rastrea interacciones, gestiona leads y aumenta la satisfacción utilizando análisis avanzados.",
                    "HOME_ERP": "ERP",
                    "HOME_ERP_DESC": "Optimiza las operaciones con nuestras soluciones ERP. Integra finanzas, cadena de suministro y otros procesos para mejorar la eficiencia y productividad.",
                    "HOME_CLIENT_SUCCESS_STORIES": "Historias de éxito",
                    "HOME_CLIENT_STORY_1_NAME": "John Smith",
                    "HOME_CLIENT_STORY_1_TITLE": "Director de Datos de AT&T",
                    "HOME_CLIENT_STORY_1_DESC": "\"Gracias a la asociación con 5IG Solutions, no solo logramos crear uno de los Centros de Excelencia en Automatización más grandes, sino que también implementamos más de 3,000 automatizaciones que transformaron completamente nuestros procesos operativos. Además, su experiencia en la integración de chatbots avanzados mejoró significativamente nuestro servicio al cliente. 5IG Solutions ha sido un socio estratégico en nuestra transformación digital y optimización de la eficiencia operativa, y continuó ayudándonos a poner en funcionamiento la IA generativa para más de 80,000 personas.\"",
                    "HOME_CLIENT_STORY_2_NAME": "Emily White",
                    "HOME_CLIENT_STORY_2_TITLE": "VP de Marketing en FinancePros",
                    "HOME_CLIENT_STORY_2_DESC": "\"Las soluciones CRM de 5IG han transformado la manera en que gestionamos las relaciones con los clientes. Los análisis avanzados y la gestión optimizada de leads han mejorado nuestra satisfacción del cliente y rendimiento de ventas.\"",
                    "HOME_CLIENT_STORY_3_TITLE": "Reclutamiento",
                    "HOME_CLIENT_STORY_3_DESC": "\"Gracias al profesionalismo de 5IG Solutions, logramos suministrar personal calificado a una de las principales empresas de telecomunicaciones del mundo. Con su apoyo, redujimos la tasa de deserción y despido en un 73%, mejorando significativamente nuestros indicadores de desempeño. Además, la fuerza laboral latinoamericana profesional y bilingüe que proporcionamos permitió aumentar nuestra tasa de retorno y mantener nuestros precios competitivos en el mercado global. 5IG Solutions ha sido un socio clave en nuestro éxito, ayudándonos a destacar en el exigente sector de las telecomunicaciones\"",
                    "HOME_CLIENT_ENVIGADO_NAME": "Secretaría de Desarrollo Económico de Envigado",
                    "HOME_CLIENT_ENVIGADO_TITLE": "Aliado Estratégico",
                    "HOME_CLIENT_ENVIGADO_DESC": "\"El alto nivel de profesionalismo demostrado por el equipo joven de 5IG Solutions ha sido excepcional. Su dedicación, enfoque profesional y habilidad para adaptarse rápidamente a diferentes desafíos han superado nuestras expectativas en múltiples ocasiones. La forma en que manejan cada proyecto con meticulosidad y compromiso no solo asegura resultados de alta calidad, sino que también contribuye al desarrollo de relaciones sólidas con los clientes. Su capacidad para anticipar necesidades y ofrecer soluciones efectivas ha sido fundamental para nuestro éxito. Apreciamos profundamente su esfuerzo constante y la forma en que siempre buscan mejorar y superar los estándares establecidos.\"",
                    "HOME_DRIVING_FUTURE_TITLE": "Conduciendo el futuro con soluciones integrales",
                    "HOME_DRIVING_FUTURE_DESC_1": "Ofrecer servicios diversos en un solo lugar permite la promoción cruzada y ayuda a los clientes a descubrir soluciones relacionadas.",
                    "HOME_DRIVING_FUTURE_DESC_2": "Un enfoque integrado mejora la colaboración y comunicación, lo que conduce a soluciones más eficientes para los clientes.",
                    "HOME_DRIVING_FUTURE_DESC_3": "Múltiples servicios permiten la venta cruzada y la venta adicional, revelando opciones beneficiosas para los clientes.",
                    "HOME_DRIVING_FUTURE_DESC_4": "Los clientes obtienen soluciones a medida que utilizan la experiencia y recursos de tu empresa en diversas áreas de servicio.",
                    "HOME_DRIVING_FUTURE_DESC_5": "Los visitantes pueden acceder a diversos servicios desde una sola plataforma, ahorrando tiempo y esfuerzo.",

                    //SERVICES PAGES TITLE
                    HOME_SERVICES_TITLE: "Más servicios",
                    OUR_SERVICES: "Nuestros servicios",

                    //SERVICES PAGES (IA)
                    IA_SERVICE_FIRST_TITLE: 'Plataforma de Análisis Predictivo',
                    IA_SERVICE_SECOND_TITLE: 'Asistente Virtual Inteligente',
                    IA_SERVICE_THIRD_TITLE: 'Sistemas de Recomendación',

                    IA_SERVICE_FIRST_DESCR: 'Ofrecemos una plataforma de análisis predictivo que aprovecha la inteligencia artificial y el aprendizaje automático, lo que permite a las empresas tomar decisiones informadas a través de la identificación de tendencias y patrones de datos.',
                    IA_SERVICE_SECOND_DESCR: 'Desarrollamos asistentes virtuales impulsados ​​por IA que son más rápidos e inteligentes, mejorando el servicio al cliente, automatizando las respuestas y ofreciendo soporte las 24 horas, los 7 días de la semana.',
                    IA_SERVICE_THIRD_DESCR: 'Construimos sistemas de recomendación de Machine Learning que impulsan la retención de clientes y las ventas a través de sugerencias personalizadas.',

                    //SERVICES PAGES(RPA)
                    RPA_SERVICE_FIRST_TITLE: 'Automatización de Procesos Empresariales',
                    RPA_SERVICE_SECOND_TITLE: 'Asesoría en Implementación de RPA',

                    RPA_SERVICE_FIRST_DESCR: 'Implementamos soluciones RPA para automatizar tareas repetitivas y manuales, lo que aumenta la eficiencia y reduce los errores humanos.',
                    RPA_SERVICE_SECOND_DESCR: 'Ofrecemos consultoría e implementación de RPA para ayudar a las empresas a automatizar tareas como ingreso de datos, procesamiento de facturas, servicio al cliente, incorporación de RR.HH. y más.',

                    //SERVICES PAGES (CLOUD)
                    CLOUD_SERVICE_FIRST_TITLE: 'Migración a la Nube',
                    CLOUD_SERVICE_SECOND_TITLE: 'Gestión de la Nube',

                    CLOUD_SERVICE_FIRST_DESCR: 'Ayudamos a las empresas a migrar sus sistemas y datos a la nube, ofreciendo opciones de infraestructura escalable y segura.',
                    CLOUD_SERVICE_SECOND_DESCR: 'Proporcionamos servicios de gestión de la nube, monitoreo y optimización para garantizar un rendimiento óptimo y una seguridad sólida en entornos de nube.',

                    //SERVICES PAGES (BI)
                    BI_SERVICE_FIRST_TITLE: 'Dashboards Personalizado',
                    BI_SERVICE_SECOND_TITLE: 'Análisis de Datos Avanzado',

                    BI_SERVICE_FIRST_DESCR: 'Diseñamos dashboards y paneles de control personalizados que brindan información en tiempo real y análisis de datos para la toma de decisiones estratégicas.',
                    BI_SERVICE_SECOND_DESCR: 'Utilizamos herramientas de BI para realizar análisis avanzados, como análisis de tendencias, segmentación de clientes y detección de anomalías.',

                    //SERVICES PAGES(WEB_DEV)
                    WEB_DEV_SERVICE_FIRST_TITLE: 'Desarrollo de Sitios Web Responsive',
                    WEB_DEV_SERVICE_SECOND_TITLE: 'Desarrollo de Aplicaciones Móviles',

                    WEB_DEV_SERVICE_FIRST_DESCR: 'Creamos sitios web responsivos y atractivos que se adaptan a diferentes dispositivos y ofrecen una experiencia de usuario excepcional.',
                    WEB_DEV_SERVICE_SECOND_DESCR: 'Diseñamos y desarrollamos aplicaciones móviles nativas y multiplataforma para satisfacer las necesidades específicas de nuestros clientes.',

                    //QUIENES SOMOS
                    QS_TITLE: '¿Quiénes somos?',
                    QS_VALUES: 'Nuestros Valores',
                    QS_VALUES_DESCR: 'A través de la confianza, prácticas honestas y éticas, creamos soluciones rápidas y valiosas que ofrecen una ventaja competitiva mediante la utilización de la tecnología y la innovación humana.',
                    QS_MISION: 'Misión',
                    QS_MISION_DESCR: 'Convertirnos en los socios de confianza líderes para habilitar la inteligencia artificial, la automatización y la innovación a través de una amplia gama de soluciones rentables.',
                    QS_VISION: 'Visión',
                    QS_VISION_DESCR: 'Transformar la sociedad y las corporaciones con tecnología e innovación a una velocidad extraordinaria.',

                    //AUTOMATE PAGE
                    AUT_TITLE: 'Automatiza tu empresa',
                    AUT_SUBTITLE: '¿Qué son los procesos automatizados?',

                    AUT_BUTTON: 'Chatea con nosotros',

                    AUT_IMG_TITLE_1: 'Inteligencia artificial',
                    AUT_IMG_TITLE_2: 'Microsoft Power Platform',
                    AUT_IMG_TITLE_3: 'Machine Learning',
                    AUT_IMG_TITLE_4: 'Plataformas Tecnológicas Innovadoras',

                    AUT_FIRST_TITLE: 'Nuestros Clientes suelen querer',
                    AUT_SECOND_TITLE: 'Nuestros Clientes sueñan con',
                    AUT_THIRD_TITLE: 'Nuestros Clientes temen',

                    AUT_FIRST_TEXT: 'Los procesos automatizados son aquellos que se realizan mediante el uso de tecnologías como:',
                    AUT_SECOND_TEXT: 'Estos procesos permiten a las organizaciones mejorar su rendimiento, eficiencia y adaptabilidad, así como crear experiencias de cliente excepcionales y memorables.',
                    AUT_THIRD_TEXT: 'Adquirir las habilidades y conocimientos necesarios para aprovechar las oportunidades que ofrecen las tecnologías de automatización, y crear soluciones que se ajusten a sus necesidades y objetivos específicos. Para ello, puede ser útil ofrecerles formación, asesoramiento y recursos que les ayuden a entender cómo funcionan los procesos automatizados, qué factores influyen en sus resultados y cómo optimizar su rendimiento y valor.',
                    AUT_FOUR_TEXT: 'Acompañamiento al enfrentarse a barreras técnicas, organizativas o regulatorias para implementar los procesos automatizados en sus procesos y sistemas. Para ello, puede ser útil proporcionarles una visión estratégica, un marco de gobernabilidad y una plataforma tecnológica que les permitan integrar los procesos automatizados con sus sistemas existentes y cumplir con las normas y regulaciones.',
                    AUT_FIFTH_TEXT: 'Ser un líder en su industria o campo gracias a la innovación y la transformación digital impulsadas por los procesos automatizados. Para ello, puede ser útil inspirarles con ejemplos de casos de éxito, mostrarles los beneficios y el potencial de los procesos automatizados, y animarles a experimentar y a innovar con las tecnologías de automatización',
                    AUT_SIX_TEXT: 'Lograr una armonía y una sinergia entre los procesos automatizados y los humanos, y crear una cultura de colaboración y empoderamiento. Para ello, puede ser útil involucrarles en el diseño, la implementación y el mantenimiento de los procesos automatizados, explicarles el propósito y el valor de los procesos automatizados, y reconocer y recompensar sus contribuciones y logros',
                    AUT_SEVEN_TEXT: 'Perder el control o la confianza en los procesos automatizados, y que estos causen errores, daños o perjuicios. Tu audiencia puede tener dudas o preocupaciones sobre la fiabilidad, la seguridad y la responsabilidad de los procesos automatizados, y temer que estos fallen, se desvíen o se vuelvan en su contra. Para ello, puede ser útil garantizarles la calidad, la transparencia y la trazabilidad de los procesos automatizados, establecer mecanismos de control, supervisión y auditoría de los procesos automatizados, y definir normas y protocolos de actuación en caso de incidencias o emergencias',
                    AUT_EIGHT_TEXT: 'Ser reemplazado o desplazado por los procesos automatizados, y perder su relevancia o competitividad en el mercado. Tu audiencia puede sentirse amenazada o desvalorizada por los procesos automatizados, y temer que estos les quiten su trabajo, su rol o su posición. Para ello, puede ser útil asegurarles que los procesos automatizados no pretenden sustituirles sino complementarles, identificar y desarrollar sus fortalezas y capacidades diferenciales, y ofrecerles oportunidades de crecimiento y desarrollo profesional',

                    //CONTACT US PAGE
                    CONTACT_US_TITLE: 'Lleva tu negocio a la nueva era de la tecnología!',

                    //CONTACT FORM
                    FORM_HEADER: 'CONTÁCTANOS',
                    FORM_TITLE: 'Si estás interesado en formar parte de nuestro equipo, por favor completa el formulario a continuación. Nos pondremos en contacto contigo a la brevedad. ¡Gracias! ',
                    FORM_CONTACT_US: 'CONTÁCTANOS',
                    FORM_FOLLOW: 'SÍGUENOS EN',
                    FORM_NAME: '¿Quién nos escribe?',
                    FORM_EMAIL: 'Regálanos tu correo',
                    FORM_PHONE: 'Regálanos tu teléfono',
                    FORM_MESSAGE: 'Dejanos tu mensaje',
                    FORM_POLICY: 'Al marcar esta casilla, confirmas que has leído y estás de acuerdo con nuestra política de tratamiento de datos.',
                    FORM_POLICY_LINK: 'Haz clic aquí para leer la política completa.',
                    FORM_BUTTON: 'Enviar',
                    FORM_BUTTON_LOADING: 'Enviando...',
                    FORM_NAME_LABEL: 'Nombre',
                    FORM_PLACEHOLDER: '',
                    FORM_NAME_PHONE: 'Teléfono',
                    FORM_NAME_MESSAGE: 'Mensaje',

                    //WORK WITH US FORM
                    WORK_US_YOUR_COUNTRY: "Selecciona tu pais",
                    WORK_US_YOUR_STATE: "Selecciona tu estado",
                    WORK_US_WAY_KNOW: "¿Como supiste de nosotros?",
                    WORK_US_SKILLS: "Selecciona tus habilidades",
                    WORK_US_VACANCY: "Selecciona una opción",
                    WRITE_YOUR_DESIRED_POSITION: "Escribe la posicion",
                    WORK_US_RESUME_MESSAGE: "Cuentanos sobre ti",

                    //HEADER
                    HEADER_CONTRACT: '¡Me interesa!',
                    HEADER_SERVICES: 'Servicios',
                    HEADER_WHOWEARE: '¿Quiénes somos?',
                    WORK_WITH_US: 'Trabaja con nosotros',
                    GET_HIRED: 'Trabaja con Nosotros',

                    //FOOTER
                    FOOTER_MESSAGE: 'La gerencia aprueba la política de tratamiento de datos el 8 de febrero de 2024, Se publica en las respectivas plataformas para el acceso público',
                    FOOTER_POLICIES: 'Políticas',
                    FOOTER_TERMS: 'Términos de uso',
                    FOOTER_DATA: 'Tratamiento de datos',
                    FOOTER_WHOWEARE: 'Nuestro equipo',
                    FOOTER_CONTACT_US: 'Contáctanos',

                    //BUTTONS
                    APPOINTMENT_BUTTON: "Agendar una cita",

                    // TECH SERVICES TITLES
                    "TECH_SERVICES_TITLE": "Servicios Tecnológicos",
                    "START_AUTOMATING_WORLD": "¡Comienza a automatizar tu mundo!",
                    Tools_That_We_Use: "Herramientas que usamos",

                    RECRUITMENT_TITLE_CARD: 'Reclutamiento',
                    RECRUITMENT_TITLE: 'Descubra a los mejores talentos con nuestro servicio de reclutamiento.',
                    RECRUITMENT_TEXT: 'Nuestro servicio de headhunting se especializa en identificar y reclutar talentos de primer nivel para su empresa. Tanto si necesita ejecutivos, directivos o puestos especializados, nos aseguramos de que encuentre la persona adecuada.',
                    APPLY_NOW: 'Aplica Ahora',
                    EXPERT_RECRUITERS: "Expertos en Contratación",
                    EXPERT_RECRUITERS_DESC: "Nuestro equipo posee profundos conocimientos y contactos en el sector.",

                    PERSONALIZED_APPROACH: "Enfoque Personalizado",
                    PERSONALIZED_APPROACH_DESC: "Adaptamos nuestra búsqueda a sus necesidades específicas.",

                    PROVEN_TRACK_RECORD: "Historial Probado",
                    PROVEN_TRACK_RECORD_DESC: "Hemos colocado con éxito a los mejores talentos en empresas líderes.",
                    FIND_YOUR_NEXT_TALENT: 'Encuentre su próximo talento',
                    FEATURED_JOBS: 'Empleos Destacados',
                    RECRUITMENT_CTA: 'No te pierdas increíbles oportunidades remotas en TI. ¡Únete a nosotros hoy y lleva tu carrera a nuevas alturas!',
                    FEATURE_GLOBAL_OPPORTUNITIES: 'Oportunidades Globales',
                    FEATURE_GLOBAL_OPPORTUNITIES_TEXT: 'Accede a emocionantes oportunidades de trabajo con empresas líderes en todo el mundo sin salir de tu casa.',
                    FEATURE_COMPETITIVE_COMPENSATION: 'Compensación Competitiva',
                    FEATURE_COMPETITIVE_COMPENSATION_TEXT: 'Benefíciate de salarios competitivos y atractivos beneficios que te podemos ofrecer gracias a nuestras empresas asociadas asociadas.',
                    FEATURE_SUPPORT_TRAINING: 'Soporte y Capacitación',
                    FEATURE_SUPPORT_TRAINING_TEXT: 'Recibe apoyo continuo y capacitación para mejorar tus habilidades y crecimiento profesional.',
                    UNLOCK_GLOBAL_CAREER_TITLE: '!Inicia tu carrera global en TI!',
                    JOIN_TALENT_POOL_TITLE: 'Conectando con los mejores profesionales del sector.',
                    WE_OFFER: "Te Ofrecemos",
                    FORM_STEP_TITLE: 'Un paso más antes de llevar su empresa al siguiente nivel',
                    FORM_INSTRUCTION: 'Por favor, complete el formulario para que podamos contactarlo para brindarle más asistencia sobre el servicio que desea adquirir con nosotros.',
                    FORM_PLACEHOLDER: 'Ingrese su nombre',
                    FORM_LABEL_NAME: 'Su Nombre',
                    FORM_LABEL_COUNTRY: 'País de Residencia',
                    FORM_LABEL_DEPARTMENT: 'Departamento/Estado',
                    FORM_LABEL_PHONE: 'Número de Teléfono',
                    FORM_LABEL_EMAIL: 'Su Correo Electrónico',
                    FORM_LABEL_SERVICE: 'Servicio que Desea Adquirir',
                    FORM_ADD_SERVICE: '+ Agregar Otro Servicio',
                    FORM_LABEL_EXTRA_INFO: 'Información adicional sobre las necesidades de su empresa',
                    FORM_BUTTON_LOADING: 'Enviando...',
                    FORM_BUTTON: 'Enviar',

                    "FORM_LABEL_CITY": "Ciudad donde se encuentra",
                    "FORM_LABEL_ADDRESS": "Dirección",
                    "FORM_LABEL_REMOTE_WORK": "¿Puede para trabajar de forma remota?",
                    "FORM_LABEL_POSITION": "Puesto actual (si aplica)",
                    "FORM_LABEL_PREVIOUS_EXPERIENCE": "Experiencia Previa (en años)",
                    "FORM_LABEL_SUBMIT_RESUME": "Enviar su currículum",
                    "FORM_LABEL_JOB_POSITION": "Puesto de Trabajo",
                    "FORM_LABEL_SKILLS": "Habilidades",
                    "FORM_LABEL_CV": "Subir archivo",
                    "FORM_POSITION_LOOKING_FOR": "Puesto que está buscando",
                    "FORM_TIME_NEED_EXPERT": "Tiempo que necesita a nuestro experto",
                    FORM_SELECT_OPTION: "Selecciona una opcion",
                    ADD_ANOTHER_TIME: "Añadir otro tiempo",

                    SHORT_TERM: 'Corto Plazo: 1-6 Meses',
                    MID_TERM: 'Mediano Plazo: 6-12 meses',
                    LONG_TERM: 'Largo Plazo: Más de 1 año',


                    "FORM_TITLE_READY_STREAMLINE": "¿Listo para optimizar su práctica?",
                    "FORM_TEXT_HIRE_VMA": "Complete el formulario a continuación para contratar un asistente médico virtual calificado hoy.",
                    "FORM_COUNTRY_RESIDENCE": "País de Residencia",
                    "FORM_MEDICAL_SPECIALIZATION": "Su Especialización Médica",
                    "FORM_TASKS_VMA": "Tareas que Requiere Para el Asistente Médico Virtual",
                    "FORM_MEET_CANDIDATE": "¿Cuándo Quiere Reunirse con Nuestro Candidato?",
                    "ADD_ANOTHER_POSITION": "Agregar otro puesto",
                    "FORM_TITLE_JOIN_TEAM": "Únete a nuestro equipo dinámico dedicado a marcar la diferencia en la atención remota al paciente",
                    "FORM_PHONE_NUMBER": "Número de Teléfono",
                    "FORM_YOUR_EMAIL": "Su Correo Electrónico",
                    "FORM_DEPARTMENT_LOCATION": "Departamento Donde Se Encuentra",
                    "FORM_CITY_LOCATION": "Ciudad Donde Se Encuentra",
                    "FORM_ADDRESS": "Dirección",
                    "FORM_REMOTE_WORK": "¿Está disponible para trabajar de forma remota?",
                    "FORM_CURRENT_POSITION": "Puesto Actual (Si Aplica)",
                    "FORM_PREVIOUS_EXPERIENCE": "Experiencia Previa (en años)",
                    "FORM_SUBMIT_RESUME": "Enviar su CV",
                    "FORM_UPLOAD_FILE": "Cargar Archivo",
                    FORM_ADDITIONAL_INFORMATION: "Información Adicional",
                    "FORM_BUTTON_LOADING": "Enviando...",
                    "FORM_BUTTON": "Enviar",
                    CONTACT_WAY_VALIDATION: "Debes seleccionar una forma de contacto",
                    CONTACT_WAY: "Prefiero que me contacten por:",
                    EMAIL: "Correo",
                    BOTH: "Cualquiera",
                    CONTACT_CENTER_PATIENTS: 'Centro de Contacto para Pacientes',
                    CONTACT_CENTER_PATIENTS_DESC: 'Brinde comunicación y soporte profesional y amigable a los pacientes con nuestro Centro de Contacto para Pacientes, asegurando una interacción y coordinación de atención sin problemas.',
                    SCHEDULING_CENTER: 'Centro de Programación',
                    SCHEDULING_CENTER_DESC: 'Gestione y organice eficientemente las citas de los pacientes con nuestro Centro de Programación, asegurando una coordinación fluida y oportuna.',
                    DIAGNOSTIC_REVIEWS_COMMUNICATIONS: 'Revisiones Diagnósticas y Comunicación de Resultados',
                    DIAGNOSTIC_REVIEWS_COMMUNICATIONS_DESC: 'Asegure una revisión oportuna de los diagnósticos y una comunicación efectiva de los resultados a los pacientes con nuestro servicio de Revisiones Diagnósticas y Comunicación de Resultados.',
                    TREATMENT_MANAGEMENT: 'Gestión del Tratamiento',
                    TREATMENT_MANAGEMENT_DESC: 'Optimice los planes de tratamiento y la coordinación de los pacientes con nuestro servicio de Gestión del Tratamiento, asegurando una atención integral y organizada.',
                    RISKS_MANAGEMENT: 'Gestión de Riesgos',
                    RISKS_MANAGEMENT_DESC: 'Gestione y mitigue proactivamente los riesgos de los pacientes con nuestro servicio de Gestión de Riesgos, asegurando la seguridad y el cumplimiento en las prácticas de atención médica.',
                    EMR_MAINTENANCE: 'Mantenimiento de EMR',
                    EMR_MAINTENANCE_DESC: 'Mantenga y actualice eficientemente los Registros Médicos Electrónicos con nuestro servicio de Mantenimiento de EMR, asegurando información precisa y accesible de los pacientes.',
                    AUDITING: 'Auditoría',
                    AUDITING_DESC: 'Asegure el cumplimiento y la precisión en las prácticas de atención médica con nuestro servicio de Auditoría, proporcionando evaluaciones exhaustivas y completas.',
                    BILLING_COORDINATION: 'Coordinación de Facturación',
                    BILLING_COORDINATION_DESC: 'Optimice los procesos de facturación y coordinación de los pacientes con nuestro servicio de Coordinación de Facturación, asegurando precisión y eficiencia.',
                    FINANCIAL_COVERAGE_PRECHECKS: 'Verificaciones Financieras y de Cobertura',
                    FINANCIAL_COVERAGE_PRECHECKS_DESC: 'Asegure verificaciones financieras y de cobertura precisas con nuestro servicio de Verificaciones Financieras y de Cobertura, optimizando los procesos de facturación y reembolso.',
                    VIRTUAL_MEDICAL_SERVICES_TITLE: 'Nuestros Servicios Médicos Virtuales Integrales',
                    VIRTUAL_MEDICAL_SERVICES_DESC: 'Conozca nuestra gama de servicios médicos virtuales, diseñados para optimizar su práctica y elevar la calidad de la atención al paciente. Explore nuestras opciones para una gestión más eficiente y efectiva de su práctica.',
                    READY_ENHANCE_PRACTICE: '¿Listo para Mejorar su Práctica?',
                    CONTACT_US_FOR_MORE_INFO_1: 'Contáctenos para obtener más información sobre nuestros servicios',
                    CONTACT_US_FOR_MORE_INFO_2: 'y cómo pueden beneficiar su práctica.',

                    "MORE_INFO_BUTTON": "Más Información",
                    "TECH_SERVICES_TEXT": "Destacamos por nuestra capacidad para crear productos innovadores sin necesidad de programación manual, lo que nos permite adaptarnos rápidamente a las necesidades específicas de nuestros clientes.",
                    "VMA_TITLE_HOME": "AMV",
                    "VMA_TEXT_HOME": "Nuestro equipo gestiona la programación, la comunicación con los pacientes, los registros, las recetas y la coordinación de seguros.",
                    "VMA_SUBTITLE": "(Asistente Médico Virtual)",
                    "CONSULTING_TITLE": "Consultoría",
                    "CONSULTING_TEXT": "Nuestro servicio abarca la planificación estratégica, el desarrollo, la optimización, las finanzas, el marketing, la tecnología y los recursos humanos.",
                    "INVESTMENTS_TITLE": "Inversiones",
                    "INVESTMENTS_TEXT": "Guiamos inversiones en industrias como la construcción, el comercio minorista, el entretenimiento, las startups, las finanzas y más.",
                    "HOME_INTRODUCING_PEOPLE_360": "Presentamos People 360",
                    "HOME_ONE_STOP_SOLUTION": "Tu solución integral para la gestión de empleados y proyectos.",
                    "HOME_PEOPLE_360_DESC": "People 360 está diseñado para agilizar tus tareas de gestión de recursos humanos y proyectos. Con nuestra plataforma, tanto empleados como empleadores pueden acceder fácilmente a los beneficios de la empresa, rastrear el progreso de los proyectos y gestionar la información de los clientes, todo en un solo lugar.",
                    "HOME_EMPLOYEE_BENEFITS": "Beneficios para empleados",
                    "HOME_EMPLOYEE_BENEFITS_DESC": "Gestiona vacaciones, días libres y préstamos estudiantiles sin esfuerzo.",
                    "HOME_PROJECT_TRACKING": "Seguimiento de proyectos",
                    "HOME_PROJECT_TRACKING_DESC": "Monitorea el estado de los proyectos, historias de usuarios, épicas y tareas.",
                    "HOME_CLIENT_INFORMATION": "Información del cliente",
                    "HOME_CLIENT_INFORMATION_DESC": "Los empleadores pueden acceder rápidamente y fácilmente a información detallada de los clientes.",
                    "BENEFITS_TITLE": "Beneficios de Trabajar Con Nosotros",
                    "BENEFIT_1_TITLE": "Nos enorgullece cultivar relaciones comerciales sólidas",
                    "BENEFIT_1_TEXT": "proporcionando servicios de alta calidad que ofrecen resultados excepcionales. Nuestro compromiso con la excelencia nos distingue y nos impulsa a superar las expectativas de nuestros clientes.",
                    "BENEFIT_2_TITLE": "Tenemos una cultura de trabajo remoto, con un equipo joven y experimentado",
                    "BENEFIT_2_TEXT": "especializado en automatizaciones y GenAI. Nuestra flexibilidad y enfoque en la innovación nos permiten ofrecer soluciones de vanguardia que impulsan el éxito de nuestros clientes en la era digital.",
                    "BOOK_APPOINTMENT_BUTTON": "Agendar una Cita",
                    "ALL_UNDER_ONE_ROOF_TITLE": "¡Todo Bajo Un Mismo Techo!",
                    "ROOF_1_TEXT": "Tener diversos servicios bajo un mismo techo permite la promoción cruzada y la sinergia entre diferentes ofertas. Por ejemplo, los clientes interesados en servicios de reclutamiento también pueden encontrar soluciones de consultoría o tecnológicas relevantes para sus necesidades.",
                    "ROOF_2_TEXT": "Un enfoque integrado de los servicios fomenta la colaboración y la comunicación fluida entre diferentes equipos o departamentos dentro de la empresa, lo que lleva a soluciones más eficientes y efectivas para los clientes.",
                    "ROOF_3_TEXT": "Ofrecer múltiples servicios permite oportunidades de venta cruzada y upselling, donde los clientes pueden descubrir servicios adicionales que no habían considerado inicialmente, pero que encuentran beneficiosos.",
                    "ROOF_4_TEXT": "Los clientes pueden beneficiarse de soluciones personalizadas y a medida que aprovechan la experiencia y los recursos de su empresa en diferentes áreas de servicio.",
                    "ROOF_5_TEXT": "Los visitantes pueden acceder a una amplia gama de servicios de manera conveniente desde una sola plataforma, ahorrando tiempo y esfuerzo en la búsqueda de diferentes proveedores.",


                    CONNECT_BEST_WORKHAND: 'Conéctate con la mejor mano de obra',
                    EMPOWER_BUSINESS: 'Potencia tu negocio con nuestra fuerza laboral global y servicios de consultoría expertos. Da el siguiente paso hacia el éxito al asociarte con nosotros hoy.',
                    BOOK_APPOINTMENT: 'Reserva una cita',
                    EXPLORE: 'Explorar',
                    CONTRACTORS_FLEXIBILITY: 'Ofrecemos flexibilidad en la dotación de personal, permitiendo a las empresas aumentar o reducir su fuerza laboral según las demandas del proyecto. Esta flexibilidad les ayuda a adaptarse rápidamente a las condiciones cambiantes del mercado sin el compromiso a largo plazo de contratar empleados a tiempo completo.',
                    CONTRACTORS_EXPERTISE: 'Al asociarse con contratistas y consultores, las empresas acceden a conocimientos y habilidades especializadas que pueden no estar disponibles internamente. Estos profesionales aportan nuevas perspectivas, conocimientos de la industria y mejores prácticas a los proyectos, ayudando a las empresas a alcanzar sus objetivos de manera más efectiva.',
                    CONTRACTORS_MULTIPLE_SERVICES: 'Utilizar servicios de consultoría y contratistas puede ser más rentable que contratar empleados a tiempo completo para proyectos a corto plazo o tareas especializadas. Las empresas pueden ahorrar en costos generales como salarios, beneficios y gastos de capacitación, y al mismo tiempo acceder a talento de alta calidad para respaldar sus objetivos comerciales.',
                    COMPANIES_CONSULTING_SERVICES: 'SERVICIOS DE CONSULTORÍA PARA EMPRESAS',
                    WORK_WITH_US_AS_A_CONTRACTOR: 'TRABAJA CON NOSOTROS COMO CONTRATISTA',
                    OUTSOURCING_SERVICES: 'ACCEDE AL MEJOR TALENTO PARA TU NEGOCIO',

                    // CONSULTING SERVICES 

                    //CONSULTING VIEW
                    EXPLORE_OPPORTUNITIES: '¡Exploremos oportunidades juntos y veamos a dónde puede llevarnos tu experiencia!',
                    APPLY_NOW: '¡Aplica ahora!',
                    FIND_YOUR_NEXT_TALENT: 'Encuentre su próximo talento',
                    ABOUT_POSITION: 'Sobre la Posición',
                    POSITIONS_APPLY: 'Posiciones a las que Puedes Aplicar',
                    CONSULTING_FLEXIBILITY: 'Disfruta de mayor flexibilidad en tus horarios de trabajo. Al trabajar como contratista, puedes elegir cuándo y dónde trabajar, lo que permite un mejor equilibrio entre la vida laboral y personal. Esta flexibilidad también se extiende a los tipos de proyectos que asumes, permitiéndote explorar diferentes industrias y trabajar con diversos clientes.',
                    CONSULTING_DIVERSE_PROJECTS: 'Como contratista, tendrás la oportunidad de trabajar en una variedad de proyectos con diferentes clientes. Esta exposición a diversos proyectos puede ayudarte a ampliar tus habilidades, ganar experiencia valiosa y construir un portafolio sólido. También te permite mantenerte actualizado con las tendencias e innovaciones de la industria.',
                    CONSULTING_HIGHER_EARNINGS: 'Al trabajar como contratista, tendrás un mayor potencial de ingresos que los empleados a tiempo completo. Dado que los contratistas a menudo son pagados según los proyectos que completan o las horas trabajadas, puedes negociar tus tarifas y asumir múltiples proyectos simultáneamente, lo que lleva a mayores oportunidades de ingresos.',

                    //CONTRACTORS VIEW
                    HIRE_EXPERTS: 'Contratar Expertos',
                    SOFTWARE_DEVELOPER: 'Desarrollador de Software',
                    SOFTWARE_DEVELOPER_DESC: 'Desbloquea la innovación y fomenta la transformación digital con nuestros desarrolladores de software capacitados. ¡Desde la magia de la codificación hasta la creación de experiencias de usuario fluidas, nuestros desarrolladores dan vida a tus visiones tecnológicas!',
                    CYBERSECURITY_EXPERT: 'Experto en Ciberseguridad',
                    CYBERSECURITY_EXPERT_DESC: 'Protege tus activos digitales y resguarda tu negocio con nuestros expertos en ciberseguridad. Nuestros especialistas emplean estrategias de vanguardia para fortalecer tus defensas, mitigar riesgos y garantizar la protección de datos en un panorama de amenazas en constante evolución.',
                    FULLSTACK_DEVELOPER: 'Desarrollador FullStack',
                    FULLSTACK_DEVELOPER_DESC: 'Empodera tus proyectos digitales con nuestros desarrolladores Full Stack. Desde el dominio del front-end hasta la brillantez del back-end, nuestros expertos crean soluciones escalables y fluidas que elevan las experiencias de los usuarios y fomentan el crecimiento del negocio.',
                    DATABASE_EXPERT: 'Experto en Bases de Datos',
                    DATABASE_EXPERT_DESC: 'Optimiza la gestión de datos y obtén insights con nuestros expertos en bases de datos. Desde el diseño de arquitecturas robustas hasta la garantía de la integridad de los datos, nuestros especialistas desbloquean el poder de los datos para impulsar decisiones informadas y el éxito empresarial.',
                    UX_UI_DESIGNER: 'Diseñador UX/UI',
                    UX_UI_DESIGNER_DESC: 'Transforma las experiencias de usuario y eleva la estética del diseño con nuestros diseñadores UX/UI. Desde interfaces intuitivas hasta visuales atractivos, nuestros expertos crean experiencias digitales inmersivas que cautivan a las audiencias y fomentan interacciones significativas.',
                    COST_SAVINGS: 'Ahorro de Costos',
                    COST_SAVINGS_DESC: 'Reduce los costos generales asociados con el personal interno.',
                    COST_SAVINGS_DESC_OUT: 'Disfruta de una mayor flexibilidad en tus horarios de trabajo. Al trabajar como contratista, puedes elegir cuándo y dónde trabajar, lo que te permite un mejor equilibrio entre el trabajo y la vida personal. Esta flexibilidad también se extiende a los tipos de proyectos que aceptas, lo que te permite explorar diferentes industrias y trabajar con diversos clientes.',
                    ACCESS_SPECIALIZED_SKILLS: 'Acceso a Habilidades Especializadas',
                    ACCESS_SPECIALIZED_SKILLS_DESC: 'La subcontratación permite a las empresas acceder a un talento global y a habilidades especializadas que pueden no estar disponibles internamente. Ya sea experiencia en TI, competencia en marketing digital o fluidez en idiomas, la subcontratación permite a las empresas cubrir brechas de habilidades de manera eficiente.',
                    FLEXIBILITY_SCALABILITY: 'Flexibilidad y Escalabilidad',
                    FLEXIBILITY_SCALABILITY_DESC: 'La subcontratación proporciona flexibilidad para escalar recursos según las necesidades del negocio. Ya sea un proyecto a corto plazo o soporte continuo, las empresas pueden ajustar sus acuerdos de subcontratación para alinearse con las fluctuaciones de la demanda.',
                    TIME_SAVINGS: 'Ahorro de Tiempo',
                    TIME_SAVINGS_DESC: 'La subcontratación de tareas o procesos puede ahorrar tiempo valioso para los equipos internos, permitiéndoles enfocarse en iniciativas estratégicas y objetivos comerciales principales. Esto puede llevar a una entrega de proyectos más rápida y a una mayor eficiencia.',
                    FIND_SKILLED_TALENT: 'Encuentra talento capacitado, flexibilidad y rentabilidad. ¡Elevemos tus proyectos juntos!',
                    HIRE_DEVELOPERS: 'Contratar con nosotros',
                    ROLES_YOU_CAN_GET: 'Roles que Puedes Obtener',
                    ENDLESS_BENEFITS: 'Beneficios Sin Fin',

                    //OUTSOURCING
                    MARKET_EXPANSION: 'Expansión de Mercado',
                    STRATEGIES: 'Estrategias',
                    MARKET_EXPANSION_DESC: 'Llegar a más clientes, explorar nuevos mercados y aumentar tus ventas. ¡Trabajemos juntos para expandir tus horizontes y desbloquear emocionantes oportunidades de crecimiento!',
                    BUSINESS_DEVELOPMENT: 'Desarrollo de Negocios',
                    AND_GROWTH_PLANNING: 'y Planificación del Crecimiento',
                    BUSINESS_DEVELOPMENT_DESC: 'Llegar a más clientes, explorar nuevos mercados y aumentar tus ventas. ¡Trabajemos juntos para expandir tus horizontes y desbloquear emocionantes oportunidades de crecimiento!',
                    DIGITAL_TRANSFORMATION: 'Transformación Digital',
                    ADVISORY: 'Asesoramiento',
                    DIGITAL_TRANSFORMATION_DESC: 'Te ayudaremos a aprovechar el poder de la tecnología para agilizar procesos, mejorar las experiencias de los clientes y mantenerte a la vanguardia en el panorama digital actual. ¡Transformémonos juntos para el éxito!',
                    TECHNOLOGY_ROADMAP: 'Hoja de Ruta Tecnológica',
                    DEVELOPMENT: 'Desarrollo',
                    TECHNOLOGY_ROADMAP_DESC: 'Crearemos una hoja de ruta estratégica con soluciones de vanguardia, alineando tu tecnología con tus objetivos comerciales. ¡Prepárate para la innovación y el crecimiento!',
                    STARTUP_CONSULTING: 'Consultoría para Startups',
                    STARTUP_CONSULTING_DESC: 'Te guiaremos en cada paso del lanzamiento de tu nuevo proyecto, desde la creación de un plan de negocios sólido hasta la navegación de estrategias de entrada al mercado. ¡Convirtamos tu visión en una realidad próspera juntos!',
                    INCREASED_MARKET_SHARE: 'Aumento de la cuota de mercado y la visibilidad de la marca',
                    STRATEGIC_PLANNING: 'Planificación estratégica para un crecimiento sostenible',
                    ENHANCED_DIGITAL_CAPABILITIES: 'Capacidades digitales mejoradas y eficiencia',
                    EXPERT_GUIDANCE: 'Orientación experta para navegar los desafíos de la industria',
                    TAILORED_STRATEGIES: 'Estrategias personalizadas para nuevos negocios para establecer una base sólida',
                    EMPOWERING_IT_COMPANIES: 'Empoderando a las empresas de TI para prosperar con estrategias personalizadas para el crecimiento y la innovación. ¡Explora nuestras soluciones y eleva tu éxito!',
                    BOOK_AN_APPOINTMENT: 'Reserva una Cita',
                    GREAT_BENEFITS: 'Grandes Beneficios',

                    GROWING_ECONOMY: 'Economía en Crecimiento',
                    GROWING_ECONOMY_DESC: 'Explore un mercado dinámico con una economía en rápido crecimiento y abundantes oportunidades de inversión.',
                    STRATEGIC_LOCATION: 'Ubicación Estratégica',
                    STRATEGIC_LOCATION_DESC: 'Benefíciese de la ubicación estratégica de Colombia en América Latina, que ofrece acceso a mercados regionales.',
                    BUSINESS_FRIENDLY_ENVIRONMENT: 'Entorno Favorable para los Negocios',
                    BUSINESS_FRIENDLY_ENVIRONMENT_DESC: 'Descubra un entorno empresarial acogedor con políticas gubernamentales de apoyo y incentivos para los inversores.',
                    TECHNOLOGY_INNOVATION: 'Tecnología e Innovación',
                    TECHNOLOGY_INNOVATION_DESC: 'Aproveche el próspero escenario tecnológico de Colombia e invierta en startups y empresas tecnológicas innovadoras.',
                    RENEWABLE_ENERGY: 'Energía Renovable',
                    RENEWABLE_ENERGY_DESC: 'Explore oportunidades en proyectos de energía renovable, como solar, eólica e hidroeléctrica.',
                    TOURISM_HOSPITALITY: 'Turismo y Hospitalidad',
                    TOURISM_HOSPITALITY_DESC: 'Considere inversiones en el próspero sector del turismo y la hospitalidad, incluidos hoteles, resorts y proyectos de ecoturismo.',
                    AGRIBUSINESS: 'Agroindustria',
                    AGRIBUSINESS_DESC: 'Explore oportunidades agrícolas que van desde el café y las frutas hasta prácticas agrícolas sostenibles.',
                    REAL_ESTATE: 'Bienes Raíces',
                    REAL_ESTATE_DESC: 'Descubra el potencial en el mercado inmobiliario, incluidas propiedades comerciales, desarrollos residenciales y proyectos de uso mixto.',
                    RESEARCH: 'Investigación',
                    RESEARCH_DESC: 'Realice una investigación exhaustiva sobre oportunidades de inversión, tendencias del mercado y marcos regulatorios.',
                    LOCAL_PARTNERSHIPS: 'Asociaciones Locales',
                    LOCAL_PARTNERSHIPS_DESC: 'Considere asociarse con empresas o expertos locales para navegar eficazmente en el mercado.',
                    DIVERSIFICATION: 'Diversificación',
                    DIVERSIFICATION_DESC: 'Diversifique su cartera de inversiones en varios sectores para minimizar riesgos y maximizar rendimientos.',
                    UNLOCK_GROWTH: '¡Desbloquee el Crecimiento desde Cualquier Lugar!',
                    WHY_INVEST_COLOMBIA: '¿Por qué invertir en Colombia?',
                    KEY_INVESTMENT_SECTORS: 'Sectores Clave de Inversión',
                    HOW_WE_CAN_HELP: 'Cómo Podemos Ayudar',
                    HELP_TEXT: 'En 5IG, brindamos orientación y apoyo personalizados para inversores internacionales que buscan explorar oportunidades en Colombia. Nuestro equipo de expertos puede ayudarle a identificar opciones estratégicas de inversión y navegar en el panorama del mercado local.',
                    GET_STARTED: 'Comencemos',
                    GET_STARTED_TEXT: '¿Listo para desbloquear el potencial de inversión en Colombia?',
                    GET_STARTED_CONTACT: 'Contáctenos hoy para discutir sus objetivos de inversión y explorar oportunidades personalizadas.',
                    APPOINTMENT_BUTTON: 'Reserve una Cita',

                    TECH_SOLUTIONS: 'Servicios Tecnológicos',
                    CONSULTING_SOLUTIONS: 'Soluciones de Consultoría',
                    VIRTUAL_MEDICAL_ASSISTANT: 'Asistente Médico Virtual',
                    RECRUITMENT_SERVICES: 'Servicios de Reclutamiento',

                    INVESTMENT_SOLUTIONS: 'Soluciones de Inversión',
                    STEP_BY_STEP: 'Proceso paso a paso',
                    OUR_PARTNERS: 'Nuestros socios de certificación',
                    NEEDS_ASSESSMENT: 'Evaluación de las necesidades',
                    NEEDS_ASSESSMENT_DESC: 'Empezamos por comprender sus necesidades específicas de contratación y la cultura de su empresa.',
                    CANDIDATE_SEARCH: 'Búsqueda de candidatos',
                    CANDIDATE_SEARCH_DESC: 'Nuestro equipo identifica y se acerca a los mejores candidatos del mercado.',
                    SCREENING_AND_INTERVIEWING: 'Selección y entrevistas',
                    SCREENING_AND_INTERVIEWING_DESC: 'Llevamos a cabo una selección exhaustiva y entrevistas iniciales para preseleccionar a los mejores candidatos.',
                    CANDIDATE_PRESENTATION: 'Presentación de candidatos',
                    CANDIDATE_PRESENTATION_DESC: 'Usted revisa los perfiles detallados de los candidatos preseleccionados.',
                    FINAL_SELECTION_AND_ONBOARDING: 'Selección final e incorporación',
                    FINAL_SELECTION_AND_ONBOARDING_DESC: 'Le ayudamos con las entrevistas finales y la incorporación para garantizar una transición fluida.',

                    FORM_TITLE_COLLABORATE: '¡Colaboremos para llevar su negocio a nuevas alturas!',
                    FORM_FIRST_NAME: 'Nombre',
                    FORM_FULL_NAME: 'Nombre completo',
                    FORM_LAST_NAME: 'Apellido',
                    FORM_PHONE_NUMBER: 'Número de Teléfono',
                    FORM_EMAIL: 'Su Correo Electrónico',
                    FORM_COMPANY_NAME: 'Nombre de la Empresa',
                    EXPERT_YOU_ARE_LOOKING: 'Experto que busca',
                    FORM_SERVICE_LOOKING_FOR: 'Servicio que Está Buscando',
                    ADD_ANOTHER_POSITION: 'Agregar Otra Posición',

                    FULL_STACK_DEVELOPER: 'Desarrollador Full Stack',

                    TIME_MANAGEMENT: 'Gestión del Tiempo',
                    TIME_MANAGEMENT_DESC: 'Libere más tiempo para centrarse en el cuidado de los pacientes.',
                    COST_SAVINGS: 'Ahorro de Costos',
                    IMPROVED_ORGANIZATION: 'Mejora de la Organización',
                    IMPROVED_ORGANIZATION_DESC: 'Mantenga su práctica organizada y funcionando sin problemas.',
                    APPOINTMENT_SCHEDULING: 'Programación de Citas',
                    APPOINTMENT_SCHEDULING_DESC: 'Gestione fácilmente las citas de sus pacientes y asegure visitas oportunas.',
                    ADMINISTRATIVE_SUPPORT: 'Soporte Administrativo',
                    ADMINISTRATIVE_SUPPORT_DESC: 'Optimice sus tareas administrativas de manera sencilla y eficiente.',
                    COMMUNICATION_MANAGEMENT: 'Gestión de la Comunicación',
                    COMMUNICATION_MANAGEMENT_DESC: 'Coordínese con su asistente para manejar las interacciones no clínicas con los pacientes.',
                    BROWSE_SERVICE_CATALOG: 'Explore Nuestro Catálogo de Servicios',
                    BROWSE_SERVICE_CATALOG_DESC: 'Descubra cómo podemos ayudarle a mejorar la eficiencia y el cuidado de los pacientes.',
                    EXPLORE_SERVICES: 'Explorar Servicios',
                    IMPULSE_CAREER: 'Impulse su carrera médica',
                    IMPULSE_CAREER_DESC: '¡Únase a nuestro equipo como Asistente Médico Virtual! Acceda a la pantalla de solicitud ahora y comience un viaje gratificante de soporte sanitario remoto.',
                    APPLY_NOW: 'Aplica Ahora',
                    VMA_TITLE: 'AMV (Asistente Médico Virtual)',
                    VMA_TEXT: 'Gestione fácilmente las citas de sus pacientes y asegure visitas oportunas.',
                    ABOUT_SERVICE: 'Sobre el Servicio',
                    DISCOVER_BENEFITS: 'Descubra los beneficios de contratar un asistente médico virtual',
                    STREAMLINE_TASKS: 'Optimice sus tareas administrativas de manera sencilla y eficiente.',
                    KEY_SERVICES_OFFERED: 'Servicios Clave Ofrecidos',
                    HIRE_VMA: 'Contratar un AMV',

                    ENHANCE_PRACTICE_TITLE: 'Mejore su Práctica con Asistentes',
                    ENHANCE_PRACTICE_TITLE2: 'Médicos Virtuales',
                    ENHANCE_PRACTICE_DESC: 'Gestione de manera eficaz sus citas y tareas administrativas.',

                    CAREER_GROWTH_DESC: 'Experimente crecimiento profesional, salarios competitivos, flexibilidad de trabajo remoto y la oportunidad de colaborar con personas de todo el mundo. Eleve su carrera mientras hace un impacto global.',
                    VIRTUAL_HEALTHCARE_OPPORTUNITIES_DESC: '¡Explore emocionantes oportunidades en la atención médica virtual! Únase a nosotros como Asistente Médico Virtual especializado en Pediatría, Psiquiatría, Dermatología y más. Marque la diferencia desde cualquier lugar mientras avanza en su carrera en la medicina remota.',
                    REMOTE_HEALTHCARE_OPPORTUNITY_DESC: '¡Aproveche la oportunidad de trabajar con pacientes en todo Estados Unidos! Únase a nuestro equipo como Asistente Médico Virtual y conéctese con personas en todo el país mientras avanza en su carrera en la atención médica remota.',
                    SCHEDULE_MANAGE_APPOINTMENTS: 'Programar y gestionar citas de pacientes',
                    HANDLE_ADMIN_TASKS: 'Manejar tareas administrativas y registros médicos',
                    COORDINATE_WITH_PROVIDERS: 'Coordinar con proveedores de atención médica y pacientes',
                    UNLOCK_REMOTE_HEALTHCARE: 'Desbloquee el potencial de la atención médica remota con nosotros',
                    APPLY_NOW_VMA: 'Solicite ahora para nuestra posición de Asistente Médico Virtual y disfrute de salarios competitivos y flexibilidad en el trabajo remoto',
                    APPLY_NOW: 'Aplica Ahora',
                    ABOUT_POSITION: 'Sobre la Posición',
                    YOUR_DAILY_TASKS: 'Sus Tareas Diarias',
                    BUSINESS_CREATION_CONSULTANCY: 'Consultoría de creacion de empresa',
                    LEGAL_CONSULTANCY: 'Consultoría legal',
                    FINANCIAL: 'Consultoría Financiera',
                    LABOR_LEGISLATION: 'Consultoría de Legislacion laboral',
                    REQUEST_SUCCESS: 'Tu solicitud fue enviada exitosamente',
                    EXPERT_REACH: '¡Uno de nuestros expertos se pondrá en contacto contigo pronto!',
                    CONTINUE_EXPLORING: 'Seguir Explorando',
                    ABOUT_US: 'Sobre Nosotros',
                    OUR_VALUES: 'Nuestros Valores',
                    OUR_GOAL: 'Nuestro Objetivo',
                    OUR_VISION: 'Nuestra Visión',

                    INNOVATIVE_SOLUTIONS: 'Soluciones innovadoras',
                    EXPERIENCED_TEAM: 'Equipo experimentado',
                    CUSTOMER_CENTRIC: 'Enfoque centrado en el cliente',
                    PROVEN_TRACK_RECORD: 'Experiencia demostrada',
                    GET_TO_KNOW: 'Conozca la empresa que está detrás de la innovación',

                    INNOVATIVE_DESCRIPTION: 'Nos mantenemos a la vanguardia con tecnología y estrategias de vanguardia.',
                    EXPERIENCED_DESCRIPTION: 'Nuestro equipo de experimentados profesionales aporta una gran cantidad de conocimientos y experiencia.',
                    CUSTOMER_DESCRIPTION: 'Damos prioridad a las necesidades de nuestros clientes y trabajamos sin descanso para superar sus expectativas.',
                    PROVEN_TRACK_RECORD_DESCRIPTION: 'Nuestras historias de éxito hablan por sí solas y demuestran nuestra capacidad para ofrecer resultados excepcionales',
                    HEADER_EVENTS: "Eventos",
                    UP_COMING_EVENTS_5IG: 'Próximos eventos en 5IG',
                    LIVE_EVENTS: 'Eventos en directo',
                    UP_COMING_EVENTS: 'Próximos Eventos',
                    RECORDED_EVENTS: 'Eventos grabados',
                    STAY_UPDATED: 'Manténgase al día con nuestros seminarios, seminarios web y conferencias, y esté a la vanguardia de las últimas ideas e innovaciones.',
                    BUTTON_JOIN_NOW: 'Únete ahora',
                    BUTTON_REGISTER_NOW: 'Regístrese ahora',
                    BUTTON_WATCH_NOW: 'Ver ahora',
                    BUTTON_WATCH_MORE: 'Ver Más',
                    BUTTON_WATCH_LESS: 'Ver Menos',
                    INNOVATIVE_SOLUTIONS: 'Soluciones innovadoras',
                    EXPERIENCED_TEAM: 'Equipo experimentado',
                    CUSTOMER_CENTRIC: 'Enfoque centrado en el cliente',
                    PROVEN_TRACK_RECORD: 'Experiencia demostrada',
                    GET_TO_KNOW: 'Conozca la empresa que está detrás de la innovación',

                    INNOVATIVE_DESCRIPTION: 'Nos mantenemos a la vanguardia con tecnología y estrategias avanzadas.',
                    EXPERIENCED_DESCRIPTION: 'Nuestro equipo de profesionales experimentados aporta una gran cantidad de conocimientos y experiencia.',
                    CUSTOMER_DESCRIPTION: 'Damos prioridad a las necesidades de nuestros clientes y trabajamos sin descanso para superar sus expectativas.',
                    PROVEN_TRACK_RECORD_DESCRIPTION: 'Nuestras historias de éxito hablan por sí solas y demuestran nuestra capacidad para ofrecer resultados excepcionales.',


                    VALUES_DESCRIPTION: 'A través de la confianza, prácticas honestas y éticas, creamos soluciones rápidas y valiosas que ofrecen una ventaja competitiva al aprovechar la tecnología y la innovación humana.',
                    GOAL_DESCRIPTION: 'Convertirnos en los principales socios de confianza en la habilitación de inteligencia artificial, automatización e innovación a través de una amplia gama de soluciones rentables.',
                    VISION_DESCRIPTION: 'Transformar la sociedad y las corporaciones con tecnología e innovación a una velocidad extraordinaria.',
                    INNOVATIVE_LEADERS: 'Líderes innovadores en inteligencia artificial y automatización, nos especializamos en automatización inteligente, RPA e inteligencia empresarial.',
                    MISSION_STATEMENT: 'Nuestra misión es simplificar los procesos organizacionales para nuestros clientes aprovechando tecnologías de vanguardia como inteligencia artificial, aprendizaje automático y procesamiento de lenguaje natural.',
                    HAND_IN_HAND: 'Siempre de la mano con nuestros clientes y la tecnología',
                    CONTACT_US: 'Estamos felices de estar en contacto con nuestros clientes.',
                    FORM_TEXT: 'No dude en dejarnos un mensaje con cualquier duda o sugerencia que tenga sobre nuestros servicios.',
                    YOUR_NAME: 'Tu Nombre',
                    PHONE_NUMBER: 'Número de Teléfono',
                    YOUR_EMAIL: 'Tu Correo Electrónico',
                    YOUR_MESSAGE: 'Tu Mensaje',
                    FORM_BUTTON: 'Enviar',
                    FORM_BUTTON_LOADING: 'Enviando...',
                    REQUEST_SUCCESS: 'Tu solicitud fue enviada exitosamente',
                    EXPERT_REACH: '¡Uno de nuestros expertos se pondrá en contacto contigo pronto!',
                    CONTINUE_EXPLORING: 'Seguir Explorando',
                    NAME_REQUIRED: 'El campo del nombre debe estar completado.',
                    COMPANY_REQUIRED: 'El campo de la empresa debe estar completado.',
                    COUNTRY_REQUIRED: 'El campo del país de residencia debe estar completado.',
                    DEPARTMENT_STATE_REQUIRED: 'El campo del departamento/estado debe estar completado.',
                    PHONE_CODE_REQUIRED: 'El campo del código de teléfono debe estar completado.',
                    PHONE_NUMBER_REQUIRED: 'El campo del número de teléfono debe estar completado.',
                    PHONE_NUMBER_ZERO: 'El campo del número de teléfono no puede ser menor o igual a 0.',
                    EMAIL_REQUIRED: 'El campo del correo electrónico debe estar completado.',
                    SERVICE_REQUIRED: 'Debe seleccionar al menos un servicio.',
                    ADDITIONAL_INFO_REQUIRED: 'El campo de información adicional debe estar completado.',
                    POLICIES_REQUIRED: 'Debe aceptar los términos y condiciones.',
                    LAST_NAME_REQUIRED: 'El campo de apellido debe ser completado.',
                    COMPANY_NAME_REQUIRED: 'El campo del nombre de la empresa debe ser completado.',
                    CURRENT_CANDIDATE_DEPARTMENT_REQUIRED: 'El campo del departamento actual del candidato debe ser completado.',
                    CURRENT_CANDIDATE_CITY_REQUIRED: 'El campo de la ciudad actual del candidato debe ser completado.',
                    CURRENT_CANDIDATE_ADDRESS_REQUIRED: 'El campo de la dirección actual del candidato debe ser completado.',
                    APPLICATION_POSITION_REQUIRED: 'El campo de la posición solicitada debe ser completado.',
                    VACANCY_GUID_REQUIRED: 'El campo del puesto de trabajo debe ser completado.',
                    SKILLS_REQUIRED: 'El campo de habilidades debe ser completado.',
                    CANDIDATE_RESUME_REQUIRED: 'El campo del currículum del candidato debe ser completado.',
                    PREVIOUS_EXPERIENCE_REQUIRED: 'El campo de experiencia previa debe ser completado.',
                    POSITIONS_REQUIRED: 'El campo de posiciones debe ser completado.',
                    TIME_NEED_EXPERT_REQUIRED: 'El campo de tiempo necesario para el experto debe ser completado.',
                    DEPARTMENT_STATE_REQUIRED: 'El campo de departamento/estado debe ser completado.',
                    CURRENT_CANDIDATE_COUNTRY_REQUIRED: 'El campo de país de residencia debe ser completado.',
                    APPLICATION_POSITION_REQUIRED: 'El campo de especialización médica debe ser completado.',
                    ADDITIONAL_INFO_TASKS_REQUIRED: 'El campo de tareas requeridas debe ser completado.',
                    POLICIES_CHECKBOX_REQUIRED: 'Debes marcar el checkbox.',
                    CURRENT_CANDIDATE_CITY_REQUIRED: 'El campo de la ciudad actual del candidato debe ser completado.',
                    EXPERT_REQUIRED: 'El campo del experto debe ser completado.',
                    CURRENT_CANDIDATE_ADDRESS_REQUIRED: 'El campo de la dirección actual del candidato debe ser completado.',
                    MORE_SERVICE_ERP_SUBTITLE1: "Optimice las operaciones, mejore la eficiencia e impulse",
                    MORE_SERVICE_ERP_SUBTITLE2: "el crecimiento con los sistemas ERP integrales de 5IG.",
                    DATA_FOOTER: 'Procesamiento de datos',
                    COPYRIGHT: 'Copyright © 2024. Todos los derechos reservados.',
                    MORE_SERVICE_ERP_TITLE: "Optimiza tu negocio con soluciones ERP",
                    MORE_SERVICE_ERP_SUBTITLE: "Optimiza operaciones, mejora la eficiencia y fomenta el crecimiento con los sistemas ERP integrales de 5IG.",
                    MORE_SERVICE_ERP_CTA: "Comienza hoy",
                    MORE_SERVICE_ERP_DESCRIPTION: "Las soluciones ERP de 5IG integran todos los aspectos de tu negocio, desde finanzas y cadena de suministro hasta recursos humanos y relaciones con los clientes, en un solo sistema fluido. Nuestro objetivo es ayudarte a lograr la excelencia operativa y fomentar un crecimiento sostenible.",
                    MORE_SERVICE_ERP_KEY_FEATURES_TITLE: "Características clave",
                    MORE_SERVICE_ERP_FINANCE_MANAGEMENT: "Gestión financiera",
                    MORE_SERVICE_ERP_FINANCE_MANAGEMENT_DESC: "Automatiza los procesos financieros y obtén información en tiempo real sobre la salud financiera de tu empresa.",
                    MORE_SERVICE_ERP_SUPPLY_CHAIN_OPTIMIZATION: "Optimización de la cadena de suministro",
                    MORE_SERVICE_ERP_SUPPLY_CHAIN_OPTIMIZATION_DESC: "Mejora la visibilidad y la eficiencia de la cadena de suministro desde la adquisición hasta la entrega.",
                    MORE_SERVICE_ERP_HUMAN_RESOURCES: "Recursos humanos",
                    MORE_SERVICE_ERP_HUMAN_RESOURCES_DESC: "Gestiona la información de los empleados, las nóminas y los beneficios con facilidad.",
                    MORE_SERVICE_ERP_PROCESS_TITLE: "Proceso paso a paso",
                    MORE_SERVICE_ERP_ASSESS_NEEDS: "Evaluar tus necesidades",
                    MORE_SERVICE_ERP_ASSESS_NEEDS_DESC: "Trabajaremos contigo para entender los requisitos específicos de tu negocio.",
                    MORE_SERVICE_ERP_TAILOR_SOLUTION: "Adaptar la solución",
                    MORE_SERVICE_ERP_TAILOR_SOLUTION_DESC: "Personalizamos el sistema ERP para que se adapte a tus necesidades únicas.",
                    MORE_SERVICE_ERP_IMPLEMENT_TRAIN: "Implementar y capacitar",
                    MORE_SERVICE_ERP_IMPLEMENT_TRAIN_DESC: "Nuestro equipo garantiza una implementación fluida y proporciona una capacitación integral.",
                    MORE_SERVICE_ERP_ONGOING_SUPPORT: "Soporte continuo",
                    MORE_SERVICE_ERP_ONGOING_SUPPORT_DESC: "Soporte continuo y actualizaciones para mantener tu sistema funcionando de manera eficiente.",
                    MORE_SERVICE_ERP_BENEFITS_TITLE: "Beneficios clave",
                    MORE_SERVICE_ERP_CENTRALIZED_DATA_MANAGEMENT: "Gestión centralizada de datos",
                    MORE_SERVICE_ERP_IMPROVED_EFFICIENCY_PRODUCTIVITY: "Mayor eficiencia y productividad",
                    MORE_SERVICE_ERP_REAL_TIME_REPORTING_ANALYTICS: "Informes y análisis en tiempo real",
                    MORE_SERVICE_ERP_SCALABLE_SOLUTIONS_GROWING_BUSINESSES: "Soluciones escalables para negocios en crecimiento",
                    "MORE_SERVICE_CRM_TITLE": "Mejora las relaciones con los clientes con nuestras soluciones CRM",
                    "MORE_SERVICE_CRM_SUBTITLE": "Gestiona interacciones, mejora la satisfacción del cliente y fomenta el crecimiento.",
                    "MORE_SERVICE_CRM_CTA": "Comienza hoy",
                    "MORE_SERVICE_CRM_DESCRIPTION": "Las soluciones CRM de 5IG permiten a tu empresa construir relaciones más fuertes con los clientes, optimizar las interacciones y aumentar la satisfacción. Nuestras herramientas integrales te ayudan a gestionar leads, rastrear interacciones y analizar datos de clientes para impulsar el crecimiento.",
                    "MORE_SERVICE_CRM_KEY_FEATURES_TITLE": "Características clave",
                    "MORE_SERVICE_CRM_LEAD_MANAGEMENT": "Gestión de leads",
                    "MORE_SERVICE_CRM_LEAD_MANAGEMENT_DESC": "Rastrea y gestiona leads desde el contacto inicial hasta la conversión con facilidad.",
                    "MORE_SERVICE_CRM_CUSTOMER_INTERACTION_TRACKING": "Seguimiento de interacciones con el cliente",
                    "MORE_SERVICE_CRM_CUSTOMER_INTERACTION_TRACKING_DESC": "Mantén un historial detallado de todas las interacciones con los clientes para proporcionar un servicio personalizado.",
                    "MORE_SERVICE_CRM_SALES_AUTOMATION": "Automatización de ventas",
                    "MORE_SERVICE_CRM_SALES_AUTOMATION_DESC": "Automatiza tareas y flujos de trabajo de ventas para aumentar la productividad y cerrar acuerdos más rápido.",
                    "MORE_SERVICE_CRM_ANALYTICS_REPORTING": "Análisis e informes",
                    "MORE_SERVICE_CRM_ANALYTICS_REPORTING_DESC": "Rastrea y gestiona leads desde el contacto inicial hasta la conversión con facilidad.",
                    "MORE_SERVICE_CRM_CUSTOMER_SUPPORT": "Soporte al cliente",
                    "MORE_SERVICE_CRM_CUSTOMER_SUPPORT_DESC": "Rastrea y gestiona leads desde el contacto inicial hasta la conversión con facilidad.",
                    "MORE_SERVICE_CRM_PROCESS_TITLE": "Proceso paso a paso",
                    "MORE_SERVICE_CRM_UNDERSTAND_NEEDS": "Entender tus necesidades",
                    "MORE_SERVICE_CRM_UNDERSTAND_NEEDS_DESC": "Evaluamos tus requisitos específicos de CRM.",
                    "MORE_SERVICE_CRM_CUSTOMIZE_SOLUTION": "Personalizar la solución",
                    "MORE_SERVICE_CRM_CUSTOMIZE_SOLUTION_DESC": "Adaptamos nuestras herramientas CRM a los procesos de tu empresa.",
                    "MORE_SERVICE_CRM_IMPLEMENT_TRAIN": "Implementar y capacitar",
                    "MORE_SERVICE_CRM_IMPLEMENT_TRAIN_DESC": "Aseguramos una implementación sin problemas y proporcionamos una capacitación integral.",
                    "MORE_SERVICE_CRM_ONGOING_SUPPORT": "Soporte continuo",
                    "MORE_SERVICE_CRM_ONGOING_SUPPORT_DESC": "Soporte continuo y actualizaciones para optimizar tu sistema CRM.",
                    "MORE_SERVICE_CRM_BENEFITS_TITLE": "Beneficios clave",
                    "MORE_SERVICE_CRM_CENTRALIZED_CUSTOMER_DATA": "Datos centralizados del cliente",
                    "MORE_SERVICE_CRM_ENHANCED_CUSTOMER_ENGAGEMENT": "Mejor compromiso del cliente",
                    "MORE_SERVICE_CRM_IMPROVED_SALES_EFFICIENCY": "Mayor eficiencia de ventas",
                    "MORE_SERVICE_CRM_DATA_DRIVEN_INSIGHTS": "Información basada en datos",
                    "MORE_SERVICE_ECOMMERCE_TITLE": "Revoluciona tus ventas B2B con las soluciones de comercio electrónico de 5IG",
                    "MORE_SERVICE_ECOMMERCE_SUBTITLE": "Optimiza transacciones, gestiona inventarios y fomenta el crecimiento con nuestra plataforma B2B integral.",
                    "MORE_SERVICE_ECOMMERCE_CTA": "Comienza hoy",
                    "MORE_SERVICE_ECOMMERCE_DESCRIPTION": "Las soluciones de comercio electrónico B2B de 5IG permiten a tu empresa optimizar pedidos al por mayor, gestionar inventarios de manera eficiente y facilitar transacciones sin problemas. Nuestra plataforma está diseñada para mejorar las operaciones de tu negocio y fomentar el crecimiento.",
                    "MORE_SERVICE_ECOMMERCE_KEY_FEATURES_TITLE": "Características clave",
                    "MORE_SERVICE_ECOMMERCE_CUSTOM_CATALOGS": "Catálogos personalizados",
                    "MORE_SERVICE_ECOMMERCE_CUSTOM_CATALOGS_DESC": "Crea catálogos de productos personalizados para diferentes clientes para satisfacer sus necesidades específicas.",
                    "MORE_SERVICE_ECOMMERCE_AUTOMATED_ORDERING": "Pedidos automatizados",
                    "MORE_SERVICE_ECOMMERCE_AUTOMATED_ORDERING_DESC": "Optimiza el proceso de pedidos con la gestión y seguimiento automatizado de pedidos.",
                    "MORE_SERVICE_ECOMMERCE_INVENTORY_MANAGEMENT": "Gestión de inventarios",
                    "MORE_SERVICE_ECOMMERCE_INVENTORY_MANAGEMENT_DESC": "Mantén un control de los niveles de stock, gestiona reordenes y reduce el exceso de inventario.",
                    "MORE_SERVICE_ECOMMERCE_FLEXIBLE_PAYMENT_OPTIONS": "Opciones de pago flexibles",
                    "MORE_SERVICE_ECOMMERCE_FLEXIBLE_PAYMENT_OPTIONS_DESC": "Ofrece múltiples métodos de pago y términos para acomodar las preferencias de los clientes.",
                    "MORE_SERVICE_ECOMMERCE_ANALYTICS_REPORTING": "Análisis e informes",
                    "MORE_SERVICE_ECOMMERCE_ANALYTICS_REPORTING_DESC": "Obtén información sobre el rendimiento de ventas y el comportamiento de los clientes con análisis avanzados.",
                    "MORE_SERVICE_ECOMMERCE_PROCESS_TITLE": "Proceso paso a paso",
                    "MORE_SERVICE_ECOMMERCE_CONSULTATION": "Consulta",
                    "MORE_SERVICE_ECOMMERCE_CONSULTATION_DESC": "Programa una consulta con nuestros expertos para discutir las necesidades y objetivos de tu negocio.",
                    "MORE_SERVICE_ECOMMERCE_SOLUTION_DESIGN": "Diseño de solución",
                    "MORE_SERVICE_ECOMMERCE_SOLUTION_DESIGN_DESC": "Adapta nuestras herramientas de comercio electrónico a los procesos de tu negocio.",
                    "MORE_SERVICE_ECOMMERCE_SEAMLESS_INTEGRATION": "Integración sin problemas",
                    "MORE_SERVICE_ECOMMERCE_SEAMLESS_INTEGRATION_DESC": "Asegura una implementación sin problemas y proporciona una capacitación integral.",
                    "MORE_SERVICE_ECOMMERCE_STAFF_TRAINING": "Capacitación del personal",
                    "MORE_SERVICE_ECOMMERCE_STAFF_TRAINING_DESC": "Soporte continuo y actualizaciones para optimizar tu sistema de comercio electrónico.",
                    "MORE_SERVICE_ECOMMERCE_LAUNCH_SUPPORT": "Lanzamiento y soporte",
                    "MORE_SERVICE_ECOMMERCE_LAUNCH_SUPPORT_DESC": "Soporte continuo y actualizaciones para optimizar tu sistema de comercio electrónico.",
                    "MORE_SERVICE_ECOMMERCE_BENEFITS_TITLE": "Beneficios clave",
                    "MORE_SERVICE_ECOMMERCE_SIMPLIFIED_BULK_ORDERING": "Pedidos al por mayor simplificados",
                    "MORE_SERVICE_ECOMMERCE_EFFICIENT_INVENTORY_MANAGEMENT": "Gestión de inventarios eficiente",
                    "MORE_SERVICE_ECOMMERCE_SEAMLESS_TRANSACTIONS": "Transacciones sin problemas",
                    "MORE_SERVICE_ECOMMERCE_ENHANCED_CUSTOMER_EXPERIENCE": "Mejor experiencia del cliente",
                    DATA_FOOTER: 'Procesamiento de datos',
                    ABOUT_US_TEXT: '5IG es un proveedor líder de soluciones innovadoras, especializado en soluciones tecnológicas, consultoría, contratación, asistentes médicos virtuales y oportunidades de inversión. Nuestra misión es dotar a las empresas de las herramientas y el talento que necesitan para triunfar.',
                    ABOUT_US_OVERLAY_TEXT: 'Conozca la empresa que está detrás de la innovación',
                    MEET_OUR_TEAM: 'Conozca al equipo detrás de nuestro éxito',
                    WHY_CHOOSE_US: '¿Por qué somos la opción ideal?',

                    MEET_OUR_TEAM: 'Conozca a nuestro equipo',
                    WHY_CHOOSE_US: '¿Por qué elegirnos?',
                    BLOG_TITLE: "Manténgase actualizado con lo último en tecnología, inteligencia artificial y automatización",
                    BLOG_BUTTON: "Leer más",
                    BLOG_FEATURED_TITLE: "Artículos destacados",
                    BLOG_LATEST_TITLE: "Últimas noticias",
                    SHARE: "Compartir",
                    SAVE_POSITIONS_REQUIRED: "Todas las posiciones deben ser guardadas",
                    POSITIONS_REQUIRED: "Todas las posiciones deben ser completadas",
                    EDIT: "Editar",
                    SAVE: "Guardar",
                    SKILLS_NO_JOB: "Por favor selecciona una posicion de trabajo",
                    "FINANCIALS_MAIN_TITLE": "IMPULSA TU NEGOCIO CON NUESTROS SERVICIOS FINANCIEROS",
                    "FINANCIALS_MAIN_DESCRIPTION": "Consultas financieras expertas para pequeñas y grandes empresas que te ayudarán a prosperar.",
                    "FINANCIALS_MAIN_BUTTON_TEXT": "Solicita una Consulta",
                    "FINANCIALS_MAIN_SUB_DESCRIPTION": "Con años de experiencia y un equipo de expertos en contabilidad, finanzas, impuestos y más, ofrecemos soluciones integrales que transformarán la manera en que gestionas tu empresa.",

                    "FINANCIALS_MAIN_WHY_CHOOSE_US_TITLE": "¿Por qué elegirnos?",
                    "FINANCIALS_MAIN_SERVICES_TITLE": "Nuestros Servicios",
                    "FINANCIALS_MAIN_BENEFITS_TITLE": "Beneficios",
                    "FINANCIALS_MAIN_TRUST_THE_BEST_TITLE": "Confía en los Mejores",
                    "FINANCIALS_MAIN_TRUST_THE_BEST_DESCRIPTION": "Somos más que un proveedor de servicios; somos tu socio estratégico en el camino hacia el éxito. ¡Contáctanos hoy y descubre cómo podemos transformar tu negocio!",

                    "FINANCIALS_MAIN_SERVICE_1_TITLE": "Contabilidad General",
                    "FINANCIALS_MAIN_SERVICE_1_DESCRIPTION": "Nos encargamos de todos los aspectos de tu contabilidad diaria, desde la entrada de datos hasta la conciliación de cuentas.",
                    "FINANCIALS_MAIN_SERVICE_2_TITLE": "Preparación de Impuestos",
                    "FINANCIALS_MAIN_SERVICE_2_DESCRIPTION": "Nos encargamos de preparar y presentar tus declaraciones de impuestos para asegurar el cumplimiento de todas las leyes y regulaciones pertinentes.",
                    "FINANCIALS_MAIN_SERVICE_3_TITLE": "Gestión de Nóminas",
                    "FINANCIALS_MAIN_SERVICE_3_DESCRIPTION": "Ofrecemos una solución integral para gestionar tus nóminas, incluyendo cálculos salariales, distribuciones y más.",
                    "FINANCIALS_MAIN_SERVICE_4_TITLE": "Presupuestos",
                    "FINANCIALS_MAIN_SERVICE_4_DESCRIPTION": "Te asistimos en la creación, gestión y seguimiento de tus presupuestos para que puedas planificar de manera efectiva y tomar decisiones financieras basadas en datos precisos.",
                    "FINANCIALS_MAIN_SERVICE_5_TITLE": "Informes Financieros",
                    "FINANCIALS_MAIN_SERVICE_5_DESCRIPTION": "Generamos informes financieros detallados y precisos que proporcionan una visión clara de la salud financiera de tu empresa.",
                    "FINANCIALS_MAIN_SERVICE_6_TITLE": "Cumplimiento Legal",
                    "FINANCIALS_MAIN_SERVICE_6_DESCRIPTION": "Nos aseguramos de que todas tus operaciones financieras cumplan con las leyes y regulaciones vigentes para evitar sanciones y problemas legales.",

                    "FINANCIALS_MAIN_BENEFIT_1_TITLE": "Ahorro de Tiempo",
                    "FINANCIALS_MAIN_BENEFIT_2_TITLE": "Reducción de Costos",
                    "FINANCIALS_MAIN_BENEFIT_3_TITLE": "Cumplimiento Legal y Regulatorio",
                    "FINANCIALS_MAIN_BENEFIT_4_TITLE": "Adaptabilidad Global",
                    "FINANCIALS_MAIN_BENEFIT_5_TITLE": "Crecimiento Sostenible",

                    "FINANCIALS_MAIN_CHOOSE_US_1": "Contable",
                    "FINANCIALS_MAIN_CHOOSE_US_2": "Especialista en Nóminas",
                    "FINANCIALS_MAIN_CHOOSE_US_3": "Especialista AP/RP",
                    "FINANCIALS_MAIN_CHOOSE_US_4": "Preparador de Impuestos",
                    "FINANCIALS_MAIN_CHOOSE_US_5": "Experiencia y Experiencia",
                    "FINANCIALS_MAIN_CHOOSE_US_6": "Soluciones Personalizadas",
                    "FINANCIALS_MAIN_CHOOSE_US_7": "Cumplimiento Garantizado",
                    "FINANCIALS_MAIN_CHOOSE_US_8": "Optimización de Procesos",
                    "FINANCIALS_MAIN_CHOOSE_US_9": "Soporte Integral",
                    "FINANCIALS_MAIN_FINANCE_TODAY_TITLE": "Finanzas en el mundo de hoy",
                    "FINANCIALS_MAIN_FINANCE_STAT_1": "Aproximadamente el 80% de los dueños son baby boomers y están experimentando un crecimiento agresivo, pero tienen dificultades para gestionar sin sacrificar tiempo.",
                    "FINANCIALS_MAIN_FINANCE_STAT_1_QUOTE": "\"El 76% de las empresas creen que trabajan eficazmente pero no de manera eficiente\"",
                    "FINANCIALS_MAIN_FINANCE_STAT_2": "Aprovechar la tecnología adecuada y las herramientas de automatización es fundamental para el éxito en la creación de eficiencia.",
                    "FINANCIALS_MAIN_FINANCE_STAT_2_QUOTE": "\"El 96% de los contadores consideran la automatización crítica para su profesión\"",
                    "FINANCIALS_FORM_SECURE_FUTURE_TITLE": "¡Asegura tu futuro financiero con 5IG!",
                    "CONTRACTING_MAIN_COMPREHENSIVE_CONSULTING": "Consultoría Integral para el Crecimiento de su Negocio",
                    "CONTRACTING_MAIN_EXPERIENCE_AND_EXPERTISE": "Experiencia y Experiencia",
                    "CONTRACTING_MAIN_EXPERIENCE_AND_EXPERTISE_TEXT": "Nuestro equipo está compuesto por expertos con décadas de experiencia en diversas industrias. Ofrecemos soluciones personalizadas basadas en un profundo conocimiento del mercado, asegurando que todas sus operaciones administrativas y tecnológicas estén en las mejores manos.",
                    "CONTRACTING_MAIN_STRATEGIC_FOCUS": "Enfoque Estratégico",
                    "CONTRACTING_MAIN_STRATEGIC_FOCUS_TEXT": "Nos convertimos en socios estratégicos para su negocio, ayudándole a trazar un camino claro hacia el éxito. Con nuestras soluciones, podrá dedicar más tiempo a innovar y expandir su negocio mientras nosotros gestionamos las operaciones diarias.",
                    "CONTRACTING_MAIN_CUSTOMIZED_SOLUTIONS": "Soluciones Personalizadas",
                    "CONTRACTING_MAIN_CUSTOMIZED_SOLUTIONS_TEXT": "Adaptamos nuestras soluciones a las necesidades específicas de su negocio. Nos encargamos de todo, desde la gestión financiera hasta el cumplimiento normativo y la salud y seguridad ocupacional, liberándolo de tareas rutinarias y dándole la libertad de centrarse en el crecimiento.",
                    "CONTRACTING_MAIN_PROCESS_OPTIMIZATION": "Optimización de Procesos",
                    "CONTRACTING_MAIN_PROCESS_OPTIMIZATION_TEXT": "Implementamos tecnologías avanzadas y metodologías probadas para automatizar y mejorar la eficiencia de sus operaciones, asegurando que todas las tareas administrativas y tecnológicas se manejen con precisión y a tiempo.",
                    "CONTRACTING_MAIN_ADMINISTRATIVE_AND_OPERATIONAL_MANAGEMENT": "Gestión Administrativa y Operacional",
                    "CONTRACTING_MAIN_BPO": "Externalización de Procesos Empresariales (BPO)",
                    "CONTRACTING_MAIN_RECORDS_MANAGEMENT": "Gestión de Registros",
                    "CONTRACTING_MAIN_AUTOMATION_OF_REPETITIVE_PROCESSES": "Automatización de Procesos Repetitivos",
                    "CONTRACTING_MAIN_OCCUPATIONAL_HEALTH_AND_SAFETY": "Salud y Seguridad Ocupacional",
                    "CONTRACTING_MAIN_DESIGN_AND_IMPLEMENTATION_OF_HEALTH_AND_SAFETY_PROGRAMS": "Diseño e Implementación de Programas de Salud y Seguridad",
                    "CONTRACTING_MAIN_RISK_ASSESSMENT_AND_TRAINING": "Evaluación de Riesgos y Capacitación",
                    "CONTRACTING_MAIN_IT_SECURITY": "Seguridad Informática",
                    "CONTRACTING_MAIN_CYBERSECURITY_CONSULTING": "Consultoría en Ciberseguridad",
                    "CONTRACTING_MAIN_MONITORING_AND_INCIDENT_RESPONSE": "Monitoreo y Respuesta a Incidentes",
                    "CONTRACTING_MAIN_EQUIPMENT_MAINTENANCE_AND_MANAGEMENT": "Mantenimiento y Gestión de Equipos",
                    "CONTRACTING_MAIN_IT_INFRASTRUCTURE_MANAGEMENT_AND_MAINTENANCE": "Gestión y Mantenimiento de Infraestructura IT",
                    "CONTRACTING_MAIN_TECHNICAL_SUPPORT_AND_EQUIPMENT_UPGRADES": "Soporte Técnico y Actualizaciones de Equipos",
                    "CONTRACTING_MAIN_PROCESS_AUDITING": "Auditoría de Procesos",
                    "CONTRACTING_MAIN_COMPREHENSIVE_PROCESS_REVIEW": "Revisión Integral de Procesos",
                    "CONTRACTING_MAIN_IMPLEMENTATION_OF_IMPROVEMENTS": "Implementación de Mejoras",
                    "CONTRACTING_MAIN_FINANCIAL_AND_ACCOUNTING_MANAGEMENT": "Gestión Financiera y Contable",
                    "CONTRACTING_MAIN_ACCOUNTING_AND_FINANCIAL_MANAGEMENT": "Gestión Contable y Financiera",
                    "CONTRACTING_MAIN_PAYROLL_AND_HUMAN_RESOURCES_MANAGEMENT": "Gestión de Nómina y Recursos Humanos",
                    "CONTRACTING_MAIN_REGULATORY_COMPLIANCE": "Cumplimiento Regulatorio",
                    "CONTRACTING_MAIN_LEGAL_AND_TAX_ADVISORY": "Asesoría Legal y Fiscal",
                    "CONTRACTING_MAIN_AUDIT_AND_COMPLIANCE": "Auditoría y Cumplimiento",
                    "CONTRACTING_MAIN_DIGITAL_TRANSFORMATION_AND_OPTIMIZATION": "Transformación y Optimización Digital",
                    "CONTRACTING_MAIN_ERP_IMPLEMENTATION": "Implementación de ERP",
                    "CONTRACTING_MAIN_PROCESS_AUTOMATION_AND_CYBERSECURITY": "Automatización de Procesos y Ciberseguridad",
                    "CONTRACTING_MAIN_STRATEGIC_CONSULTING_AND_BUSINESS_GROWTH": "Consultoría Estratégica y Crecimiento Empresarial",
                    "CONTRACTING_MAIN_STRATEGIC_PLANNING": "Planificación Estratégica",
                    "CONTRACTING_MAIN_MARKET_AND_COMPETITOR_ANALYSIS": "Análisis de Mercado y Competencia",
                    "CONTRACTING_MAIN_STRATEGIC_CONSULTANTS": "Consultores Estratégicos",
                    "CONTRACTING_MAIN_RECORDS_MANAGEMENT_SPECIALISTS": "Especialistas en Gestión de Registros",
                    "CONTRACTING_MAIN_OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS": "Especialistas en Salud y Seguridad Ocupacional",
                    "CONTRACTING_MAIN_CYBERSECURITY_SPECIALISTS": "Especialistas en Ciberseguridad",
                    "CONTRACTING_MAIN_TECHNICAL_SUPPORT_AND_MAINTENANCE_TECHNICIANS": "Técnicos de Soporte Técnico y Mantenimiento",
                    "CONTRACTING_MAIN_PROCESS_AUDITORS": "Auditores de Procesos",
                    "CONTRACTING_MAIN_FINANCIAL_AND_ACCOUNTING_ANALYSTS": "Analistas Financieros y Contables",
                    "CONTRACTING_MAIN_ADMINISTRATIVE_MANAGERS": "Gerentes Administrativos",
                    "CONTRACTING_MAIN_LEGAL_AND_TAX_ADVISORS": "Asesores Legales y Fiscales",
                    "CONTRACTING_MAIN_HUMAN_RESOURCES_CONSULTANTS": "Consultores de Recursos Humanos",
                    "CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS": "Ahorro de Tiempo y Recursos",
                    "CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS_DESCRIPTION": "Nos encargamos de sus tareas administrativas, operativas y tecnológicas, permitiéndole dedicar más tiempo al crecimiento de su negocio.",
                    "CONTRACTING_MAIN_INCREASED_EFFICIENCY": "Mayor Eficiencia",
                    "CONTRACTING_MAIN_INCREASED_EFFICIENCY_DESCRIPTION": "Con nuestras soluciones tecnológicas, servicios de gestión de registros, auditorías de procesos y automatización, optimizamos sus operaciones, reduciendo errores y mejorando la productividad.",
                    "CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND": "Cumplimiento Legal y Tranquilidad",
                    "CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND_DESCRIPTION": "Nos aseguramos de que su negocio cumpla con todas las regulaciones legales, minimizando riesgos y brindándole tranquilidad.",
                    "CONTRACTING_MAIN_FOCUS_ON_GROWTH": "Enfoque en el Crecimiento",
                    "CONTRACTING_MAIN_FOCUS_ON_GROWTH_DESCRIPTION": "Con las tareas operativas, administrativas y tecnológicas bajo control, puede centrarse en innovar, expandir y hacer crecer su negocio de manera sostenible.",
                    "CONTRACTING_MAIN_CONSULTING": "Consultoría",
                    "CONTRACTING_MAIN_AT_5IG_SOLUTIONS": "En 5IG Solutions, entendemos que la gestión de tareas administrativas y operativas puede consumir tiempo y recursos valiosos que podrían emplearse mejor en el crecimiento de su negocio.",
                    "CONTRACTING_MAIN_WHY_CHOOSE_US": "¿Por Qué Elegirnos?",
                    "CONTRACTING_MAIN_OUR_CONSULTING_SERVICES": "Nuestros Servicios de Consultoría",
                    "CONTRACTING_MAIN_OUR_TEAM_OF_PROFESSIONALS": "Nuestro Equipo de Profesionales",
                    "CONTRACTING_MAIN_AT_5IG_SOLUTIONS_TEAM_DESCRIPTION": "En 5IG Solutions, contamos con un equipo multidisciplinario de expertos dedicados a manejar todas las tareas administrativas, operativas y tecnológicas de su empresa.",
                    "CONTRACTING_MAIN_TEAM_INCLUDES": "Nuestro equipo incluye:",
                    "CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS": "Ahorro de Tiempo y Recursos",
                    "CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS_DESCRIPTION": "Nos encargamos de sus tareas administrativas, operativas y tecnológicas, permitiéndole dedicar más tiempo al crecimiento de su negocio.",
                    "CONTRACTING_MAIN_INCREASED_EFFICIENCY": "Eficiencia Incrementada",
                    "CONTRACTING_MAIN_INCREASED_EFFICIENCY_DESCRIPTION": "Con nuestras soluciones tecnológicas, servicios de gestión de registros, auditorías de procesos y automatización, optimizamos sus operaciones, reduciendo errores y mejorando la productividad.",
                    "CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND": "Cumplimiento Legal y Tranquilidad",
                    "CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND_DESCRIPTION": "Aseguramos que su negocio cumpla con todas las regulaciones legales, minimizando riesgos y proporcionándole tranquilidad.",
                    "CONTRACTING_MAIN_FOCUS_ON_GROWTH": "Enfoque en el Crecimiento",
                    "CONTRACTING_MAIN_FOCUS_ON_GROWTH_DESCRIPTION": "Con las tareas operativas, administrativas y tecnológicas bajo control, puede concentrarse en innovar, expandir y crecer sosteniblemente su negocio.",
                    "WORK_WITH_US_CONTENT_APPRECIATION_INCENTIVES": "Incentivos de Apreciación",
                    "WORK_WITH_US_CONTENT_EDUCATIONAL_AID": "Ayuda Educativa",
                    "WORK_WITH_US_CONTENT_LIFE_INSURANCE": "Seguro de Vida",
                    "WORK_WITH_US_CONTENT_CONNECTIVITY_ALLOWANCE": "Subsidio de Conectividad",
                    "WORK_WITH_US_CONTENT_OPHTHALMOLOGICAL_AID": "Ayuda Oftalmológica",
                    "WORK_WITH_US_CONTENT_PSYCHOLOGICAL_SUPPORT": "Apoyo Psicológico",
                    "WORK_WITH_US_CONTENT_JOIN_THE_COMPANY": "Únete a la empresa líder en innovación",
                    "WORK_WITH_US_CONTENT_JOIN_DESCRIPTION": "Únete a 5IG Solutions y descubre emocionantes oportunidades para hacer crecer tu carrera. Explora cómo podemos trabajar juntos para lograr el éxito a través de proyectos innovadores y colaboración.",
                    "WORK_WITH_US_CONTENT_OUR_COMPANY_BENEFITS": "Beneficios de Nuestra Empresa",
                    "WORK_WITH_US_CONTENT_INTERESTED_JOIN_TEAM": "Si estás interesado en unirte a nuestro equipo, por favor completa el formulario a continuación. Nos pondremos en contacto contigo pronto. ¡Gracias!"
                },
            },
        },
        lng: userLanguage,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

