import React, { useState } from "react";
import "./financial-services.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/header/header";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import BannerComponent from "../../../components/bannerComponent/bannerComponent";
import BenefitsCardsComponent from "../../../components/benefitsCardsComponent/benefitsCardsComponent";
import ButtonLinkComponent from "../../../components/buttonLink/buttonLink";
import Footer from "../../../components/footer/footer";

const FinancialServices = () => {
    const { t } = useTranslation();

    const services = [
        {
            title: t('FINANCIALS_MAIN_SERVICE_1_TITLE'),
            text: t('FINANCIALS_MAIN_SERVICE_1_DESCRIPTION'),
            icon: "accounting-icon.svg" // Placeholder for the icon
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_2_TITLE'),
            text: t('FINANCIALS_MAIN_SERVICE_2_DESCRIPTION'),
            icon: "tax-icon.svg" // Placeholder for the icon
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_3_TITLE'),
            text: t('FINANCIALS_MAIN_SERVICE_3_DESCRIPTION'),
            icon: "payroll-icon.svg" // Placeholder for the icon
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_4_TITLE'),
            text: t('FINANCIALS_MAIN_SERVICE_4_DESCRIPTION'),
            icon: "budgeting-icon.svg" // Placeholder for the icon
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_5_TITLE'),
            text: t('FINANCIALS_MAIN_SERVICE_5_DESCRIPTION'),
            icon: "reporting-icon.svg" // Placeholder for the icon
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_6_TITLE'),
            text: t('FINANCIALS_MAIN_SERVICE_6_DESCRIPTION'),
            icon: "compliance-icon.svg" // Placeholder for the icon
        }
    ]

    const benefits = [
        {
            name: t('FINANCIALS_MAIN_BENEFIT_1_TITLE'),
            img: '/img/openart-image_vGkkH9SM_1715791353615_raw 2.jpg'
        },
        {
            name: t('FINANCIALS_MAIN_BENEFIT_2_TITLE'),
            img: '/img/openart-image_UhNKA27w_1715791682693_raw 3.jpg'
        },
        {
            name: t('FINANCIALS_MAIN_BENEFIT_3_TITLE'),
            img: '/img/young-adult-programmer-typing-computer-office-generated-by-ai 4.jpg'
        },
        {
            name: t('FINANCIALS_MAIN_BENEFIT_4_TITLE'),
            img: '/img/openart-image_QMcNbC6M_1715792822145_raw 5.jpg'
        },
        {
            name: t('FINANCIALS_MAIN_BENEFIT_5_TITLE'),
            img: '/img/openart-image_YyQnCnLS_1715793412802_raw 4.jpg'
        }
    ];

    // Componente BenefitsContainer
    function BenefitsContainer({ img, text }) {
        return (
            <div className="positionsApplyContainer">
                <img src={`${img}`} alt="" className="positionsApplyImgBackground" />
                <div className="positionsApplyBackground">
                    <p className="positionsApplyText">{text}</p>
                </div>
            </div>
        )
    }

    const [dropdowns, setDropdowns] = useState([
        { title: 'FINANCIALS_MAIN_CHOOSE_US_1', isOpen: false },
        { title: 'FINANCIALS_MAIN_CHOOSE_US_2', isOpen: false },
        { title: 'FINANCIALS_MAIN_CHOOSE_US_3', isOpen: false },
        { title: 'FINANCIALS_MAIN_CHOOSE_US_4', isOpen: false },
        { title: 'FINANCIALS_MAIN_CHOOSE_US_5', isOpen: false },
        { title: 'FINANCIALS_MAIN_CHOOSE_US_6', isOpen: false },
        { title: 'FINANCIALS_MAIN_CHOOSE_US_7', isOpen: false },
        { title: 'FINANCIALS_MAIN_CHOOSE_US_8', isOpen: false },
        { title: 'FINANCIALS_MAIN_CHOOSE_US_9', isOpen: false },
    ]);

    const toggleDropdown = (index) => {
        setDropdowns((prevDropdowns) =>
            prevDropdowns.map((dropdown, i) =>
                i === index ? { ...dropdown, isOpen: !dropdown.isOpen } : dropdown
            )
        );
    };

    function Dropdown({ title, index, isOpen, onToggle }) {
        return (
            <div
                id={`dropdown-${index}`}
                className="dropdown"
                style={{
                    height: isOpen && 'auto',
                    overflow: 'hidden',
                    transition: 'height 0.3s ease'
                }}
                onClick={() => onToggle(index)}
            >
                <div className="dropdown-header" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p className="dropdownTitle">{t(title)}</p>
                    <img src="/img/arrowDownDropdown.svg" className="arrowDownFinancialsChoseUs" alt="" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }} />
                </div>
                {isOpen && (
                    <div className="dropdown-content">
                        {/* Aquí iría el contenido del dropdown */}
                        Contenido del dropdown...
                    </div>
                )}
            </div>
        );
    };

    const featuresData = [
        {
            title: t('FINANCIALS_MAIN_FINANCE_STAT_1_QUOTE'),
            text: t('FINANCIALS_MAIN_FINANCE_STAT_1'),
            imgSrc: '/img/natStatics1.jpg',
            className: 'competitive-compensation'
        },
        {
            title: t('FINANCIALS_MAIN_FINANCE_STAT_2_QUOTE'),
            text: t('FINANCIALS_MAIN_FINANCE_STAT_2'),
            imgSrc: '/img/staticsFinancials2.jpg',
            className: 'support-training'
        }
    ];

    function TargetHome({ img, text, title }) {
        return (
            <div className='targetHomeContainer'>
                <div className="targetInfoContainer">
                    <p className="mainText">{text}</p>
                    <h2>{title}</h2>
                </div>
                <img src={img} alt="" />
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="financialsMainContainer">
                <MainTitleComponent
                    title={t('FINANCIALS_MAIN_TITLE')}
                />
                <section className="services-container" id="services-container">
                    <div className="services-portfolio">
                        <BannerComponent
                            translateTitle={t("FINANCIALS_MAIN_DESCRIPTION")}
                            translateButton={t('FINANCIALS_MAIN_BUTTON_TEXT')}
                            className={`financialsBanner`}
                            classNameSide={`l`}
                            url={'/financials/adquire'}
                        />
                    </div>
                </section>
                <p className="mainTextTitle top">{t('FINANCIALS_MAIN_SUB_DESCRIPTION')}</p>
                <h2 className="titleH2">{t('FINANCIALS_MAIN_WHY_CHOOSE_US_TITLE')}</h2>
                <section className="choseUSSection">
                    <div className="choseUsContainer">
                        <img src="/img/experrts 2.jpg" alt="" className="choseUSImg" />
                        <div className="choseUsOptionsContainer">
                            {dropdowns.map((dropdown, index) => (
                                <Dropdown
                                    key={index}
                                    index={index}
                                    title={dropdown.title}
                                    isOpen={dropdown.isOpen}
                                    onToggle={toggleDropdown}
                                />
                            ))}
                        </div>
                    </div>
                </section>
                <h2 className="titleH2">{t('FINANCIALS_MAIN_FINANCE_TODAY_TITLE')}</h2>
                <section className="financesData targetsSection">
                    <div className="targetsContainer">
                        {featuresData.map((feature, index) => (
                            <TargetHome
                                key={index}
                                title={feature.title}
                                img={feature.imgSrc}
                                text={feature.text}
                                className={feature.className}
                            />
                        ))}
                    </div>
                </section>
                <h2 className="titleH2">{t('FINANCIALS_MAIN_TRUST_THE_BEST_TITLE')}</h2>
                <p className="mainTextTitle">{t('FINANCIALS_MAIN_TRUST_THE_BEST_DESCRIPTION')}</p>
                <section className="keyServicesSection">
                    <BenefitsCardsComponent
                        title={t('FINANCIALS_MAIN_SERVICES_TITLE')}
                        cardsData={services}
                    />
                </section>
                <section className="benefitsApplySection">
                    <h2 className="titleH2">{t("FINANCIALS_MAIN_BENEFITS_TITLE")}</h2>
                    <div className="roofsContainer">
                        {benefits.map((data, index) => (
                            <BenefitsContainer
                                key={index}
                                img={data.img}
                                text={data.name}
                            />
                        ))}
                    </div>
                </section>
                <ButtonLinkComponent
                    translateButton={t('FINANCIALS_MAIN_BUTTON_TEXT')}
                    className={'buttonFinancials'}
                    url={'/financials/adquire'}
                />
            </main>
            <Footer />
        </>
    );
};

export default FinancialServices;
