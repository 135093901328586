
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import React, { useEffect, useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import "../style/advantages.css";
import "../style/contact-us.css";
import TextAreaComponent from "../components/textAreaComponent";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import InputComponent from "../components/inputComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FetchSuccessComponent from "../components/fetchComponent/fetchComponent";
import useDropdown from "../hooks/searchDropdownHook";
import { filterByCountryObject } from "../utilities/filterfunctionsDropdowns";
import optionsCountries from "../utilities/countries_code";
import ContactWay from "../components/contactOptionsComponent/contactOptionsComponent";
import useSortedCountries from "../hooks/sortCountriesCode";
import i18n from "../utilities/i18n";

const Find_top_talent = () => {
  const { t } = useTranslation();
  const [skillsArray, setSkillsArray] = useState([])
  const {
    isOpen: openDropdownCountryCode,
    setIsOpen: setOpenDropdownCountryCode,
    searchTerm: searchTermCountryCode,
    filteredArray: filteredCountryCode,
    inputRef: elementoRef,
    handleSearchChange: handleSearchChangeCountryCode,
    selectedItem: selectedCountryCode,
    setSelectedItem: setSelectedCountryCode
  } = useDropdown(optionsCountries, filterByCountryObject);

  const initialData = {
    firstName: "",
    lastName: "",
    phoneNumberCode: "",
    phoneNumber: "",
    email: "",
    companyName: "",
    expertYouAreLookingFor: "",
    additionalInformation: "",
    policies: false,
  }

  const [loading, setLoading] = useState(false)
  const [errors, setErros] = useState({})
  // const [selectedPhonePrefix, setSelectedPhonePrefix] = useState(initialData.phone_prefix)
  const [form, setForm] = useState(initialData)

  const handleDropdown = (set) => {
    set((prev) => !prev);
  }

  const handleChange = (event) => {
    const { name, value, checked } = event.target
    if (name != "email" && name !== "additionalInformation") {
      if (name === 'phoneNumber' || name === 'previousExperience') {
        if (value < 0) {
          return;
        }
      } else {
        if (!/^[^\d]*$/.test(value)) {
          return;
        }
      }
    }
    if (name === 'policies') {
      setForm({ ...form, [name]: checked })
    } else {
      setForm({ ...form, [name]: value })
    }

  }

  const handleSelectOption = (option, keyOption, set) => {
    if (keyOption == 'skills') {

      setSkillsArray([...skillsArray, option]);
      setForm({
        ...form,
        [keyOption]: [...skillsArray, option].join(",")
      });
    } else if (keyOption == 'vacancy') {
      setForm({ ...form, [keyOption]: option.vacancyName, ["RecruitingEffort"]: option.recruitingEffortId, ["VacancyGUID"]: option.vacancyGUID })
    } else if (keyOption === "phoneNumberCode") {
      setSelectedCountryCode(option);
      setForm({ ...form, [keyOption]: option })
    } else {
      setForm({ ...form, [keyOption]: option })
    }
    set((prev) => !prev);
  }

  const [selectedContactWay, setSelectedContactWay] = useState("");

  const onValidate = (form) => {
    let errors = {};

    if (!form.firstName.trim()) {
      errors.firstName = t('NAME_REQUIRED');
    }

    if (!form.lastName.trim()) {
      errors.lastName = t('LAST_NAME_REQUIRED');
    }

    if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumberCode.trim())) {
      errors.phoneNumberCode = t('PHONE_CODE_REQUIRED');
    }

    if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumber.trim())) {
      errors.phoneNumber = t('PHONE_NUMBER_REQUIRED');
    }

    if (((selectedContactWay === "1") || (selectedContactWay === "3")) && (!form.email.trim())) {
      errors.email = t('EMAIL_REQUIRED');
    }

    if (!selectedContactWay) {
      errors.contactWay = t("CONTACT_WAY_VALIDATION")
    }

    if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (form.phoneNumber <= 0)) {
      errors.phoneNumber = t('PHONE_NUMBER_ZERO')
    }

    if (!form.expertYouAreLookingFor.trim()) {
      errors.expertYouAreLookingFor = t('EXPERT_REQUIRED');
    }

    if (!form.companyName) {
      errors.companyName = t('COMPANY_REQUIRED');
    }

    if (!form.additionalInformation.trim()) {
      errors.additionalInformation = t('ADDITIONAL_INFO_REQUIRED');
    }

    if (!form.policies) {
      errors.policies = t('POLICIES_REQUIRED');
    }

    return errors;
  };


  const handleClickOutside = (event) => {

    if (elementoRef.current && !elementoRef.current.contains(event.target) &&
      !event.target.classList.contains('dropdownOption')) {
      setOpenDropdownCountryCode(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const form2 = useRef();


  onValidate(form)

  const [isFetchSuccessful, setIsFetchSuccessful] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    const validationErrors = onValidate(form);
    setErros(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {

        const convertSkillsToString = (data) => {
          return {
            ...data
          };
        };

        const response = await fetch('https://prod-98.westus.logic.azure.com:443/workflows/251696796cab422e91aea776fac48a8e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=5Ivjk3qCWe16u7cOyTvXgL6gjelgyVkipD7H7WL4oRo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(convertSkillsToString(form)),
        });

        if (response.ok) {
          setIsFetchSuccessful(true); // Cambia el estado a true si la respuesta es exitosa
          setForm(initialData)

        } else {
          setIsFetchSuccessful(false); // Cambia el estado a false si la respuesta no es exitosa
        }

      } catch (error) {
        setIsFetchSuccessful(false); // Cambia el estado a false si la respuesta no es exitosa
      }
    }
  };

  const sortedCountriesCode = useSortedCountries("country", filteredCountryCode)


  return (
    <>
      <Header></Header>
      <div className="workWithUsBanner"></div>
      <main className="workWithUsFormMainContainer">
        <div className="contact-us-forms">
          <form ref={form2}>
            <h2 className="form-title">{t('FORM_TITLE')}</h2>
            <div className="nameContainer">
              <div className="errorContainer">
                <InputComponent
                  className={`input-width`}
                  name={'firstName'}
                  value={form.firstName}
                  handleChange={handleChange}
                  label={`${t('FORM_FIRST_NAME')}*`}
                />
                {errors.firstName && <div className="alert alert-danger">{errors.firstName}</div>}
              </div>
              <div className="errorContainer">
                <InputComponent
                  className={`input-width`}
                  name={'lastName'}
                  value={form.lastName}
                  handleChange={handleChange}
                  label={`${t('FORM_LAST_NAME')}*`}
                />
                {errors.lastName && <div className="alert alert-danger">{errors.lastName}</div>}
              </div>
            </div>
            <ContactWay
              selectedContactWay={selectedContactWay}
              setSelectedContactWay={setSelectedContactWay}
            />
            <div className="phoneEmailContainer">
              {((selectedContactWay === "2") || (selectedContactWay === "3")) && (<div className="phoneContactContainerMain">
                <p>{t('FORM_PHONE_NUMBER')}*</p>
                <div className="phoneContactContainer phoneCode">
                  <div className="phoneContactDropdown phoneCode" ref={elementoRef}>
                    <div className="phoneContactInputContainer">
                      <input
                        type="text"
                        className={`inputNumber ${openDropdownCountryCode && "active"}`}
                        onClick={() => handleDropdown(setOpenDropdownCountryCode)}
                        onChange={handleSearchChangeCountryCode}
                        name="phoneNumberCode"
                        value={selectedCountryCode ? selectedCountryCode : searchTermCountryCode}
                        autoComplete="off"
                      />
                      <img src={'/img/arrowDownDropdown.svg'} alt="" />
                    </div>
                    {openDropdownCountryCode && (
                      <div className="phoneContactOptions">
                        <div className="optionsContainer">
                          {sortedCountriesCode.map((option) => {
                            return <p onClick={() => handleSelectOption(option.code, "phoneNumberCode", setOpenDropdownCountryCode)} key={option.country}>
                              {i18n.language === 'es' ? option.country : option.country_EN}
                            </p>
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="errorContainer">
                    <InputComponent
                      className={`input-width`}
                      name={'phoneNumber'}
                      type={'number'}
                      handleChange={handleChange}
                      value={form.phoneNumber}
                    />
                  </div>
                </div>
                {errors.phoneNumber && <div className="alert alert-danger">{errors.phoneNumber}</div>}
                {errors.phoneNumberCode && <div className="alert alert-danger">{errors.phoneNumberCode}</div>}
              </div>)}
              {((selectedContactWay === "1") || (selectedContactWay === "3")) && (<div className="errorContainer email">
                <InputComponent
                  className={`input-width`}
                  label={`${t('FORM_EMAIL')}*`}
                  name={'email'}
                  type={'email'}
                  value={form.email}
                  handleChange={handleChange}
                />
                {errors.email && <div className="alert alert-danger">{errors.email}</div>}
              </div>)}
            </div>
            {errors.contactWay && <div className="alert alert-danger">{errors.contactWay}</div>}

            <div className="errorContainer">
              <InputComponent
                className={`input-width`}
                name={'companyName'}
                value={form.companyName}
                label={`${t('FORM_COMPANY_NAME')}*`}
                handleChange={handleChange}
              />
            </div>
            {errors.companyName && <div className="alert alert-danger">{errors.companyName}</div>}
            <div className="errorContainer">
              <InputComponent
                className={`input-width`}
                name={'expertYouAreLookingFor'}
                value={form.expertYouAreLookingFor}
                label={`${t('EXPERT_YOU_ARE_LOOKING')}*`}
                handleChange={handleChange}
              />
            </div>
            {errors.expertYouAreLookingFor && <div className="alert alert-danger">{errors.expertYouAreLookingFor}</div>}

            <p>{t('FORM_ADDITIONAL_INFORMATION')}*</p>
            <div className="errorContainer">
              <TextAreaComponent
                className={`input-width textArea`}
                name={'additionalInformation'}
                handleChange={handleChange}
                value={form.additionalInformation}
              />
              {errors.additionalInformation && <div className="alert alert-danger">{errors.additionalInformation}</div>}
            </div>
            <div className="termsAndConditionsContainer">
              <input type="checkbox" name="policies" onChange={handleChange} />
              <p className="termsAndConditionsText">{t('FORM_POLICY')} <a href="/PoliticaDeTratamientoDeDatosPersonales.pdf">{t('FORM_POLICY_LINK')}*</a></p>
            </div>
            {errors.policies && <div className="alert alert-danger">{errors.policies}</div>}
            <button className="button" onClick={sendEmail}>{loading ? t('FORM_BUTTON_LOADING') : t('FORM_BUTTON')}</button>
            <FetchSuccessComponent isFetchSuccessful={isFetchSuccessful} setIsFetchSuccessful={setIsFetchSuccessful} url={'/recruitment'} />
          </form>
        </div>
      </main>

      <Footer></Footer>
    </>
  );
};

export default Find_top_talent;
